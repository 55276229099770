import React, { useState } from 'react';
import { firestore, auth } from '../../firebase';
import Templates from './Templates';
import LocalAnaesthetic from './LocalAnaesthetic';
import RadiographSettings from './RadiographSettings';
import ConsentForms from './ConsentForms';
import ExamAndConsultTemplates from './ExamsAndConsultTemplates';
import CariespondenceTemplates from './CariespondenceTemplates';
import './settings.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotesMedical, faSyringe, faSatelliteDish, faMagnifyingGlass, faEnvelope } from '@fortawesome/free-solid-svg-icons';


const Settings = () => {
    const [activeTab, setActiveTab] = useState('ExamsConsults');

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    }  

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
      {/* <ExamAndConsultTemplates />
      <Templates />
      <LocalAnaesthetic />
      <RadiographSettings />
      <ConsentForms />
      <CariespondenceTemplates /> */}

      
      {/* HEADER TABS */}
      <div>
          <ul className="cursor-pointer flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
              <li className="me-2" onClick={() => handleTabClick('ExamsConsults')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'ExamsConsults' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
              <FontAwesomeIcon icon={faNotesMedical} />
                    <span className="ml-2">
                        Exams & Consults
                    </span>
                  </div>
              </li>
              <li className="me-2" onClick={() => handleTabClick('BasicExamTreatmentNotes')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'BasicExamTreatmentNotes' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  <span className="ml-2">
                        Basic Exam/Treatment Notes
                    </span>
                  </div>
              </li>
              <li className="me-2" onClick={() => handleTabClick('LA')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'LA' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
                  <FontAwesomeIcon icon={faSyringe} />
                  <span className="ml-2">
                        Local Anaesthetic
                    </span>
                  </div>
              </li>
              {/* <li className="me-2" onClick={() => handleTabClick('Radiographs')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'Radiographs' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
                  <FontAwesomeIcon icon={faSatelliteDish} />
                  <span className="ml-2">
                        Radiographs
                    </span>

                  </div>
              </li> */}
              <li className="me-2" onClick={() => handleTabClick('CustomForms')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'CustomForms' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
                      <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                          <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                      </svg>Custom Forms
                  </div>
              </li>
              <li className="me-2" onClick={() => handleTabClick('Cariespondence')}>
              <div className={`inline-flex items-center justify-center p-4 border-b-2 ${activeTab === 'Cariespondence' ? 'border-primary-400 text-primary-400' : 'border-transparent'} rounded-t-lg group hover:text-gray-600 hover:border-gray-300`}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="ml-2">
                        Cariespondence
                    </span>
                  </div>
              </li>
          </ul>
      </div>

      {/* CONTENT */}
      <div>
          {activeTab === 'ExamsConsults' && <ExamAndConsultTemplates />}
          {activeTab === 'BasicExamTreatmentNotes' && <Templates />}
          {activeTab === 'LA' && <LocalAnaesthetic />}
          {activeTab === 'Radiographs' && <RadiographSettings />}
          {activeTab === 'CustomForms' && <ConsentForms />}
          {activeTab === 'Cariespondence' && <CariespondenceTemplates />}
      </div>

    </main>
  );
};

export default Settings;

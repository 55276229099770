// TreatmentSelection.js
import React, { useState, useEffect } from 'react';
import { dentistDetails, introduction,
    generalDentalRisks,
    dentalcareDetails,
    compositeDetails,
    rootCanalDetails,
    crownOrOnlayDetails,
    dentureDetails,
    hygieneDetails,
    bridgeDetails,
    extractionDetails,
    treatmentAgreement,
    whiteningDetails,
    dentalimplantDetails,
    patientBehaviour,
    amalgamDetails,
    fissuresealantDetails,
    stainlesssteelcrownDetails,
    compositebondingDetails,
    invisalignDetails,
    fixedorthoDetails,
    veneerDetails,
    sedationDetails,
    fullarchimplantsDetails,
    immediatedentureDetails,
    enlightenDetails } from './TreatmentDetails';
import axios from 'axios';
import './TreatmentSelection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTooth, faCrown, faScrewdriver, faGrinTongue, faArchway, 
    faWandSparkles, faSeedling, faSoap, faWrench, 
    faCapsules, faVial, faRulerVertical, faUserMd, 
    faSmileBeam, faAlignJustify, faTeeth, faMicrophone, faStop, faPaperPlane, faXmark
  } from '@fortawesome/free-solid-svg-icons';  
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import TreatmentDetails from './TreatmentDetails';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const TreatmentSelection = () => {
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [patientName, setPatientName] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [patientSpecifics, setPatientSpecifics] = useState('');
    const [showPatientSpecificInput, setShowPatientSpecificInput] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [consentForms, setConsentForms] = useState({});
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [assistantResponse, setAssistantResponse] = useState('');
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);

    const formKeyMappings = {
        'introduction': 'Introduction',
        'generalDentalRisks': 'General Dental Risks',
        'dentalcareDetails': 'Dental Care Details',
        'compositeDetails': 'Composite',
        'rootCanalDetails': 'Root Canal',
        'crownOrOnlayDetails': 'Crown/Onlay',
        'dentureDetails': 'Dentures',
        'hygieneDetails': 'Hygiene',
        'bridgeDetails': 'Bridge',
        'extractionDetails': 'Extraction',
        'immediatedentureDetails': 'Immediate Denture',
        'whiteningDetails': 'Whitening',
        'enlightenDetails': 'Enlighten',
        'dentalimplantDetails': 'Dental Implant',
        'fullarchimplantsDetails': 'Full Arch Implants',
        'patientBehaviour': 'Patient Behaviour',
        'treatmentAgreement': 'Treatment Agreement',
        'amalgamDetails': 'Amalgam Filling',
        'fissuresealantDetails': 'Fissure Sealant',
        'stainlesssteelcrownDetails': 'Stainless Steel Crown',
        'compositebondingDetails': 'Composite Bonding',
        'invisalignDetails': 'Invisalign',
        'fixedorthoDetails': 'Fixed Orthodontics',
        'veneerDetails': 'Veneers',
        'sedationDetails': 'Sedation',
      };
    
      const omittedKeys = [
        'introduction',
        'generalDentalRisks',
        'dentalcareDetails',
        'patientBehaviour',
        'treatmentAgreement'
      ];

    const togglePatientSpecificInput = () => {
      setShowPatientSpecificInput(!showPatientSpecificInput);
    };

    useEffect(() => {
        if (audioData) {
            console.log('Audio Blob is set, now sending to cloud function');
            sendAudioToCloudFunction(audioData);
        }
    }, [audioData]);
    
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const newMediaRecorder = new MediaRecorder(stream);
            console.log('MediaRecorder started');
            setCanStopRecording(true);
    
            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
    
            newMediaRecorder.onstop = async () => {
                console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                console.log('Audio blob created:', audioBlob.size);
    
                if (audioBlob.size > 0) {
                    const filePath = `recordings/${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    setAudioData(filePath);
                }
            };
    
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };
    
    const stopRecording = () => {
        if (mediaRecorder) {
            console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setPatientSpecifics("Loading...");
        }
    };
    
    
    const sendAudioToCloudFunction = async (filePath) => {
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/whisper', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ filePath }),
            });
            const result = await response.json();
            console.log('Received response:', result);
            setPatientSpecifics(result.text);
            setIsRecording(false);
        } catch (error) {
            console.error('Error sending audio URL:', error);
        }
    };
    
    const fetchAssistantResponse = async () => {
        setIsFetching(true);
        setShowLoadingPopup(true);
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/customformsbroke', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: patientSpecifics }),
            });
            
            const result = await response.json();
            setAssistantResponse(result.text);
            setPatientSpecifics(result.text);
        } catch (error) {
            setAssistantResponse('Error fetching response.');
            setShowLoadingPopup(false);
            setIsFetching(false);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsFetching(false);
            setShowLoadingPopup(false);
        }
    };

    useEffect(() => {
        // Function to fetch user profile
        const fetchUserProfile = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUserProfile(userDoc.data());
                }
            }
        };

        // Function to fetch custom consent form templates
        const fetchConsentForms = async () => {
            const uid = auth.currentUser.uid;
            const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
            
            try {
                const consentFormsSnap = await getDoc(consentFormsRef);
                
                if (consentFormsSnap.exists()) {
                    setConsentForms(consentFormsSnap.data());
                } else {
                    console.log('No custom consent form data found. Using default forms.');
                    
                    // Use default consent forms from TreatmentDetails.js
                    setConsentForms({
                        introduction,
                        generalDentalRisks,
                        dentalcareDetails,
                        compositeDetails,
                        rootCanalDetails,
                        crownOrOnlayDetails,
                        dentureDetails,
                        hygieneDetails,
                        bridgeDetails,
                        extractionDetails,
                        treatmentAgreement,
                        whiteningDetails,
                        dentalimplantDetails,
                        patientBehaviour,
                        amalgamDetails,
                        fissuresealantDetails,
                        stainlesssteelcrownDetails,
                        compositebondingDetails,
                        invisalignDetails,
                        fixedorthoDetails,
                        veneerDetails,
                        sedationDetails,
                        fullarchimplantsDetails,
                        immediatedentureDetails,
                        enlightenDetails
                    });
                }
            } catch (error) {
                console.error('Error fetching custom consent form data:', error);
            }
        };

        // Call both fetch functions
        fetchUserProfile();
        fetchConsentForms();
    }, []);

  const handleTreatmentChange = (event) => {
    const { value } = event.target;
    if (event.target.checked) {
      setSelectedTreatments(prev => [...prev, value]);
    } else {
      setSelectedTreatments(prev => prev.filter(treatment => treatment !== value));
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
}

    const todayDate = formatDate(new Date());

  const calculateLines = (text, maxWidth, fontSize) => {
    const averageCharWidth = fontSize * 0.5; // This is a rough estimate. Adjust as needed.
    const charsPerLine = maxWidth / averageCharWidth;
    return Math.ceil(text.length / charsPerLine);
  };

  const generatePDF = async () => {
    setIsGenerating(true);
  
    const userProfileDetails = `
      <div style="text-align: center; margin-bottom: 70px;">
        ${userProfile.letterheadURL ? `<img src="${userProfile.letterheadURL}" alt="Letterhead" style="max-height: 200px; width: auto;"/>` : ''}
      </div>
      <div style="text-align: right; margin-bottom: 70px" >
        <h4 style="margin: 0;">Dr. ${userProfile.name}</h4>
        <h4 style="margin: 0;">${userProfile.practiceName}</h4>
        <h4 style="margin: 0;">${userProfile.practiceAddress}</h4>
        <h4 style="margin: 0;">${userProfile.practicePostcode}</h4>
        <br>
        <h4 style="margin: 0;">${userProfile.practiceEmail}</h4>
        <h4 style="margin: 0;">${userProfile.practicePhoneNumber}</h4>
      </div>
    `;
  
    let detailsToUse = `
      <div style="margin-bottom: 70px;">${userProfileDetails}</div>
      <div style=""><p>${todayDate}</p></div>
      <div style=""><p>Dear ${patientName},</p></div>
      <div style="margin-bottom: 70px;">${consentForms.introduction}</div>
      <div style="margin-bottom: 70px;">${consentForms.generalDentalRisks}</div>
    `;
  
    if (patientSpecifics.trim() !== '') {
      detailsToUse += `<div style="margin-bottom: 70px;">
        <h2>Details Specific To Your Current Situation</h2>
        ${patientSpecifics}
      </div>`;
    }
  
    selectedTreatments.forEach((treatment) => {
      if (consentForms[treatment]) {
        detailsToUse += `<div style="margin-bottom: 70px;">${consentForms[treatment]}</div>`;
      }
    });
  
    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.dentalcareDetails}</div>`;
    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.patientBehaviour}</div>`;
    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.treatmentAgreement}</div>`;
  
    if (!detailsToUse) return;
  
    try {
      console.log("Starting PDF generation process");
      console.log("Sending request with HTML content to the server");
  
      const response = await axios.post('https://us-central1-digitaltco-c40e9.cloudfunctions.net/generatePdf', {
        htmlContent: detailsToUse
      }, {
        responseType: 'blob'
      });
  
      console.log("Received response from server. Processing blob data...");
      const blob = new Blob([response.data], { type: 'application/pdf' });
  
      console.log("Creating download link for the PDF");
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${patientName} - Dr. ${userProfile.name}.pdf`;
  
      console.log("Initiating download...");
      link.click();
  
      console.log("Download initiated. Cleaning up object URLs");
      URL.revokeObjectURL(link.href);
      console.log("PDF generation and download process completed");
  
      setIsGenerating(false);
  
    } catch (error) {
      if (error.response) {
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
        console.error("Error Headers:", error.response.headers);
  
        // Specific check for auth error
        if (error.response.status === 401) {
          console.error("Authentication error. The provided token may be invalid or expired.");
        }
      } else if (error.request) {
        console.error("No response was received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
  
      console.error("Detailed error:", error.config);
    }
  };
  

const handleButtonClick = () => {
    if (patientSpecifics.trim() !== '') {
        fetchAssistantResponse();
    }
};

const formatFormKey = (key) => {
    return key.replace(/([A-Z])/g, ' $1')
              .replace(/^./, str => str.toUpperCase());
};



return (
    <main className="flex flex-col items-center tracking-tight mx-auto">
        <input
          type="text"
          id="patient-name"
          value={patientName}
          placeholder="Patient Name"
          onChange={(e) => setPatientName(e.target.value)}
          className="mx-auto mb-4 h-8 block px-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
          autoFocus
        />

        

      {showPatientSpecificInput ? (
        <div className="flex flex-col items-center w-2/5 mb-8">
            <ReactQuill
            value={patientSpecifics}
            onChange={setPatientSpecifics}
            style={{ marginBottom: '50px', width: '100%', height: '250px', }}
            modules={{
                toolbar: [
                [{ 'header': [1, 2, 3, 4, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                ['link'],
                ['clean']
                ]
            }}
            formats={[
                'header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'
            ]}
            placeholder="Enter patient-specific information..."
            />
            <div>
                <button 
                onClick={startRecording} 
                disabled={isRecording}
                className={`${isRecording ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                >
                    <FontAwesomeIcon icon={faMicrophone} />
                </button>
                <button 
                    onClick={stopRecording} 
                    disabled={!isRecording}
                    className={`${!isRecording ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                >
                    <FontAwesomeIcon icon={faStop} />
                </button>
                <button
                onClick={handleButtonClick}
                disabled={isFetching}
                className={`${isFetching ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-b border-t border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
                <button 
                onClick={togglePatientSpecificInput}
                className="px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
                >
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
          
        </div>
      ) : (
        <button onClick={togglePatientSpecificInput} className="ml-8 mb-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200">
            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                Add Patient Specific Information
            </span>
        </button>
      )}
      
      {/* Adjacent elements wrapped in a fragment */}
      <>
      <div className="grid grid-cols-4 gap-4 w-8/12 mx-auto">
        {Object.keys(consentForms)
          .filter((formKey) => !omittedKeys.includes(formKey))
          .map((formKey) => (
            <React.Fragment key={formKey}>
              <input
                id={formKey}
                type="checkbox"
                value={formKey}
                onChange={handleTreatmentChange}
                className="treatment-selection-input"
              />
              <label htmlFor={formKey} className="treatment-selection-label">
                {formKeyMappings[formKey] || formKey}
              </label>
            </React.Fragment>
          ))}
      </div>
        <button
          onClick={generatePDF}
          className="mt-8 ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
          disabled={isGenerating}
        >
          <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
            {isGenerating ? "Generating Forms..." : "Create PDF Consent"}
          </span>
        </button>
      </>
    </main>
  );
};

export default TreatmentSelection;

import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate, Link } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [gdprConsent, setGdprConsent] = useState(true);
  const [marketingConsent, setMarketingConsent] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState('GBP');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (email !== confirmEmail) {
      setError('Email and confirm email do not match.');
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Password and confirm password do not match.');
      setIsLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.');
      setIsLoading(false);
      return;
    }

    if (!gdprConsent) {
      setError('Please agree to the terms and privacy policy.');
      setIsLoading(false);
      return;
    }

    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, {
        email: email,
        gdprConsent: gdprConsent,
        marketingConsent: marketingConsent,
        selectedCurrency: selectedCurrency
      });

      // Add customer to digitaltcolandingpage project
      const addCustomerData = {
        email,
        firstName: '', // Add the necessary fields
        lastName: '',
        dentalPractice: '',
        timestamp: new Date().toISOString(),
        tag: 'reg'
      };

      await fetch('https://us-central1-digitaltcolandingpage.cloudfunctions.net/addOrUpdateCustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(addCustomerData)
      });

      navigate('/payment');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        // Try to log in the user
        try {
          await signInWithEmailAndPassword(auth, email, password);
          navigate('/payment');
        } catch (signInError) {
          if (signInError.code === 'auth/wrong-password') {
            setError('An account with this email already exists, but the password you entered is incorrect.');
          } else {
            setError('Account with this email already exists, wrong password used.');
          }
        }
      } else {
        setError('An error occurred while creating your account. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    if (!gdprConsent) {
      setError('Please agree to the terms and privacy policy.');
      return;
    }

    const googleProvider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      const userRef = doc(firestore, 'customers', user.uid);
      const userSnapshot = await getDoc(userRef);

      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          email: user.email,
          gdprConsent: gdprConsent,
          marketingConsent: marketingConsent,
          selectedCurrency: selectedCurrency
        });
      }

      const addCustomerData = {
        email: user.email,
        firstName: '', // Add the necessary fields
        lastName: '',
        dentalPractice: '',
        timestamp: new Date().toISOString(),
        tag: 'reg'
      };

      await fetch('https://us-central1-digitaltcolandingpage.cloudfunctions.net/addOrUpdateCustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(addCustomerData)
      });

      navigate('/payment');
    } catch (error) {
      setError('An error occurred while signing up with Google. Please try again later.');
      console.error(error);
    }
  };

  return (
    <section className="bg-gradient-to-br from-primary-50 to-primary-300 min-h-screen tracking-tighter overflow-auto">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative hidden lg:block h-16 lg:order-first lg:col-span-5 lg:h-full xl:col-span-6 text-center">
          <div className="mx-auto w-4/5 py-24">
            <h2 className="text-4xl font-bold text-primary-400 sm:text-5xl tracking-tighter md:text-6xl text-center">
              Elevate Your Efficiency with Smart Tools
            </h2>
            <div className="flex flex-col mt-8 p-2 bg-white border border-primary-300 text-left rounded-xl shadow-lg text-gray-800 z-10">
              <div className="mt-4 p-2 border-b border-primary-300">
                <h3 className="font-bold text-xl mb-2">
                  No Extra Paperwork
                </h3>
                <p className="mb-2">
                  Standard mode doesn't need patient consent, just jump right in.
                </p>
              </div>
              <div className="mt-4 p-2 border-b border-primary-300">
                <h3 className="font-bold text-xl mb-2">
                  Easy Peasy Learning
                </h3>
                <p className="mb-2">
                  A small learning curve, nothing you can't handle! You're a dentist; if you can navigate a root canal, you can definitely master our system.
                </p>
              </div>
              <div className="mt-4 p-2 border-b border-primary-300">
                <h3 className="font-bold text-xl mb-2">
                  Fort Knox Security
                </h3>
                <p className="mb-2">
                  Your data's safe with us! We guard it like a mouthguard during a hockey match—secure, encrypted, and confidential.
                </p>
              </div>
              <div className="mt-4 p-2">
                <h3 className="font-bold text-xl mb-2">
                  Mic Flexibility
                </h3>
                <p className="mb-2">
                  Got a mic? Any will do! Whether it's a fancy lapel or the one that came free with your phone, we're compatible.
                </p>
              </div>
            </div>
          </div>
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6 bg-white shadow mx-4 my-4 md:mx-16 md:my-10 rounded-xl">
          <div className="max-w-xl lg:w-3xl">
            <h1 className="mt-6 text-4xl font-bold text-primary-400 tracking-tighter sm:text-5xl md:text-6xl text-center mb-16">
              DigitalTCO
            </h1>
            <form className="mx-auto w-full" onSubmit={handleSubmit}>
              <div className="flex items-center justify-center mx-auto pb-8 border-b border-primary-300">
                <button type="button" className="flex items-center justify-center mx-auto p-2 bg-white text-primary-400 shadow-sm border-primary-400 border rounded-xl w-full hover:bg-gradient-to-r hover:from-primary-200 hover:to-primary-300 hover:text-white" onClick={handleGoogleSignUp}>
                  <FcGoogle className="text-xl mr-2" />
                  Sign up with Google
                </button>
              </div>
              <input type="email" name="email" id="email" className="mb-2 mt-8 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Enter your email" value={email} onChange={handleEmailChange} onBlur={handleEmailChange} required />
              <input type="email" name="confirmEmail" id="confirmEmail" className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Confirm your email" value={confirmEmail} onChange={handleConfirmEmailChange} onBlur={handleConfirmEmailChange} required />
              <input type="password" name="password" id="password" className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Enter your password" value={password} onChange={handlePasswordChange} onBlur={handlePasswordChange} required />
              <input type="password" name="confirmPassword" id="confirmPassword" className="mb-8 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Confirm your password" value={confirmPassword} onChange={handleConfirmPasswordChange} onBlur={handleConfirmPasswordChange} required />
              <button type="submit" className={`bg-primary-400 text-white p-2 rounded-xl w-full mx-auto ${isLoading ? 'animate-pulse bg-gray-400 cursor-not-allowed' : 'hover:bg-white hover:border hover:border-primary-400 hover:text-primary-400'}`} disabled={isLoading}>
                {isLoading ? 'Setting up your account...' : 'Sign up'}
              </button>
              <div className="max-w-xs mx-auto">
                {error && (
                  <div className="flex tracking-tighter text-normal text-sm text-center mt-4 mx-auto items-center justify-center text-gray-700">
                    <p>{error}</p>
                  </div>
                )}
              </div>
              <div className="flex justify-center items-center mt-4 mb-4">
                <span className="text-sm underline text-primary-400">
                  <Link to="/login">Already have an account? Click here to log in!</Link>
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="gdprConsent" className="checkbox-label-protrusive">
                  <input type="checkbox" id="gdprConsent" checked={gdprConsent} onChange={() => setGdprConsent(!gdprConsent)} />
                  I agree to the <a href="https://digitaltco.co.uk/trustcenter" target="_blank" rel="noopener noreferrer">terms and privacy policy</a>.
                </label>
              </div>
              <div className="checkbox-group-protrusive">
                <label htmlFor="marketingConsent" className="checkbox-label-protrusive">
                  <input type="checkbox" id="marketingConsent" className="checkbox" checked={marketingConsent} onChange={() => setMarketingConsent(!marketingConsent)} />
                  Be a game-changer! Get the latest DigitalTCO updates by email.
                </label>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
  );
};

export default SignUp;

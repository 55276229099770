import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTooth, 
    faStethoscope, 
    faChild, 
    faShieldAlt, 
    faCapsules, 
    faHeartbeat, 
    faSmileBeam, 
    faSmile, 
    faAlignJustify, 
    faNetworkWired, 
    faCrown, 
    faArchway, 
    faMask, 
    faScrewdriver, 
    faTeeth, 
    faBroom, 
    faBrush, 
    faProcedures, 
    faXRay,
    faShower, 
    faWrench, 
    faTeethOpen, 
    faSeedling,
    faSun,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import './ExamsAndConsults.css';
import { collection, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const VoiceChartList = () => {
    const [selectedTreatments, setSelectedTreatments] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [additionalTreatments, setAdditionalTreatments] = useState({});
    const [isInfoVisible, setIsInfoVisible] = useState(true); 

    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const initialTreatments = {
        'Basic Exam': { label: 'Basic Exam', icon: faStethoscope },
    };

    useEffect(() => {
        const fetchTreatments = async () => {
            const uid = auth.currentUser.uid;
            const treatmentsRef = collection(firestore, 'customers', uid, 'exam_templates');
            const snapshot = await getDocs(treatmentsRef);
            const fetchedTreatments = {};
            snapshot.forEach(doc => {
                // Assume no specific icon for additional treatments
                fetchedTreatments[doc.id] = { label: doc.id, icon: null };
            });
            setAdditionalTreatments(fetchedTreatments);
        };

        fetchTreatments();
    }, []);

    // Combine initial treatments with fetched additional treatments
    const allTreatments = { ...initialTreatments, ...additionalTreatments };

    const filteredTreatments = Object.entries(allTreatments).filter(([key, value]) =>
        value.label.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <div className="txnotes-container">
            <h2 className="txnotes-h2">Jaz Mode <sup>beta</sup></h2>
            {isInfoVisible && (
                    <div className="info-box-eac">
                        <p><strong>Really never write a note again: </strong> 
                        Wear a lapel mic. Hit record. Let DigitalTCO do the work.
                        </p>
                        <p>Beta testing - please provide feedback on the good, the bad, and the ugly!</p>
                    </div>
                )}
            <input
                type="text"
                placeholder="Search examination type..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="txnotes-search-input"
                autoFocus
            />
            <div className="txnotes-grid-container">
                {filteredTreatments.map(([key, value]) => (
                    <div key={key}>
                        <input id={key} type="checkbox" className="txnotes-input" />
                        <label htmlFor={key} className="txnotes-label">
                            <Link to={`/mvp/jazmode/${key}`} className="txnotes-link">
                                {value.icon && <FontAwesomeIcon icon={value.icon} style={{ marginRight: '8px' }} />}
                                {value.label}
                            </Link>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VoiceChartList;

    

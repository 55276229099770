import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faTimes, faPlay, faPause, faMicrophone, faPaperPlane, faStop } from '@fortawesome/free-solid-svg-icons';
import { collection, doc, setDoc, updateDoc, where, getDocs, query, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import LoadingPopup2 from  '../popup/LoadingPopup2';
import './SuperDentistCheatMode.css';
import CopyButton from '../CopyButton';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import RiskBankChecker from '../riskbank/RiskBankChecker';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';
import AudioErrorModal from '../elements/AudioErrorModal';
import SilenceErrorModal from '../elements/SilenceErrorModal';
import AudioEditor from '../audioeditor/AudioEditor';


const SuperDentistCheatMode = () => {
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [isInfoVisible, setIsInfoVisible] = useState(true); 
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [showLoadingPopup2, setShowLoadingPopup2] = useState(false);
    const [mode, setMode] = useState('normal');
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [dentistName, setDentistName] = useState('Dentist');
    const [chartingMethod, setChartingMethod] = useState('FDI');
    const [languagePreference, setLanguagePreference] = useState('EN');
    const audioDetectedRef = useRef(false);
    const [analyserKey, setAnalyserKey] = useState(0);
    const [isAudioErrorModalVisible, setIsAudioErrorModalVisible] = useState(false);
    const [isSilenceErrorModalVisible, setIsSilenceErrorModalVisible] = useState(false);
    const [isAudioEditorVisible, setIsAudioEditorVisible] = useState(false);
    const [showEditAudioButton, setShowEditAudioButton] = useState(false);


    useEffect(() => {
        const fetchChartingMethod = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setChartingMethod(userDoc.data().chartingMethod || 'FDI'); // Load charting method from Firestore
                }
            }
        };
        fetchChartingMethod();
    }, []);

    // Fetch Language Preference and Dentist Name from Firestore or localStorage
    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };
    

    const globalNoteRef = useRef(null);

    const handleAssistantResponseChange = (e) => {
        setAssistantResponse(e.target.value);
    };

// useEffect(() => {
//     if (audioData) {
//         // console.log('Audio Blob is set, now sending to cloud function');
//         sendAudioToCloudFunction(audioData);
//     }
// }, [audioData]);

const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream);
        const newMediaRecorder = new MediaRecorder(stream);
        // // console.log('MediaRecorder started');
        setCanStopRecording(true);

        audioDetectedRef.current = false;
        setAnalyserKey(prevKey => prevKey + 1);

        let audioChunks = [];
        newMediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };

        newMediaRecorder.onstop = async () => {
            // // console.log('Recording stopped, creating audio blob');
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            // // console.log('Audio blob created:', audioBlob.size);

            if (audioBlob.size === 0) {
                alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                setIsRecording(false);
                return; // Early return to prevent further execution
            }

            if (audioBlob.size > 0) {
                const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                const filePath = `sdcm/${userUID}/${Date.now()}.webm`;
                const storageRef = ref(getStorage(), filePath);
                await uploadBytes(storageRef, audioBlob);
                
                getDownloadURL(storageRef).then(async (downloadURL) => {
                    // // console.log('Download URL:', downloadURL);
                    setDownloadUrl(downloadURL);

                    let finalFilePath = filePath;
                    if (audioBlob.size > 23000000) { // 23MB
                        // // console.log("File size exceeds 23MB, compressing...");
                        setShowLoadingPopup2(true);
                        finalFilePath = await compressAudio(filePath);
                    }

                    sendAudioToCloudFunction(finalFilePath, downloadURL);
                });
            }
        };

        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start();
        setIsRecording(true);
    } catch (error) {
        // console.error('Error in starting recording:', error);
    }
};


const stopRecording = () => {
    if (mediaRecorder) {
        // console.log('Stopping recording...');
        mediaRecorder.stop();
        setIsRecording(false);
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        setTranscribedNote("Loading...");

        // Check if any audio was detected during the recording
        if (!audioDetectedRef.current) {
            setIsAudioErrorModalVisible(true); // Show the error modal
            setTranscribedNote('');
            return;
        }
    }
};

const hasRepetitiveWords = (transcript) => {
    const words = transcript.split(/\s+/);
    let repeatCount = 1;
    for (let i = 1; i < words.length; i++) {
        if (words[i] === words[i - 1]) {
            repeatCount++;
            if (repeatCount >= 4) { // Adjust the threshold as needed
                return true;
            }
        } else {
            repeatCount = 1;
        }
    }
    return false;
};

const sendAudioToCloudFunction = async (filePath, downloadURL) => {
    try {
        // Get the current authenticated user
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        // Get the Firebase ID token of the current user
        const idToken = await user.getIdToken();

        // Prepare the payload
        const payload = {
            filePath: filePath,
            language: languagePreference // Send the user's language preference
        };

        // Make the POST request with Authorization header
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            // Handle HTTP errors
            const errorText = await response.text();
            throw new Error(`Server Error: ${errorText}`);
        }

        const result = await response.json();
        setShowLoadingPopup2(false);
        let appendedTranscript = result.text;

        // RiskBank - check for triggers and append risks if found
        const triggersInTranscript = await RiskBankChecker(result.text);
        // console.log("Triggers found in transcript:", triggersInTranscript);

        if (triggersInTranscript.length > 0) {
            for (const trigger of triggersInTranscript) {
                const risksQuery = query(
                    collection(firestore, 'customers', user.uid, 'riskBank'),
                    where("trigger", "==", trigger)
                );
                const querySnapshot = await getDocs(risksQuery);
                querySnapshot.forEach(docSnap => {
                    const riskData = docSnap.data();
                    appendedTranscript += `\n\nRisk for ${riskData.name}: ${riskData.risk}`;
                });
            }
        }

        setTranscribedNote(appendedTranscript);

        if (hasRepetitiveWords(appendedTranscript)) {
            setIsSilenceErrorModalVisible(true);
            setShowEditAudioButton(true);
        }

        // Store the note
        const noteRef = doc(collection(firestore, 'customers', user.uid, 'notes'));
        await setDoc(noteRef, {
            title: "Super Dentist Cheat Mode",
            content: "", 
            transcript: appendedTranscript,
            audio_recording: downloadURL,
            patientId: patientId,
            timestamp: new Date()
        });
        globalNoteRef.current = noteRef;

        // console.log("Document created/updated successfully");
    } catch (error) {
        console.error("Error:", error);
        setShowLoadingPopup2(false);
        setAssistantResponse('Error fetching transcription.');
        alert("Sorry, there was an error processing your request. Please try again.");
    }
};
 

const compressAudio = async (filePath) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Get the Firebase ID token of the current user
    const idToken = await user.getIdToken();

    // Prepare the payload
    const payload = {
        filePath: filePath,
        uid: user.uid // Ensure uid matches the authenticated user
    };

    // Make the POST request with Authorization header
    const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/compressAudio', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
        body: JSON.stringify(payload),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok during compression');
    }
  
    const data = await response.json();
    // // console.log('Compression response:', data);
    return data.outputPath; // Return the path of the compressed file
};
    

const fetchAssistantResponse = async () => {
    setIsFetching(true);
    setShowLoadingPopup(true);
    const endpoint = mode === 'normal' ? 'SDCMbroke' : 'SDCMsimple';
    try {
        // Prepare the prompt
        const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.\nDentist: ${dentistName}\n${transcribedNote}\nTeeth Notation Used: ${chartingMethod}`;

        // Get the Firebase ID token of the current user
        const idToken = await auth.currentUser.getIdToken();

        const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
            },
            body: JSON.stringify({ prompt: prompt }),
        });

        const result = await response.json();
        setAssistantResponse(result.text);

        // Update or create the Firestore document
        if (globalNoteRef.current) {
            // Update the existing Firestore document
            await updateDoc(globalNoteRef.current, {
                content: result.text,
            });
        } else {
            // Create a new Firestore document
            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            await setDoc(noteRef, {
                title: "Super Dentist Cheat Mode",
                content: result.text,
                patientId: patientId,
                timestamp: new Date()
            });
            globalNoteRef.current = noteRef;
        }

        // console.log("Document created/updated successfully");
    } catch (error) {
        // console.error("Error updating document: ", error);
        setAssistantResponse('Error fetching response.');
        setShowLoadingPopup(false);
        setIsFetching(false);
        alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        // console.error('Error fetching response:', error);
    } finally {
        setIsFetching(false);
        setShowLoadingPopup(false);
    }
};


    const handleButtonClick = () => {
        if (transcribedNote.trim() !== '') {
            fetchAssistantResponse();
        }
    };

    
    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(assistantResponse).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000); // Reset message after 2 seconds
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleInputChange = (e) => {
        setTranscribedNote(e.target.value);
        
    };

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/\*\*/g, ''); 
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    
    const containsReferral = /referr(al|er)/i.test(assistantResponse);

    const handleCheckAudioQuality = () => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
    };


    // todelete

    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         // console.log('File selected:', file.name);
    
    //         const filePath = `recordings/${Date.now()}-${file.name}`;
    //         const storageRef = ref(getStorage(), filePath);
    
    //         try {
    //             await uploadBytes(storageRef, file);
    //             const downloadURL = await getDownloadURL(storageRef);
    
    //             let finalFilePath = filePath;
    //             if (file.size > 23000000) { // 23MB
    //                 // console.log("File size exceeds 23MB, compressing...");
    //                 setShowLoadingPopup2(true);
    //                 finalFilePath = await compressAudio(filePath);
    //             }
    
    //             sendAudioToCloudFunction(finalFilePath, downloadURL);
    //         } catch (error) {
    //             // console.error('Error uploading file:', error);
    //         }
    //     }
    // };
    
    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const handleAudioEdited = async (editedAudioBlob) => {
        const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
        const filePath = `sdcm/${userUID}/${Date.now()}_edited.webm`;
        const storageRef = ref(getStorage(), filePath);
        await uploadBytes(storageRef, editedAudioBlob);
    
        const downloadURL = await getDownloadURL(storageRef);
    
        // Reprocess the audio
        sendAudioToCloudFunction(filePath, downloadURL);
    
        // Hide the audio editor
        setIsAudioEditorVisible(false);
    };    

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
        
            {showLoadingPopup && <LoadingPopup />}
            {showLoadingPopup2 && <LoadingPopup2 />}
            <AudioErrorModal 
                isVisible={isAudioErrorModalVisible} 
                onClose={() => setIsAudioErrorModalVisible(false)} 
            />
            <SilenceErrorModal 
                isVisible={isSilenceErrorModalVisible} 
                onClose={() => setIsSilenceErrorModalVisible(false)} 
                onEditAudio={() => {
                    setIsAudioEditorVisible(true);
                    setIsAudioErrorModalVisible(false);
                }}
            />

            {isAudioEditorVisible && (
                <AudioEditor
                    audioUrl={downloadUrl}
                    onSave={handleAudioEdited}
                    onCancel={() => setIsAudioEditorVisible(false)}
                />
            )}

            {/* TOP BAR */}
            <div className="flex items-center w-[65%] mx-auto justify-center">
                <div>
                    <input
                        type="text"
                        id="patientId"
                        value={patientId}
                        onChange={handlePatientIdChange}
                        placeholder="Patient ID"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="mode-toggle-container">  
                    <label className="simple-toggle-label">
                        <input
                            type="checkbox"
                            checked={mode === 'simplified'}
                            onChange={() => setMode(mode === 'normal' ? 'simplified' : 'normal')}
                            className="simple-toggle-input"
                        />
                        <span className="simple-toggle-slider"></span>
                    </label>
                    <span className="mode-label">{mode.charAt(0).toUpperCase() + mode.slice(1).toLowerCase()} Mode</span>
                </div>
            </div>
               
            {/* MAIN SECTION */}
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">

            {/* LEFT INPUT COLUMN */}
            <div className="flex flex-col">
                <label className="block mb-2 text-xs text-primary-400">Dentist's Input</label>
                <textarea
                    placeholder="Present the case..."
                    value={transcribedNote}
                    onChange={(e) => setTranscribedNote(e.target.value)}
                    readOnly={isRecording || transcribedNote === "Loading..."}
                    className="blockoverflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="20"
                ></textarea>

                {mediaStream && (
                    <div className="active-audio-analyser-container">
                        <ActiveAudioAnalyser
                            key={analyserKey}
                            audioStream={mediaStream}
                            isPaused={isPaused}
                            onAudioDetected={() => {
                                // console.log('Audio detected callback triggered in SuperDentistCheatMode'); // Debugging
                                audioDetectedRef.current = true;
                            }}
                        />
                    </div>
                )}

                
                {/* BUTTONS */}
                <div className="mx-auto mt-2">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button 
                                onClick={startRecording} 
                                disabled={isRecording || isPaused}
                                className={`${isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faMicrophone} />
                            </button>

                            {isRecording && (
                                <button 
                                    onClick={isPaused ? resumeRecording : pauseRecording}
                                    className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                </button>
                            )}

                            <button 
                                onClick={stopRecording} 
                                disabled={!isRecording || isPaused}
                                className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </button>
                
                            <button 
                                onClick={fetchAssistantResponse} 
                                disabled={isFetching || isRecording || isPaused}
                                className={`${isFetching || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>

                         {/* Show "Edit Audio" button if silence issue is detected */}
                            {showEditAudioButton && (
                                <div className="flex justify-center mt-4">
                                    <button
                                        className="inline-flex items-center justify-center p-0.5 mb-4 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
                                        onClick={() => setIsAudioEditorVisible(true)}
                                    >
                                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                            Edit Audio
                                        </span>
                                    </button>
                                </div>
                            )}

                    </div>
                </div>

                {/* RIGHT OUTPUT COLUMN */}
                <div className="flex flex-col">
                
                <label className="block mb-2 text-xs text-primary-400">AI Output</label>
                    <textarea
                        value={removeDoubleAsterisks(assistantResponse)}
                        placeholder="Notes will load here..."
                        onChange={handleAssistantResponseChange}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="20"
                    />

                    <div className="flex flex-col items-center justify-center mx-auto mt-2">
                        <CopyButton textToCopy={removeDoubleAsterisks(assistantResponse)} />
                        <div className="mx-auto mt-2">
                            {assistantResponse && (
                                <PatientLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                            {containsReferral && (
                                <ReferralLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                            {assistantResponse && (
                                <ReferralResponseLetter noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </main>
    );
};

export default SuperDentistCheatMode;
import React, { useState, useEffect, useRef } from 'react';
import './riskbank.css'; // Import your CSS file
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane, faStop, faSquarePlus, faPenToSquare, faFloppyDisk, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';

const RiskBank = () => {
    const [generatedNote, setGeneratedNote] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const globalNoteRef = useRef(null);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [riskName, setRiskName] = useState('');
    const [trigger, setTrigger] = useState('');
    const [risks, setRisks] = useState([]);
    const [editingRisk, setEditingRisk] = useState(null);
    const [selectedRisk, setSelectedRisk] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);


    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const handleRowClick = (risk) => {
        setSelectedRisk(selectedRisk && risk.id === selectedRisk.id ? null : risk);
    };
    

    const handleEditClick = (risk) => {
        setRiskName(risk.name);
        setTrigger(risk.trigger);
        setEditingRisk(risk);
        setShowAddPopup(true);
    };

    const handleAddClick = () => {
        setShowAddPopup(true);
      };
      
      const handleRiskNameChange = (e) => {
        setRiskName(e.target.value);
      };
      
      const handleTriggerChange = (e) => {
        setTrigger(e.target.value);
      };
      
      const handleSave = async () => {
        if (auth.currentUser) {
            if (editingRisk) {
                // Ensure status is defined
                const status = editingRisk.status ? editingRisk.status : 'inactive';
    
                // Update the existing document
                const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', editingRisk.id);
                await updateDoc(riskRef, { name: riskName, trigger: trigger, status: status });
            } else {
                // Create a new document
                const riskRef = collection(firestore, 'customers', auth.currentUser.uid, 'riskBank');
                await addDoc(riskRef, { name: riskName, trigger: trigger, status: 'inactive' });
            }
        }
        resetForm();
    };
    
    

    const handleDelete = async () => {
        if (editingRisk) {
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', editingRisk.id);
            await deleteDoc(riskRef);
        }
        resetForm();
    };

    const resetForm = () => {
        setRiskName("");
        setTrigger("");
        setShowAddPopup(false);
        setEditingRisk(null);
    };
      
      const handleCancel = () => {
        resetForm();
      };

      const updateCurrentlyActive = async (risk, isActive) => {
        const currentlyActiveDocId = "activeRisks";
        const currentlyActiveRef = doc(firestore, 'customers', auth.currentUser.uid, 'currentlyActiveRisks', currentlyActiveDocId);
    
        const docSnap = await getDoc(currentlyActiveRef);
        let currentlyActive = docSnap.exists() && docSnap.data().triggers ? docSnap.data().triggers : [];
    
        if (isActive) {
            // Add risk trigger to the array if not already included
            if (!currentlyActive.includes(risk.trigger)) {
                currentlyActive.push(risk.trigger);
            }
        } else {
            // Remove risk trigger from the array
            currentlyActive = currentlyActive.filter(trigger => trigger !== risk.trigger);
        }
    
        // Update or set the document with the new array
        await setDoc(currentlyActiveRef, { triggers: currentlyActive });
    };
    

    const handleToggle = async (event, riskId, currentStatus) => {
        event.stopPropagation();
        const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
        const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', riskId);
    
        await updateDoc(riskRef, { status: newStatus });
    
        // Find the risk
        const risk = risks.find(r => r.id === riskId);
        if (risk) {
            updateCurrentlyActive(risk, newStatus === 'active');
        }
    };

    const handleSaveChanges = async () => {
        if (selectedRisk) {
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                // Update it with the current value of generatedNote
                risk: generatedNote 
            });
            alert("Changes saved successfully!");
        }
    };
    
    
    
      useEffect(() => {
        // Listening for real-time updates
        if (auth.currentUser) {
          const unsubscribe = onSnapshot(
            collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'),
            (snapshot) => {
              const risksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              setRisks(risksData);
            },
            (error) => {
            //   console.error("Error fetching risks:", error);
            }
          );
    
          return () => unsubscribe(); // Unsubscribe from listener when component unmounts
        }
      }, []);

      useEffect(() => {
        const fetchRiskData = async () => {
            if (selectedRisk) {
                const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
                const docSnap = await getDoc(riskRef);
                
                if (docSnap.exists()) {
                    // Update the textarea with the fetched risk or transcript
                    const riskData = docSnap.data();
                    setGeneratedNote(riskData.risk || ''); // Assuming 'risk' field contains the risk text
                    setTranscribedNote(riskData.transcript || '');
                }
            } else {
                // Clear the textarea if no risk is selected
                setGeneratedNote('');
                setTranscribedNote('');
            }
        };

        fetchRiskData();
    }, [selectedRisk]);
   
    
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            // console.log('MediaRecorder started');
            setCanStopRecording(true);
    
            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
    
            newMediaRecorder.onstop = async () => {
                // console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                // console.log('Audio blob created:', audioBlob.size);
    
                if (audioBlob.size === 0) {
                    alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                    setIsRecording(false);
                    return; // Early return to prevent further execution
                }
    
                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const filePath = `riskbank/${uid}/riskbank-${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    getDownloadURL(storageRef).then((downloadURL) => {
                        // console.log('Download URL:', downloadURL);
                        setAudioData(filePath);
                        setDownloadUrl(downloadURL);
                        sendAudioToCloudFunction(filePath, downloadURL);
                    });
                }
            };
    
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            // console.error('Error in starting recording:', error);
        }
    };
    
    const stopRecording = async () => {
        if (mediaRecorder) {
            setIsGenerating(true);
            setShowLoadingPopup(true);
            // console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setCanStopRecording(false); // Disable the stopRecording button after recording stops
            setTranscribedNote("Loading...");
    
            // // Automatically call generate note function after stopping recording
            // if (audioData) {
            //     console.log("sending for transcription");
            //     await sendAudioToCloudFunction(audioData);
            //     console.log("transcription receieved");
            // }
    
            // const prompt = `Audio transcript from dentist: ${transcribedNote}.`;
            // console.log("sending to generate note:", prompt);
            // callGenerateNoteFunction(prompt);
        }
    };
    
    
    // useEffect(() => {
    //     if (audioData) {
    //         console.log('Audio Blob is set, now sending to cloud function');
    //         sendAudioToCloudFunction(audioData);
    //     }
    // }, [audioData]);

    const sendAudioToCloudFunction = async (filePath, downloadURL) => {
        if (!selectedRisk) return; // Ensure there's a selected risk
    
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }
            
            const result = await response.json();
            // console.log('Received response:', result);
            setTranscribedNote(result.text);
            // setIsRecording(false);
    
            // Update the selected risk document with transcript
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                transcript: result.text,
                audio_recording: downloadURL
                // other fields you might want to update...
            });
            const prompt = `Audio transcript from dentist: ${result.text}.`;
            // console.log("sending to generate note:", prompt);
            callGenerateNoteFunction(prompt);
        } catch (error) {
            // console.error('Error sending audio URL:', error);
        }
    };  
    
    const callGenerateNoteFunction = async (prompt) => {
        // if (!selectedRisk) return; // Ensure there's a selected risk
    
        setIsGenerating(true);
        setShowLoadingPopup(true);
    
        const transcript = selectedRisk.transcript || ''; // Fallback if no transcript
    
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/riskBankWriter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setGeneratedNote(responseData.text);
    
            // Update the selected risk document with the generated note
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                risk: responseData.text,
                // other fields you might want to update...
            });
        } catch (error) {
            // console.error('Error generating note:', error);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsGenerating(false);
            setShowLoadingPopup(false);
        }
    };  
    
        const removeDoubleAsterisks = (text) => {
            let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
            formattedText = formattedText.replace(/##/g, ''); // Remove ##
            formattedText = formattedText.replace(/###/g, ''); // Remove ###
            formattedText = formattedText.replace(/【\d+†.*?】/g, '');
            return formattedText;
        };
    

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
        {showLoadingPopup && <LoadingPopup />}

        {showAddPopup && (
        <div className="riskbank-overlay">
        <div className="riskbank-popup">
            <div className="flex flex-col">
            <label className="riskbank-input-label">
                <input
                type="text"
                value={riskName}
                onChange={handleRiskNameChange}
                className="riskbank-text-input"
                />
                <span className="riskbank-input-placeholder">Risk Name</span>
            </label>
            <label className="riskbank-input-label">
                <input
                type="text"
                value={trigger}
                onChange={handleTriggerChange}
                className="riskbank-text-input"
                />
                <span className="riskbank-input-placeholder">Trigger</span>
            </label>
            
            <div className="flex mx-auto text-center">
                    <button         
                    className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
                    onClick={handleSave}
                    >
                        {editingRisk ? 'Update' : 'Save'}
                    </button>
                    {editingRisk && 
                        <button 
                        onClick={handleDelete}
                        className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"

                        >
                            Delete
                        </button>
                    }
                    <button 
                    onClick={resetForm}
                    className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
                    >
                        Cancel
                    </button>
            </div>
            </div>
        </div>
        </div>
        )}

      
      <div className="content-container-rb">
        <button className="bg-primary-400 p-2 rounded-lg text-white" onClick={handleAddClick}><FontAwesomeIcon icon={faSquarePlus} /></button>

        {/* TABLE */}
        <div className="table-container-rb">
            <table className="table-rb">
                <thead>
                    <tr>
                        <th>Risk Name</th>
                        <th>Trigger</th>
                        <th>Status</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {risks.map(risk => (
                        <tr
                            key={risk.id}
                            className={`hoverable-cell ${selectedRisk && risk.id === selectedRisk.id ? 'selected-row-rb' : ''}`}
                            onClick={() => handleRowClick(risk)}
                        >
                            <td className="truncate">{risk.name}</td>
                            <td className="truncate">{risk.trigger}</td>
                            <td>
                                <label className="riskbank-toggle-label">
                                    <input
                                        type="checkbox"
                                        checked={risk.status === 'active'}
                                        onChange={(e) => handleToggle(e, risk.id, risk.status)}
                                        className="riskbank-toggle-input"
                                    />
                                    <span className="riskbank-toggle-slider"></span>
                                </label>
                            </td>
                            <td>
                                <button onClick={() => handleEditClick(risk)}><FontAwesomeIcon icon={faPenToSquare} /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>


        {/* INPUT */}
            <div className="textarea-container-rb">

                {/* TEXT INPUT */}
                <textarea
                    className="textarea-rb"
                    placeholder="What's your spiel? Spill the beans, Doc!"
                    value={generatedNote}
                    onChange={(e) => setGeneratedNote(e.target.value)}
                    readOnly={isRecording || isGenerating}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="25"
                ></textarea>

                {mediaStream && (
                    <div className="active-audio-analyser-container">
                        <ActiveAudioAnalyser audioStream={mediaStream} isPaused={isPaused} />
                    </div>
                )}


                {/* BUTTONS */}
                <div className="mx-auto mt-2">
                    <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button 
                                onClick={startRecording} 
                                disabled={isRecording || isPaused || !selectedRisk}  // Disable button if no risk is selected
                                className={`${isRecording || isPaused || !selectedRisk ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faMicrophone} />
                            </button>

                            {isRecording && (
                                <button 
                                    onClick={isPaused ? resumeRecording : pauseRecording}
                                    className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                </button>
                            )}

                            <button 
                                onClick={stopRecording} 
                                disabled={!isRecording || isPaused}
                                className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </button>
                    {selectedRisk && (
                        <button 
                            className="px-4 py-2 text-xl font-medium text-primary-400 bg-white border rounded-e-lg border-gray-200 hover:bg-primary-400 hover:text-white"
                            onClick={handleSaveChanges}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                        </button>
                    )}
                    </div>
                </div>
                
            </div>

        </div>

    </main>
  );
}

export default RiskBank;
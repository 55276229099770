import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, writeBatch, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';

const ExamsAndConsultTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [searchInput, setSearchInput] = useState('');
  let treatments = Object.keys(templates); // Now dynamic, based on the templates object
  const [isTemplatesVisible, setIsTemplatesVisible] = useState(false);
  const textareaRef = useRef(null);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateContent, setNewTemplateContent] = useState('');
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFixing, setIsFixing] = useState(false); // Add fixing state


  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleEditTemplate = () => {
    if (!selectedTemplate) {
      alert("Please select a template to edit.");
      return;
    }
    setNewTemplateName(selectedTemplate);
    setNewTemplateContent(templates[selectedTemplate]);
    setIsPopupVisible(true);
  };

  const saveNewTemplate = async () => {
    const trimmedTemplateName = newTemplateName?.trim(); // Safeguard against undefined
    const trimmedTemplateContent = newTemplateContent?.trim(); // Safeguard against undefined
  
    if (!trimmedTemplateName || !trimmedTemplateContent) {
      alert("Please enter a valid template name and content.");
      return;
    }
  
    // Check for placeholders
    if (containsPlaceholders(trimmedTemplateContent)) {
      setIsConfirmationVisible(true);
    } else {
      await confirmSaveTemplate(trimmedTemplateName, trimmedTemplateContent);
    }
  };

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/###/g, ''); // Remove ###
    formattedText = formattedText.replace(/【\d+†.*?】/g, ''); // Remove citations
    return formattedText;
};
    
  // Function to handle fixing the template automatically
  const fixTemplateAutomatically = async () => {
    setIsFixing(true);
    try {
      const response = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/templateFixer',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ prompt: newTemplateContent }),
        }
      );
  
      const result = await response.json();
  
      if (!result.text) {
        throw new Error('No fixed template returned from API');
      }

      const cleanedResult = removeDoubleAsterisks(result.text);
  
      // Update the template content with the fixed result
      setNewTemplateContent(cleanedResult || '');
  
      const trimmedTemplateName = newTemplateName ? newTemplateName.trim() : '';
      const trimmedFixedTemplate = cleanedResult ? cleanedResult.trim() : '';
  
      if (!trimmedTemplateName || !trimmedFixedTemplate) {
        alert('Template name or content is missing.');
        setIsFixing(false);
        return;
      }
  
      await confirmSaveTemplate(trimmedTemplateName, trimmedFixedTemplate);
  
      setIsFixing(false);
      setIsConfirmationVisible(false);
    } catch (error) {
      console.error('Error fixing template:', error);
      alert('Error fixing template. Please try again.');
      setIsFixing(false);
    }
  };
    
  

  const toggleTemplatesVisibility = () => {
    setIsTemplatesVisible(!isTemplatesVisible);
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const uid = auth.currentUser.uid;
      const loadedTemplates = {};
  
      // Fetch all template documents from Firestore
      const templatesRef = collection(firestore, 'customers', uid, 'exam_templates');
      const querySnapshot = await getDocs(templatesRef);
  
      querySnapshot.forEach((doc) => {
        // Add each template to the loadedTemplates object
        loadedTemplates[doc.id] = doc.data().text;
      });
  
      setTemplates(loadedTemplates);
    };
  
    loadTemplates();
  }, []);
  

  const handleTemplateSelection = (treatment) => {
    setSelectedTreatment(treatment);
    setSelectedTemplate(treatment); // Set the selectedTemplate to the treatment selected
  };
  
const updateSelectedTemplate = (newTemplate) => {
    setSelectedTemplate(newTemplate);
    // Ensure the textarea is updated with the new template
    if (textareaRef.current) {
        textareaRef.current.focus();
    }
};

const handleSaveTemplate = async () => {
  if (!selectedTemplate) return;

  const uid = auth.currentUser.uid;
  const templateRef = doc(firestore, 'customers', uid, 'exam_templates', selectedTemplate);
  try {
    await setDoc(templateRef, { text: templates[selectedTemplate] });
    alert('Template saved successfully');
  } catch (error) {
    console.error('Error saving template:', error);
  }
};

const confirmSaveTemplate = async (trimmedTemplateName, trimmedTemplateContent) => {
  if (!trimmedTemplateName || !trimmedTemplateContent) {
    alert('Template name or content is missing.');
    setIsSaving(false);
    return;
  }

  setIsSaving(true);
  const uid = auth.currentUser.uid;

  try {
    const newTemplateRef = doc(firestore, 'customers', uid, 'exam_templates', trimmedTemplateName);

    await setDoc(newTemplateRef, { text: trimmedTemplateContent });

    const updatedTemplates = {
      ...templates,
      [trimmedTemplateName]: trimmedTemplateContent,
    };
    setTemplates(updatedTemplates);

    alert('Template saved successfully');
  } catch (error) {
    console.error('Error saving template:', error);
    alert('Error saving template. Please try again.');
  }
  setIsSaving(false);
};

const handleSaveAllTemplates = async () => {
  const uid = auth.currentUser.uid;
  try {
    const batch = writeBatch(firestore);

    for (const [key, value] of Object.entries(templates)) {
      const templateRef = doc(firestore, 'customers', uid, 'exam_templates', key);
      batch.set(templateRef, { text: value });
    }

    await batch.commit();
    alert('All templates saved successfully');
  } catch (error) {
    console.error('Error saving all templates:', error);
  }
};

  const handleTextChange = (e) => {
    setTemplates({ ...templates, [selectedTemplate]: e.target.value });
  };

const filteredTreatments = treatments.filter(treatment =>
    treatment.toLowerCase().includes(searchInput.toLowerCase())
);

const deleteTemplate = async () => {
  if (!selectedTemplate) {
    alert("Please select a template to delete.");
    return;
  }

  // Ask for confirmation before deleting
  const isConfirmed = window.confirm("Are you sure you want to delete this template?");

  if (isConfirmed) {
    const uid = auth.currentUser.uid;
    const templateRef = doc(firestore, 'customers', uid, 'exam_templates', selectedTemplate);

    try {
      await deleteDoc(templateRef); // Correctly delete the template document

      // Update the local state to remove the deleted template
      const updatedTemplates = { ...templates };
      delete updatedTemplates[selectedTemplate];
      setTemplates(updatedTemplates);

      // Reset the selected template
      setSelectedTemplate('');
      setSelectedTreatment('');

      alert('Template deleted successfully');
      togglePopup(); // Close the popup
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  }
};

const containsPlaceholders = (text) => {
  const placeholderPatterns = [
    /\[.*?\]/,        // Matches anything inside square brackets
    /_{2,}/,          // Matches two or more underscores
    /___+/,           // Matches three or more underscores
    /\.\.\.+/,        // Matches three or more dots
    /\([a-zA-Z]\)/,   // Matches (a), (b), etc.
    /\/\s*/,          // Matches slashes used in multiple choices
    /{{.*?}}/,        // Matches double curly braces
    /<<.*?>>/,        // Matches double angle brackets
  ];

  return placeholderPatterns.some((pattern) => pattern.test(text));
};



  return (
    <main className="flex flex-col items-center tracking-tight w-full mx-auto">
      {isConfirmationVisible && (
        <div className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center p-4 text-sm tracking-tighter">
          <div className="relative bg-white shadow-lg mx-auto w-1/3 p-4 flex flex-col items-center rounded-xl gap-4">
            <h2 className="text-lg font-bold text-red-600">Warning</h2>
            <p className="text-center">
              This template includes placeholders or multiple-choice options that might not function as expected.
            </p>
            <p className="text-center underline">
              <a href="https://youtu.be/26nwK2fpF6I" target="_blank" rel="noopener noreferrer">
                Click here to learn the key concept underpinning DigitalTCO
              </a>
            </p>
            <p className="text-center">
              Would you like us to fix it for you?
            </p>

            <div className="flex gap-4">
            <button
              onClick={fixTemplateAutomatically}
              className={`px-4 py-2 text-xs text-white bg-red-600 rounded-lg ${
                isFixing ? 'cursor-not-allowed animate-pulse bg-red-300' : 'hover:bg-red-300'
              }`}
              disabled={isFixing}
            >
              {isFixing ? 'Fixing...' : 'Fix Template Automatically'}
            </button>

            {!isFixing && (
              <>
                <button
                  onClick={() => {
                    confirmSaveTemplate(
                      newTemplateName ? newTemplateName.trim() : '',
                      newTemplateContent ? newTemplateContent.trim() : ''
                    );
                    setIsConfirmationVisible(false);
                  }}
                  className={`px-4 py-2 text-xs text-white bg-gray-600 rounded-lg hover:bg-gray-700 ${
                    isSaving ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save Anyway'}
                </button>

                <button
                  onClick={() => setIsConfirmationVisible(false)}
                  className="px-4 py-2 text-xs text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
              </>
            )}

            </div>
          </div>
        </div>
      )}

       {/* TOP BAR */}
       <div className="flex items-center w-[65%] mx-auto justify-center my-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="block w-2/5 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
            autoFocus
          />
          <button 
          onClick={togglePopup} 
          className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
          >
            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
              Create New Exam Template
            </span>
          </button>
      </div>

      {/* TREATMENT BUTTONS */}
      <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-wrap justify-center gap-4 mb-4">
            {filteredTreatments.map(treatment => (
              <div key={treatment}>
                <button
                  className={`${selectedTreatment === treatment ? 'p-2 bg-primary-400 text-white text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400' : 'p-2 bg-white text-gray-700 text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400'}`}
                  onClick={() => handleTemplateSelection(treatment)}
                >
                  {treatment}
                </button>
                
              </div>
            ))}
          </div>

          <textarea
            className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            rows="20"
            value={templates[selectedTemplate] || ''}
            onChange={handleTextChange}
            placeholder="Type the template here..."
            ref={textareaRef}
            disabled={!selectedTreatment}
          />
      </div>



          <div className="flex">
            <button 
            className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
            onClick={handleSaveTemplate}
            >
              Save
            </button>
            <button 
            className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
            onClick={handleSaveAllTemplates}
            >
              Save All
            </button>
            <button 
            className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
            onClick={handleEditTemplate}
            >
              Edit Template
            </button>
           
          </div>
        
      

      {isPopupVisible && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4 text-sm tracking-tighter">
        <div className="relative bg-white shadow-lg mx-auto w-2/5 p-4 flex flex-col items-center rounded-xl gap-2">
          <input
            type="text"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
            placeholder="Treatment Name"
            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
          />
          <textarea
            value={newTemplateContent}
            onChange={(e) => setNewTemplateContent(e.target.value)}
            placeholder="Template Content"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            rows="30"
          />
          <div className="flex">
          <button
            onClick={saveNewTemplate}
            className={`px-4 py-2 text-xs ${
              !newTemplateName.trim() || !newTemplateContent.trim()
                ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
                : 'text-primary-400 bg-white hover:bg-primary-400 hover:text-white'
            } border border-gray-200 rounded-s-lg`}
            disabled={!newTemplateName.trim() || !newTemplateContent.trim()}
          >
            Save
          </button>

          <button 
          onClick={deleteTemplate}
          className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
          >
          Delete
          </button>
          <button 
          onClick={togglePopup}
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
          >
          Close
          </button>
          </div>
        </div>
        </div>
      )}
    </main>
  );
};

export default ExamsAndConsultTemplates;
import React, { useRef, useEffect, useState } from 'react';
import { firestore, auth } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import EXIF from 'exif-js';
import { defaultTeethData } from '../treatmentplanner/defaultTeethData';
import { defaultTeethDataPalmer } from '../treatmentplanner/defaultTeethDataPalmer';
import { defaultTeethDataUniversal } from '../treatmentplanner/defaultTeethDataUniversal';

const MarkUpImage = ({ imageUrl, onSave }) => {
  const canvasRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [flipH, setFlipH] = useState(false);
  const [flipV, setFlipV] = useState(false);
  const [image, setImage] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [teethData, setTeethData] = useState(defaultTeethData); // Default to FDI
  const [currentToothIndex, setCurrentToothIndex] = useState(0);
  const [startingToothNumber, setStartingToothNumber] = useState('');
  const [label, setLabel] = useState('Starting Tooth Number');
  const [markedTeeth, setMarkedTeeth] = useState([]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserProfile(docSnap.data());
          switch (docSnap.data().chartingMethod) {
            case 'Palmer':
              setTeethData(defaultTeethDataPalmer);
              break;
            case 'Universal':
              setTeethData(defaultTeethDataUniversal);
              break;
            case 'FDI':
            default:
              setTeethData(defaultTeethData);
              break;
          }
        } else {
          console.log("No user profile found!");
        }
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (teethData.length > 0) {
      setStartingToothNumber(teethData[0].number);
      setCurrentToothIndex(0);
    }
  }, [teethData]);

  const drawCircle = (context, x, y, radius, color, opacity = 0.25) => {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI);
    context.fillStyle = `rgba(${color},${opacity})`; // Assuming color is passed in 'r,g,b' format
    context.fill();
  };

  const drawText = (context, text, x, y) => {
    context.fillStyle = 'white';
    context.font = '150px ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
    context.fillText(text, x, y);
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const combinedImageUrl = canvas.toDataURL('image/png');
    onSave(combinedImageUrl);
  };

  const loadImageAndFixOrientation = (img, canvas, context) => {
    EXIF.getData(img, function () {
      const orientation = EXIF.getTag(this, 'Orientation');
      let width = img.width;
      let height = img.height;

      if (orientation >= 5 && orientation <= 8) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      switch (orientation) {
        case 2:
          context.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          context.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          context.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          context.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          context.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          context.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          context.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          context.transform(1, 0, 0, 1, 0, 0);
      }

      context.drawImage(img, 0, 0, width, height);
      setImageLoaded(true);
    });
  };

  const updateCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (!image) return;

    canvas.width = image.width;
    canvas.height = image.height;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();

    // Apply rotation and flipping transformations
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((rotation * Math.PI) / 180);
    context.scale(flipH ? -1 : 1, flipV ? -1 : 1);
    context.drawImage(image, -image.width / 2, -image.height / 2);

    context.restore();

    // Redraw all marked teeth
    markedTeeth.forEach(({ x, y, toothNumber }) => {
      const radius = getToothRadius(toothNumber); // Get the radius based on tooth number
      drawCircle(context, x, y, radius, '36, 153, 255');
      drawText(context, toothNumber, x - 75, y + 75);
    });
  };

  const getToothRadius = (toothNumber) => {
    switch (userProfile?.chartingMethod) {
      case 'Palmer':
        return ['LL3', 'LL2', 'LL1', 'LR1', 'LR2', 'LR3'].includes(toothNumber) ? 225 : 300;
      case 'Universal':
        return ['23', '24', '25', '26', '27', '28'].includes(toothNumber) ? 225 : 300;
      case 'FDI':
      default:
        return ['33', '32', '31', '41', '42', '43'].includes(toothNumber) ? 225 : 300;
    }
  };

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Set crossOrigin to Anonymous

    img.onload = () => {
      setImage(img);
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      loadImageAndFixOrientation(img, canvas, context);
    };

    img.onerror = (error) => {
      console.error('Error loading image:', error);
    };

    img.src = imageUrl;
  }, [imageUrl]);

  useEffect(() => {
    updateCanvas();
  }, [rotation, flipH, flipV, image, markedTeeth]);

  useEffect(() => {
    const handleWheel = (event) => {
      event.preventDefault();
      setCurrentToothIndex((prevIndex) => {
        const newIndex = event.deltaY < 0 
          ? (prevIndex - 1 + teethData.length) % teethData.length
          : (prevIndex + 1) % teethData.length;
        setStartingToothNumber(teethData[newIndex].number);
        return newIndex;
      });
    };

    const canvas = canvasRef.current;
    canvas.addEventListener('wheel', handleWheel);

    return () => {
      canvas.removeEventListener('wheel', handleWheel);
    };
  }, [teethData]);

  const handleCanvasClick = (event) => {
    if (!imageLoaded) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    // Check if clicked inside an existing circle
    const clickedToothIndex = markedTeeth.findIndex(({ x: cx, y: cy }) => {
      const distance = Math.sqrt((x - cx) ** 2 + (y - cy) ** 2);
      return distance <= 150; // Central 150 area
    });

    if (clickedToothIndex !== -1) {
      // Remove the clicked circle
      setMarkedTeeth((prevMarkedTeeth) => {
        const newMarkedTeeth = [...prevMarkedTeeth];
        newMarkedTeeth.splice(clickedToothIndex, 1);
        return newMarkedTeeth;
      });

      if (clickedToothIndex === markedTeeth.length - 1) {
        // If it was the last added circle, update the tooth index
        setCurrentToothIndex((prevIndex) => (prevIndex - 1 + teethData.length) % teethData.length);
        setStartingToothNumber(teethData[(currentToothIndex - 1 + teethData.length) % teethData.length].number);
      }

      return;
    }

    const radius = getToothRadius(teethData[currentToothIndex].number);

    // Add a new circle
    drawCircle(context, x, y, radius, '36, 153, 255');
    drawText(context, teethData[currentToothIndex].number, x - 75, y + 75);

    setMarkedTeeth((prevMarkedTeeth) => [
      ...prevMarkedTeeth,
      { x, y, toothNumber: teethData[currentToothIndex].number }
    ]);

    setCurrentToothIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % teethData.length;
      setStartingToothNumber(teethData[newIndex].number);
      setLabel('Next Tooth Number');
      return newIndex;
    });
  };

  const handleStartingToothNumberChange = (e) => {
    const toothNumber = e.target.value;
    setStartingToothNumber(toothNumber);

    const startingIndex = teethData.findIndex((tooth) => tooth.number === toothNumber);
    if (startingIndex !== -1) {
      setCurrentToothIndex(startingIndex);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">{label}</label>
          <input
            type="text"
            value={startingToothNumber}
            onChange={(e) => {
              const index = teethData.findIndex(tooth => tooth.number === e.target.value);
              if (index !== -1) {
                setCurrentToothIndex(index);
                setStartingToothNumber(e.target.value);
              }
            }}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          />
        </div>
        <canvas
          ref={canvasRef}
          className="w-full h-auto border border-gray-300 cursor-crosshair"
          onClick={handleCanvasClick}
        ></canvas>
        <div className="mt-4 flex justify-between">
          <button onClick={() => setRotation((prev) => prev + 90)} className="px-4 py-2 bg-primary-400 text-white rounded hover:bg-primary-600">
            Rotate 90°
          </button>
          <button onClick={() => setFlipH((prev) => !prev)} className="px-4 py-2 bg-primary-400 text-white rounded hover:bg-primary-600">
            Flip Horizontal
          </button>
          <button onClick={() => setFlipV((prev) => !prev)} className="px-4 py-2 bg-primary-400 text-white rounded hover:bg-primary-600">
            Flip Vertical
          </button>
          <button onClick={handleSave} className="px-4 py-2 bg-primary-400 text-white rounded hover:bg-primary-600">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarkUpImage;
import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';

const defaultAnaesthetics = {
  Lidocaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Articaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Citanest: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Mepivacaine: { batchNo: '', expiry: '', volume: '', percentage: '' },
  Bupivacaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Prilocaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
};

const LocalAnaesthetic = () => {
  const [selectedAnaesthetic, setSelectedAnaesthetic] = useState('');
  const [anaesthetics, setAnaesthetics] = useState(defaultAnaesthetics);
  const [isAnaestheticsVisible, setIsAnaestheticsVisible] = useState(false);
  const [newAnaestheticName, setNewAnaestheticName] = useState('');

  const toggleAnaestheticsVisibility = () => {
    setIsAnaestheticsVisible(prev => !prev); 
  };

  useEffect(() => {
    const loadAnaesthetics = async () => {
      const uid = auth.currentUser.uid;
      const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
      const anaestheticSnap = await getDoc(anaestheticRef);
      if (anaestheticSnap.exists()) {
        setAnaesthetics(anaestheticSnap.data());
      }
    };
    loadAnaesthetics();
  }, []);
  

  const handleAnaestheticSelection = (type) => {
    setSelectedAnaesthetic(type);
  };

  const handleInputChange = (type, field, value) => {
    setAnaesthetics(prev => ({
      ...prev,
      [type]: { ...prev[type], [field]: value }
    }));
  };

  const handleSaveAnaesthetic = async () => {
    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await setDoc(anaestheticRef, anaesthetics);
      console.log('Anaesthetic saved successfully');
      alert('LA saved successfully');
    } catch (error) {
      console.error('Error saving anaesthetic:', error);
    }
  };

  const handleDeleteAnaesthetics = async () => {
    const confirmed = window.confirm("Are you sure you want to clear all batch numbers and expiries?");
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }
  
    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await deleteDoc(anaestheticRef);
      setAnaesthetics({});
      console.log('Anaesthetic record deleted successfully');
      alert('Batch numbers and expiries cleared');
    } catch (error) {
      console.error('Error deleting LA record:', error);
    }
  };

  const handleAddAnaesthetic = async () => {
    const trimmedAnaestheticName = newAnaestheticName.trim();
    if (trimmedAnaestheticName && !anaesthetics[trimmedAnaestheticName]) {
      const updatedAnaesthetics = {
        ...anaesthetics,
        [trimmedAnaestheticName]: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
      };
      setAnaesthetics(updatedAnaesthetics);
      setNewAnaestheticName('');

      const uid = auth.currentUser.uid;
      const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
      try {
        await setDoc(anaestheticRef, updatedAnaesthetics);
        console.log('Anaesthetic added successfully');
      } catch (error) {
        console.error('Error adding anaesthetic:', error);
      }
    } else {
      alert('Invalid or duplicate anaesthetic name');
    }
  };

  const handleDeleteSingleAnaesthetic = async () => {
    const confirmed = window.confirm(`Are you sure you want to delete the anesthetic "${selectedAnaesthetic}"? This action cannot be undone.`);
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }

    const updatedAnaesthetics = { ...anaesthetics };
    delete updatedAnaesthetics[selectedAnaesthetic];

    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await setDoc(anaestheticRef, updatedAnaesthetics);
      console.log('Anaesthetic deleted successfully');
    } catch (error) {
      console.error('Error deleting anaesthetic:', error);
    }

    const remainingAnaesthetics = Object.keys(updatedAnaesthetics);
    setSelectedAnaesthetic(remainingAnaesthetics.length > 0 ? remainingAnaesthetics[0] : '');
    setAnaesthetics(updatedAnaesthetics);
  };

  const handleResetToDefault = async () => {
    const confirmed = window.confirm("Are you sure you want to reset to default? This action cannot be undone.");
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }

    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await deleteDoc(anaestheticRef);
      setAnaesthetics(defaultAnaesthetics);
      setSelectedAnaesthetic('');
      console.log('Reset to default successfully');
      alert('Reset to default successfully');
    } catch (error) {
      console.error('Error resetting to default:', error);
    }
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-full mx-auto mt-8">
      {/* TOP BAR */}
      <div className="flex items-center justify-center mt-4 mb-8 border-b pb-8">
        <input
          type="text"
          placeholder="New Anaesthetic Name"
          value={newAnaestheticName}
          onChange={(e) => setNewAnaestheticName(e.target.value)}
          className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          className="ml-2 mb-2 px-4 py-2 mt-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
          onClick={handleAddAnaesthetic}>
          Add Anaesthetic
        </button>
      </div>
      <div className="flex items-center mx-auto justify-center my-4 gap-4">
        {Object.keys(anaesthetics).map(type => (
          <button
            key={type}
            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
            ${selectedAnaesthetic === type
                ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
            }`
          }
            onClick={() => handleAnaestheticSelection(type)}
          >
            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
              {type}
            </span>
          </button>
        ))}
      </div>

      {selectedAnaesthetic && (
        <>
          <div className="flex flex-col gap-4 mx-auto mb-4">
            <div className="flex gap-4">
              <div>
                <input
                  className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
                  type="text"
                  placeholder="Batch No."
                  value={anaesthetics[selectedAnaesthetic]?.batchNo || ''}
                  onChange={(e) => handleInputChange(selectedAnaesthetic, 'batchNo', e.target.value)}
                />
              </div>
              <div>
                <input
                  className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
                  type="month" // Changed to 'month' for MM/YYYY format
                  placeholder="Expiry Date"
                  value={anaesthetics[selectedAnaesthetic]?.expiry || ''}
                  onChange={(e) => handleInputChange(selectedAnaesthetic, 'expiry', e.target.value)}
                />
              </div>
              <div>
                <input
                  className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
                  type="text"
                  placeholder="Enter Volume"
                  value={anaesthetics[selectedAnaesthetic]?.volume || ''}
                  onChange={(e) => handleInputChange(selectedAnaesthetic, 'volume', e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-4 mx-auto">
              <div>
                <input
                  className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
                  type="number"
                  placeholder="Percentage"
                  value={anaesthetics[selectedAnaesthetic]?.percentage || ''}
                  onChange={(e) => handleInputChange(selectedAnaesthetic, 'percentage', e.target.value)}
                />
              </div>
              {selectedAnaesthetic !== 'Mepivacaine' && (
                <div>
                  <input
                    className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
                    type="text"
                    placeholder="Concentration"
                    value={anaesthetics[selectedAnaesthetic]?.concentration || ''}
                    onChange={(e) => handleInputChange(selectedAnaesthetic, 'concentration', e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="flex">
        <button
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
          onClick={handleSaveAnaesthetic}>
          Save
        </button>
        <button
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 hover:bg-primary-400 hover:text-white"            
          onClick={handleDeleteAnaesthetics}>
          Clear All Batches & Expiries
        </button>
        <button
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"            
          onClick={handleResetToDefault}>
          Reset to Default
        </button>
        {selectedAnaesthetic && (
          <button
            className="ml-2 px-4 py-2 text-xs text-red-400 bg-white border border-gray-200 rounded-lg hover:bg-red-400 hover:text-white"            
            onClick={handleDeleteSingleAnaesthetic}>
            Delete
          </button>
        )}
      </div>
    </main>
  );
};

export default LocalAnaesthetic;

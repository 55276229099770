import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faTimes, faMicrophone, faPaperPlane, faStop, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { collection, doc, getDoc, setDoc, updateDoc, where, getDocs, query, getFirestore } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import LoadingPopup2 from  '../popup/LoadingPopup2';
import './cariespondence.css';
import CopyButton from '../CopyButton';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import RiskBankChecker from '../riskbank/RiskBankChecker';
import PastExamModal from '../basicexam/PastExamModal';
import MolarMailerLetters from '../molarmailer/MolarMailerLetters';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';

Quill.register('modules/imageUploader', ImageUploader);

const Cariespondence = () => {
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [isInfoVisible, setIsInfoVisible] = useState(true); 
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [showLoadingPopup2, setShowLoadingPopup2] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [templates, setTemplates] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const searchInputRef = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handleSelectTemplate = async (templateId) => {
        try {
            // Fetch the template data from Firestore
            const templateRef = doc(firestore, 'customers', auth.currentUser.uid, 'CariespondenceTemplates', templateId);
            const templateDoc = await getDoc(templateRef);
    
            if (templateDoc.exists()) {
                // Update the states with the template data
                const templateData = templateDoc.data();
                // console.log("Template text:", templateData.text); // Logging the template text for debugging
            } else {
                // console.log("No such template!");
            }
            
            setSearchInput(templateId); // Update search input
            setSelectedTemplate(templates[templateId]); // Update the selected template
            setFilteredTemplates([]); // Clear the filtered list
            setFocusedItemIndex(-1); // Reset the focused item index
    
        } catch (error) {
            console.error("Error fetching template:", error);
        }
    };
    
    
    const handleSearchInput = (value) => {
        // console.log("Search input changed:", value);
        setSearchInput(value);
        const lowerCaseInput = value.toLowerCase();
    
        if (templates[lowerCaseInput]) {
            // console.log("Template found for:", lowerCaseInput);
            setSelectedTemplate(templates[lowerCaseInput]);
            setFilteredTemplates([]);
            return;
        }
    
        const filtered = Object.keys(templates).filter(key =>
            key.toLowerCase().includes(lowerCaseInput)
        );
        setFilteredTemplates(filtered);
    };
    
    
    
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.min(prev + 1, filteredTemplates.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.max(prev - 1, 0));
        } else if (e.key === "Enter" && focusedItemIndex >= 0) {
            e.preventDefault();
            handleSelectTemplate(filteredTemplates[focusedItemIndex]);
        }
    };
    
    

    useEffect(() => {
        // Function to fetch user profile
        const fetchUserProfile = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUserProfile(userDoc.data());
                }
            }
        };
        fetchUserProfile(); 
    }, []);

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };
    

    const globalNoteRef = useRef(null);

    const handleAssistantResponseChange = (content) => {
        const processedContent = removeDoubleAsterisks(content);
        setAssistantResponse(processedContent);
    };
    

// useEffect(() => {
//     if (audioData) {
//         // console.log('Audio Blob is set, now sending to cloud function');
//         sendAudioToCloudFunction(audioData);
//     }
// }, [audioData]);

const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream);
        const newMediaRecorder = new MediaRecorder(stream);
        // console.log('MediaRecorder started');
        setCanStopRecording(true);

        let audioChunks = [];
        newMediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };

        newMediaRecorder.onstop = async () => {
            // // console.log('Recording stopped, creating audio blob');
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            // // console.log('Audio blob created:', audioBlob.size);

            if (audioBlob.size === 0) {
                alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                setIsRecording(false);
                return; // Early return to prevent further execution
            }

            if (audioBlob.size > 0) {
                const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                const filePath = `cariespondence/${userUID}/${Date.now()}.webm`;
                const storageRef = ref(getStorage(), filePath);
                await uploadBytes(storageRef, audioBlob);
                
                getDownloadURL(storageRef).then(async (downloadURL) => {
                    // // console.log('Download URL:', downloadURL);
                    setDownloadUrl(downloadURL);

                    let finalFilePath = filePath;
                    if (audioBlob.size > 23000000) { // 23MB
                        // console.log("File size exceeds 23MB, compressing...");
                        setShowLoadingPopup2(true);
                        finalFilePath = await compressAudio(filePath);
                    }

                    sendAudioToCloudFunction(finalFilePath, downloadURL);
                });
            }
        };

        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start();
        setIsRecording(true);
    } catch (error) {
        console.error('Error in starting recording:', error);
    }
};


const stopRecording = () => {
    if (mediaRecorder) {
        // console.log('Stopping recording');
        mediaRecorder.stop();
        setIsRecording(false);
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        setTranscribedNote("Loading...");
    }
};




const sendAudioToCloudFunction = async (filePath, downloadURL) => {
    try {
        // Get the current authenticated user
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        // Get the Firebase ID token of the current user
        const idToken = await user.getIdToken();

        // Prepare the payload
        const payload = {
            filePath: filePath,
            language: languagePreference // Send the user's language preference
        };

        // Make the POST request with Authorization header
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            // Handle HTTP errors
            const errorText = await response.text();
            throw new Error(`Server Error: ${errorText}`);
        }

        const result = await response.json();
        // // console.log('Received response:', result);
        setShowLoadingPopup2(false);
        let appendedTranscript = result.text;

        // RiskBank - check for triggers and append risks if found
        const triggersInTranscript = await RiskBankChecker(result.text);
        // // console.log("Triggers found in transcript:", triggersInTranscript);

        if (triggersInTranscript.length > 0) {
            for (const trigger of triggersInTranscript) {
                const risksQuery = query(collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'), where("trigger", "==", trigger));
                const querySnapshot = await getDocs(risksQuery);
                querySnapshot.forEach(doc => {
                    const riskData = doc.data();
                    appendedTranscript += `\n\nRisk for ${riskData.name}: ${doc.data().risk}`;
                });
            }
        }

        setTranscribedNote(appendedTranscript);

        // Store the note
        const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
        await setDoc(noteRef, {
            title: "Cariespondence",
            content: "", 
            transcript: appendedTranscript,
            audio_recording: downloadURL,
            patientId: patientId,
            timestamp: new Date()
        });
        globalNoteRef.current = noteRef;
    } catch (error) {
        console.error('Error:', error);
        setShowLoadingPopup2(false);
    }
};
 

const compressAudio = async (filePath) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Get the Firebase ID token of the current user
    const idToken = await user.getIdToken();

    // Prepare the payload
    const payload = {
        filePath: filePath,
        uid: user.uid // Ensure uid matches the authenticated user
    };

    // Make the POST request with Authorization header
    const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/compressAudio', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
        body: JSON.stringify(payload),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok during compression');
    }
  
    const data = await response.json();
    // // // console.log('Compression response:', data);
    return data.outputPath; // Return the path of the compressed file
};
    

const fetchAssistantResponse = async () => {
    setIsFetching(true);
    setShowLoadingPopup(true);

    let prompt;
    // Check if a template is selected
    if (selectedTemplate) {
        // Compose a prompt with both the template and the transcribed note
        prompt = `The template = ${selectedTemplate}, dentist's input = ${transcribedNote}`;
    } else {
        // Use only the transcribed note if no template is selected
        prompt = transcribedNote;
    }

    try {
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/cariespondence', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt: prompt }),
        });
        
        const result = await response.json();
        setAssistantResponse(result.text);
        
        // console.log("global note ref:", globalNoteRef);
        
        if (globalNoteRef.current) {
            // Update the existing Firestore document
            await updateDoc(globalNoteRef.current, {
                content: result.text,
            });
        } else {
            // Create a new Firestore document
            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            await setDoc(noteRef, {
                title: "Cariespondence",
                content: result.text,
                patientId: patientId,
                timestamp: new Date()
            });
            globalNoteRef.current = noteRef;
        }
        
        // console.log("Document created/updated successfully");
    } catch (error) {
        console.error("Error updating document: ", error);
        setAssistantResponse('Error fetching response.');
        setShowLoadingPopup(false);
        setIsFetching(false);
        alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        console.error('Error fetching response:', error);
    } finally {
        setIsFetching(false);
        setShowLoadingPopup(false);
    }
};


    const handleButtonClick = () => {
        if (transcribedNote.trim() !== '') {
            fetchAssistantResponse();
        }
    };

    
    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(assistantResponse).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000); // Reset message after 2 seconds
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleInputChange = (e) => {
        setTranscribedNote(e.target.value);
        
    };

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/```html/g, '');
        formattedText = formattedText.replace(/```/g, '');
        formattedText = formattedText.replace(/\*\*/g, ''); 
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    
    const containsReferral = /referr(al|er)/i.test(assistantResponse);

    const handleCheckAudioQuality = () => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
    };

    const formatHTMLText = (htmlText) => {
        let formattedText = htmlText
            // Insert newline before certain tags
            .replace(/(<(p|h1|h2|h3|li|div)[^>]*>)/g, "\n$1")
            // Remove opening and closing <ul> and <ol> tags
            .replace(/<\/?(ul|ol)>/g, "");
    
        return formattedText;
    };
    

    const removeLeadingWhitespace = (htmlString) => {
        return htmlString
        // Remove leading white space before HTML tags on each line
        .replace(/^\s+</gm, '<')
        // Remove specific HTML tags
        .replace(/<\/?(ol|ul|strong|i|u|b)>/gm, '');
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
        const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    }

    const todayDate = formatDate(new Date());
    
    
    const handleCreateDocx = async () => {
        if (!userProfile) {
          alert('Please wait while your profile is loaded.');
          return;
        }
    
        setShowLoadingPopup(true);
        setIsProcessing(true);
    
        try {
          // Step 1: Prepare content for the document
          let introContent = removeLeadingWhitespace(`
            Dr. ${userProfile?.name}\n${userProfile?.practiceName}\n${userProfile?.practiceAddress}\n${userProfile?.practicePostcode}\n${userProfile?.practiceEmail}\n${userProfile?.practicePhoneNumber}\n
            <br>\n
            ${todayDate}\n
            <br>\n`);
    
          let processedText = introContent + "<br>\n" + formatHTMLText(removeDoubleAsterisks(assistantResponse)) + "\n<br>\n" + `<p>Dr. ${userProfile?.name}</p>\n`;
    
          // Step 2: Get the user's Bearer token for authorization
          const user = auth.currentUser;
          if (!user) {
            throw new Error('User not authenticated');
          }
          const idToken = await user.getIdToken();
    
          // Step 3: Send the request to the cloud function (with the Bearer token)
          const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreatorMM', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`, // Attach Bearer token for authentication
            },
            body: JSON.stringify({
              textContent: processedText,
              letterheadURL: userProfile?.letterheadURL || '', // Include letterhead URL if available
            }),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok in DocxCreator');
          }
    
          // Step 4: Process the response to generate the DOCX
          const docxData = await response.json();
          const docxContent = atob(docxData.base64);
          const arrayBuffer = new Uint8Array(docxContent.length);
          for (let i = 0; i < docxContent.length; i++) {
            arrayBuffer[i] = docxContent.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    
          // Step 5: Create a download link for the document
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `Letter - Dr. ${userProfile.name}.docx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
    
          // Step 6: Upload the document to Firebase Storage
          const uid = auth.currentUser.uid;
          const storage = getStorage();
          const storageRef = ref(storage, `letters/${uid}/patient-letter.docx`);
    
          const snapshot = await uploadBytes(storageRef, blob);
          const downloadURL = await getDownloadURL(snapshot.ref);
    
          // Step 7: Save the download URL in Firestore
          const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
          await setDoc(noteRef, {
            title: 'Patient Letter',
            content: downloadURL,
            timestamp: new Date(),
          });
    
          setShowLoadingPopup(false);
          setIsProcessing(false);
    
        } catch (error) {
          console.error('Error:', error);
          setShowLoadingPopup(false);
          setIsProcessing(false);
          alert('Sorry, there was an error with the AI. Please try again.');
        }
      };
    
      
    useEffect(() => {
        const uid = auth.currentUser.uid;
        const templatesRef = collection(firestore, 'customers', uid, 'CariespondenceTemplates');
    
        const fetchTemplates = async () => {
            const querySnapshot = await getDocs(templatesRef);
            const fetchedTemplates = {};
            querySnapshot.forEach(doc => {
                fetchedTemplates[doc.id] = doc.data().text;
            });
            setTemplates(fetchedTemplates);
        };
    
        fetchTemplates();
    }, []);
      
    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };    
    

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
           
            {showLoadingPopup && <LoadingPopup />}
            {showLoadingPopup2 && <LoadingPopup2 />}
                
               
            {/* TOP BAR */}
            <div className="flex items-center w-[65%] mx-auto justify-center">
                <div>
                    <input
                        type="text"
                        id="patientId"
                        value={patientId}
                        onChange={handlePatientIdChange}
                        placeholder="Enter Patient ID"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

            {/* Template Search */}
                <div>
                    <input
                        ref={searchInputRef}
                        onKeyDown={handleKeyDown}
                        type="text"
                        id="templatesearch"
                        placeholder="Search for a template"
                        value={searchInput}
                        onChange={(e) => handleSearchInput(e.target.value)}
                        className="realtive block ml-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />

                    {filteredTemplates.length > 0 && (
                    <div className="absolute ml-4 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                        <ul className="py-2 text-sm text-gray-700 ">
                            {filteredTemplates.map((templateId, index) => (
                                <li key={templateId}
                                    onClick={() => handleSelectTemplate(templateId)}
                                    className={focusedItemIndex === index ? "block px-4 py-2 bg-gray-50" : "block px-4 py-2 hover:cursor-pointer hover:bg-gray-50"}
                                    onMouseEnter={() => setFocusedItemIndex(index)}
                                    onMouseLeave={() => setFocusedItemIndex(-1)}
                                >
                                    {templateId}
                                </li>
                            ))}
                        </ul>
                    </div>
                    )}
                </div>
            </div>
            
        
            
            {/* MAIN SECTION */}
            <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
            {/* LEFT INPUT COLUMN */}
            <div className="flex flex-col">
                <textarea
                    placeholder="Speak your letter, to whomever you so wish..."
                    value={transcribedNote}
                    onChange={(e) => setTranscribedNote(e.target.value)}
                    readOnly={isRecording || transcribedNote === "Loading..."}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="20"
                ></textarea>
                {mediaStream && (
                    <div className="active-audio-analyser-container">
                        <ActiveAudioAnalyser audioStream={mediaStream} isPaused={isPaused} />
                    </div>
                )}


                 <div className="mx-auto mt-2">
                    <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button 
                                onClick={startRecording} 
                                disabled={isRecording || isPaused}
                                className={`${isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faMicrophone} />
                            </button>

                            {isRecording && (
                                <button 
                                    onClick={isPaused ? resumeRecording : pauseRecording}
                                    className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                </button>
                            )}

                            <button 
                                onClick={stopRecording} 
                                disabled={!isRecording || isPaused}
                                className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </button>
                
                            <button 
                                onClick={fetchAssistantResponse} 
                                disabled={isFetching || isRecording || isPaused}
                                className={`${isFetching || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                    </div>
                </div>
            </div>

            {/* RIGHT OUTPUT COLUMN */}
            <div className="flex flex-col">
                <label className="block mb-2 text-xs text-primary-400">AI Output</label>
                    <ReactQuill
                        theme="snow"
                        value={assistantResponse}
                        onChange={handleAssistantResponseChange}
                        modules={Cariespondence.modules}
                        formats={Cariespondence.formats}
                        placeholder="Letter content will show here..."
                        style={{ marginBottom: '35px', width: '100%' }}
                    />

                    <div className="mt-4 flex items-center justify-center mx-auto mt-2">
                        <button 
                        onClick={handleCreateDocx} 
                        disabled={!assistantResponse}
                        className="cursor-pointer px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
                        >
                            Create Document
                        </button>
                        <MolarMailerLetters 
                        className="cursor-pointer px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
                        noteContent={assistantResponse} 
                        patientId={patientId} 
                        disabled={!assistantResponse} 
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  
  
  Cariespondence.modules = {
    toolbar: {
      container: [
        [{'header': [2, 3, false]}],
        [{'list': 'bullet'},],
        ['image'],
      ],
    },
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const storage = getStorage();
          const storageRef = ref(storage, 'images/' + file.name); // Adjust path as needed
          uploadBytes(storageRef, file).then((snapshot) => {
            // console.log('Uploaded a blob or file!');
            getDownloadURL(snapshot.ref).then((downloadURL) => {
              // console.log('File available at', downloadURL);
              resolve(downloadURL);
            });
          }).catch((error) => {
            console.error("Error uploading file to Firebase Storage:", error);
            reject("Upload failed");
          });
        });
      }
    }
     
  };
  
  Cariespondence.formats = [
    'header', 'list', 'image'
  ];

export default Cariespondence;

import React, { useState } from 'react';
import { collectionGroup, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';

const NpsOverview = () => {
    const [totalSubmissions, setTotalSubmissions] = useState(0);
    const [npsScore, setNpsScore] = useState(0);
    const [detractors, setDetractors] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAllSubmissions = async () => {
        setLoading(true);
        try {
            const submissionsRef = collectionGroup(firestore, 'submissions');
            const submissionsSnapshot = await getDocs(submissionsRef);

            // Extract data from submissions
            const submissionsData = submissionsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setTotalSubmissions(submissionsData.length);

            // Initialize counters
            let promoters = 0;
            let passives = 0;
            let detractorsCount = 0;
            let flaggedUsers = [];

            // Calculate NPS and identify detractors
            submissionsData.forEach(submission => {
                const score = submission.score;
                if (score >= 9) {
                    promoters += 1;
                } else if (score >= 7) {
                    passives += 1;
                } else {
                    detractorsCount += 1;
                }

                // Flag users with score <= 7
                if (score <= 7) {
                    flaggedUsers.push(submission);
                }
            });

            // Calculate NPS Score
            const nps = ((promoters - detractorsCount) / submissionsData.length) * 100;
            setNpsScore(nps.toFixed(2)); // Round to two decimal places
            setDetractors(flaggedUsers);
        } catch (error) {
            console.error('Error fetching submissions:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h3>NPS Submissions Overview</h3>
            <button onClick={fetchAllSubmissions} disabled={loading}>
                {loading ? 'Loading...' : 'Fetch All Submissions'}
            </button>
            <p>Total Submissions: {totalSubmissions}</p>
            <p>Net Promoter Score: {npsScore}</p>

            {detractors.length > 0 && (
                <div>
                    <h4>Flagged Users (Score 7 or Less):</h4>
                    <ul>
                        {detractors.map(submission => (
                            <li key={submission.id}>
                                <strong>UID:</strong> {submission.uid}, <strong>Score:</strong> {submission.score}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default NpsOverview;

const stockTxNoteTemplates = {
    'RCT-Complete': `Root canal treatment tooth:
    C/O – nil
    MH – no changes
    LA given: 
    restoration removed
    caries cleared
    dam applied
    Pulp chamber entered, floor explored, no sign of fractures
    canal(s) accessed 
    scouted with #15 k-file to EWL
    Coronal flaring done by 2/3rd WL on waveone gold #25
    CWL: with apex locator/ WL IOPA. 
    wave one system used to prepare canals to WL: Prepared to full WL by using hand kflexo files & wave1 rotary system, working length =
    recapitulate with k file
    irrigation throughout with 1% sodium hypochlorite
    canals dried with paper points; 
    obturated with GP points (single master cone/CLC) coated with tubliseal  and heat plugged
    restoration:
    core build up:
    post op radiograph taken Tx objectives discussed with patient: Post op PA taken. RR - Grade1, in all canals, GP well condensed apically & laterally to within 2mm of radiographic apex, no unfilled canal space visible beyond GP. 
    POIG. Warned pt of possibility of post op pain/swelling – analgesic advice given (rec use of ibuprofen)
    `,

    'Composite' : `Composite Restoration Teeth:

    C/O – nil
    MH – no changes
    
    Explained process for todays appt- VCG. Advised re hand signals if wishes to stop/rest
    
    LA:
    Composite shade selected: 
    Caries removed. margins bevelled. cavity isolated with rubber dam, etch applied, gluma desensitiser, bonding agent applied to dried enamel/dentine, light cured & composite inserted incrementally, light-curing between each application. Occlusion checked & restoration finished using finishing burrs & soflex discs.
    `, 

    'Amalgam' : `Amalgam Filling Tooth:
    MH: checked no changes.
    Pt c/o nil. Discussed treatment proposed today, filling and discussed material to be used. VCG. Advised re hand signals if wishes to stop/rest.
    LA:
    Access to caries using high speed and small fissure bur, caries removed using slow speed and rose head bur. margins bevelled. cavity isolated, resin modified glass ionomer lining placed, matrix band placed,, amalgam compacted into cavity, carved and morphology restored. occlusion checked & adjusted as necessary. 
    Explained to pt that amalgam fillings all take a full 24hr to set fully, so to try and avoid anything too hard or sticky for the next 24hrs.
    Painkillers can be taken as you would for a headache if area is tender.
    Pt happy.
    `, 

    'Fissure Sealant' : `Fissure sealant teeth:
    Complaints - nil. PMH - no changes.
    Isiolated with cotton wool rolls.
    etch tooth 20s. rinsed with water 3in1. dried. FS applied, light cured 20s. margins checked with probe - no issues. adv bite will feel high, will resolved in a few days, pt happy, advised to contact with any issues.`,
    
    'SSC': `Complaints - nil. PMH - no changes. Sized stainless steel crown, size:
    Cemented with GI, excess removed. pt coped well with procedure. adv to contact if any problems.`,

    'XLA-Basic': `Complaints - nil. MH – no changes
Consent pre-operatively obtained from patient for extraction of offending tooth. Risks and Benefits discussed pre-operatively.
Advantages:
Relief from Pain: Extraction can alleviate severe tooth pain caused by damage, decay, infection, or crowding.
Preventing Spread of Infection: Removing an infected tooth can prevent the spread of infection to nearby teeth or the jawbone.
Disadvantages:
Aesthetics: Extraction can affect facial aesthetics, especially when a visible tooth is removed.
Impact on Chewing: Losing a tooth can affect chewing and speech in some cases.
Adjacent Teeth Shifting: Nearby teeth might shift into the space left by the extracted tooth, leading to alignment issues.
Bone Loss: Extraction can sometimes lead to bone loss in the jaw over time.
Possible Nerve Damage: There's a risk of nerve damage during extraction, leading to numbness or altered sensation in the surrounding area.
Risks:
Infection: Infection at the extraction site is a possible risk.
Dry Socket: Sometimes, the blood clot at the extraction site can dislodge, leading to a painful condition called dry socket.
Bleeding: Excessive bleeding can occur after extraction, especially for patients with bleeding disorders or those on blood-thinning medication.
Damage to Surrounding Structures: There's a risk of damage to nearby teeth, nerves, or sinus cavities during extraction.
Alternatives:
Root Canal Treatment: This procedure can save a damaged or infected tooth by removing the infected pulp and sealing the tooth.
Options for replacement discussed. LA administered. Tooth elevated and extracted routinely.
Socket inspected. Haemostasis achieved. Verbal & Written post-operative instructions supplied
`, 

    'XLA-Soft Tissue': `Complaints - nil. MH – no changes. Consent pre-operatively obtained from patient for extraction of offending tooth. Risks and Benefits discussed pre-operatively. Advantages:
    Relief from Pain: Extraction can alleviate severe tooth pain caused by damage, decay, infection, or crowding.
    Preventing Spread of Infection: Removing an infected tooth can prevent the spread of infection to nearby teeth or the jawbone.
    Disadvantages:
    Aesthetics: Extraction can affect facial aesthetics, especially when a visible tooth is removed.
    Impact on Chewing: Losing a tooth can affect chewing and speech in some cases.
    Adjacent Teeth Shifting: Nearby teeth might shift into the space left by the extracted tooth, leading to alignment issues.
    Bone Loss: Extraction can sometimes lead to bone loss in the jaw over time.
    Possible Nerve Damage: There's a risk of nerve damage during extraction, leading to numbness or altered sensation in the surrounding area.
    Risks:
    Infection: Infection at the extraction site is a possible risk.
    Dry Socket: Sometimes, the blood clot at the extraction site can dislodge, leading to a painful condition called dry socket.
    Bleeding: Excessive bleeding can occur after extraction, especially for patients with bleeding disorders or those on blood-thinning medication.
    Damage to Surrounding Structures: There's a risk of damage to nearby teeth, nerves, or sinus cavities during extraction.
    Alternatives:
    Root Canal Treatment: This procedure can save a damaged or infected tooth by removing the infected pulp and sealing the tooth.
     Options for replacement discussed. LA administered. envelope flap raised to fully visualise tooth, luxated and delivered. Socket inspected. 1x vicryl rapide suture placed, pt advised will dissolve. Haemastasis achieved. Verbal & Written post-operative instructions supplied`, 
    
    'XLA-Bone Removal': `Complaints - nil. MH – no changes. Consent pre-operatively obtained from patient for extraction of offending tooth. Risks and Benefits discussed pre-operatively. Advantages:
    Relief from Pain: Extraction can alleviate severe tooth pain caused by damage, decay, infection, or crowding.
    Preventing Spread of Infection: Removing an infected tooth can prevent the spread of infection to nearby teeth or the jawbone.
    Disadvantages:
    Aesthetics: Extraction can affect facial aesthetics, especially when a visible tooth is removed.
    Impact on Chewing: Losing a tooth can affect chewing and speech in some cases.
    Adjacent Teeth Shifting: Nearby teeth might shift into the space left by the extracted tooth, leading to alignment issues.
    Bone Loss: Extraction can sometimes lead to bone loss in the jaw over time.
    Possible Nerve Damage: There's a risk of nerve damage during extraction, leading to numbness or altered sensation in the surrounding area.
    Risks:
    Infection: Infection at the extraction site is a possible risk.
    Dry Socket: Sometimes, the blood clot at the extraction site can dislodge, leading to a painful condition called dry socket.
    Bleeding: Excessive bleeding can occur after extraction, especially for patients with bleeding disorders or those on blood-thinning medication.
    Damage to Surrounding Structures: There's a risk of damage to nearby teeth, nerves, or sinus cavities during extraction.
    Alternatives:
    Root Canal Treatment: This procedure can save a damaged or infected tooth by removing the infected pulp and sealing the tooth.
     Options for replacement discussed. LA administered. 
     Full mucoperiosteal flap raised, 3mm buccal bone removed, tooth sectioned, luxated and delivered. 3 Vicryl sutures placed, Socket inspected.
     Haemastasis achieved. Verbal & Written post-operative instructions supplied`, 
    
     'RCT-1st Stage': `RCT
    Tooth:
     LA given: 
     restoration removed
     caries cleared
     canal(s) accessed 
     dam applied
     Estimated working length:
     scouted with #15 k-file to WL
     Confirmed working length:
     wave one system used to prepare canals to WL:
     recapitulate with k file
     irrigation throughout with sodium hypochlorite
     canals dried with paper points;
     dressed with non-setting calcium hydroxide, dw & GI.
     nv obturate
     post op instructions given
     pt happy 
     `,

    'RCT-2nd Stage': `RCT

LA given: 
restoration removed
dam applied
recapitulate with k file
irrigation throughout with sodium hypochlorite
canals dried with paper points; 
obturated with GP points coated with tubliseal :
restoration:
core build up:
post op radiograph taken
pt happy
poist op instructions given
`,

    'Bleaching-UL Impressions': `complaints - nil. medical - no changes. verbal consent obtained. full intra & extraoral examination conducted. hard & soft tissue inspected, including check for any signs of benign or malignant lesions. . Dietary and fluoride advice given. periodontal health checked. pt completed recent course of treatment by GDP
    c/o tooth colour wants whiter discussed in surg and home whitening options.
    upper and lower alginate impressions made for bleaching trays, sent to lab. photo taken, current shade:
    next visit - fit bleaching trays.`,

    'Bleaching-Full Arch 3D Scans': `complaints - nil. medical - no changes. verbal consent obtained. upper and lower full arch 3D scans made for bleaching trays, sent to lab. photo taken, current shade:
    next visit - fit bleaching trays.`,

    'Bleaching-Fit': `complaints - nil. medical - no changes. verbal consent obtained. Trays returned from lab, checked in situ and fit assessed. Good fit noted. Instructions given to patient to apply small amount of gel into each tooth chamber and insert trays with light finger pressure to spread gel across teeth. Remove excess if required. Patient to wear trays overnight for 2 weeks - W10% Carbamide Peroxide. Use of desensitiser swabs advised if required. Consent form signed and pt to contact if any concerns/issues. current shade:`,
    
    'Bleaching-Review': `complaints nil. medical - no changes. reviewed bleaching, pt happy with result. next visit exam 6 months.`,

    'Bleaching-Internal': `complaints - nil. medical - no changes. verbal consent obtained. Procedure: Internal Dental Bleaching
    Tooth/Treatment Area: [Specify Tooth or Area]
    
    Procedure Details:
    Patient Complaint: Patient presented with discoloration or intrinsic staining in the [Specify Tooth or Area].
    Procedure Explanation: Discussed internal dental bleaching as a cosmetic procedure to address intrinsic staining.
    Consent: Informed the patient about the procedure, its risks, benefits, and alternative treatments. Obtained signed consent.
    Pre-Procedure Check: Verified patient's medical history and allergies.
    Procedure Steps:
    Preparation: Isolated the treatment area with rubber dam to prevent contact with surrounding tissues.
    Access Opening: Created an access cavity in the affected tooth's pulp chamber under local anesthesia.
    Cleaning: Thoroughly cleaned the pulp chamber to remove debris and decay remnants.
    Bleaching Agent Placement: Placed a bleaching agent (e.g., sodium perborate or hydrogen peroxide gel) inside the pulp chamber.
    Temporary Seal: Sealed the access cavity with a temporary filling material to prevent saliva contamination.
    Waiting Period: Advised patient on home care and instructed them to return after [Specify Timeframe] for evaluation.
    Evaluation: Assessed the effectiveness of the bleaching. If necessary, repeated the procedure for desired results.
    Post-Procedure Care:
    Post-Procedure Instructions: Advised the patient on post-procedure care, including oral hygiene practices and dietary restrictions (e.g., avoiding staining substances).
    Follow-Up: Scheduled a follow-up appointment for 2 weeks time to monitor the tooth's response and evaluate color changes.`,

    'Composite Bonding': `During the consultation, the patient's dissatisfaction with the aesthetics or appearance of their teeth, specifically [Specify Teeth], was addressed. Composite bonding was proposed and discussed as a viable cosmetic dental procedure to improve the overall appearance of the affected teeth. Emphasized points:

    Purpose: Explained that composite bonding is utilized to enhance the aesthetic appeal of teeth affected by discoloration, chips, fractures, or minor irregularities.
    Procedure Details: Provided a comprehensive overview of the process, outlining the steps involved in applying composite resin to the teeth to improve their shape, color, or alignment.
    Visual Aid: Utilized visual aids, such as images or models, to illustrate the expected outcome and the process involved in the procedure.
    Duration and Recovery: Detailed the approximate duration of the procedure and the expected recovery period, emphasizing minimal discomfort and the non-invasive nature of the treatment.
    Consent Process:
    Informed Consent: Thoroughly informed the patient about the entire procedure, including its potential risks, benefits, and anticipated outcomes.
    Risks and Benefits: Discussed potential risks such as slight discomfort during the procedure, post-operative sensitivity, or the need for future adjustments. Balanced this with the benefits of improved tooth appearance and boosted self-confidence.
    Alternatives: Presented alternative treatment options, if applicable, such as veneers or orthodontic procedures, highlighting their differences and potential suitability.
    Financial Discussion: Transparently discussed the associated costs, insurance coverage (if applicable), and any follow-up appointments or maintenance required.
    Consent Obtained:
    Documentation: Provided detailed information verbally and supplemented it with written materials, ensuring clarity and understanding.
    Patient Queries: Encouraged the patient to ask questions and addressed all concerns raised.
    Patient Acknowledgment: Ensured the patient comprehended the information provided and willingly consented to proceed with the proposed composite bonding procedure.
    wax up received from lab, checked, discussed with pt
    putty stent from wax up
    Isolation under RD/optragate
    Composite shades  A1CL selected. margins bevelled. cavity isolated, bonding agent applied to dried enamel/dentine, light cured & composite inserted incrementally using palatal shell from putty stent, light-curing between each application. occlusion checked & restoration finished using finishing burrs & soflex discs.
    `,

    'Invisalign-Consult': `ull intra & extraoral examination conducted. hard & soft tissue inspected, including check for any signs of benign or malignant lesions.  Dietary and fluoride advice given. periodontal health and BPE checked. Patient oral health is stable and is suitable for ortho.
    c/o position of ?? pt wishes to proceed with short term ortho with poss increase in overjet discussed. Discussed compromise with inability to treat ?? with STO
    full consent explained and copy emailed to patient to read and have questioned answered prior to fit. Also discussed fixed comprehensive ortho and clear aligners with option for specialist referral
    putty/ wash, full photographs and space assessment
    Comprehensive vs STO explained 
    Posterior occlusion and centre line accepted
    Time scale planned with estimate between 6-9 months but may be longer for final detailing movements 
    Consent for retention indefinitely both fixed and removable and pt made aware of long term monitoring of retention and costs
    IPR discussed as method of creating space with reduction in enamel but minimal for no long-term damage to teeth
    Review required normally every 4-8 weeks and pt required to attend as advised
    Advised re mild/moderate discomfort at time of appliance fitting or if adding elastic chain and management through NSAIDs, wax and occasionally topical anaesthetic gel from pharmacy. Also advised softer diet with appliance especially at initial fitting
    Pt aware of occasional complication of bracket debond, need to be careful with eating and restricting tougher foods as well as need to attend to have bracket replaced
    Patients stated aim is ???
    Digital planning offered and declined
    Discussed wear and poss anterior bonding post op
    Poss speech difficulties
    Referral offered for comprehensive ortho but pt happy to proceed as planned without further examination elsewhere
    Full ESAO assessment form completed on paper notes
    DH:
    SH:
    EO:tmj, nodes, lips, asymm NAD
    IO:Mucosa, FOM, H+S palate, tongue NAD
    OH & Perio:-
    Things of note;
    Endodontic treatment and status
    Occlusion – stable
    NCTSL - Chronological
     Diagnosis :
    Treatment plan:
     
     
    MH checked consent for radiographs gained 
    Exposure 68kV 8mA 11.4s
    View Taken:  Panoral
    Justification: Assessment of pathology and bone levels
    Grade: 1
    Restored teeth: nil to note
    Caries: nil to note
    RCT: nil to note
    PA pathology: nil to note
    Bone levels: bone levels normal
    root position favourable and no evidence of resorption
    
    today: 
    d/w pt and parent what the crown looks like and how we will place crown on tooth.
    tooth * - attempted size * on tooth, fitted well.
    crown fitted on tooth.
    asked pt to bite down.
    pt happy.
    advised pt and parent that crown will feel high but will become adjusted during the rest of the week.
    advised to continue biting down and to keep crown clean, treat like a similar tooth.
    pt happy.
    `,

    'Invisalign-Scan': `complaints - nil. medical - no changes. verbal consent obtained. upper and lower full arch 3D scans made for short term orthodontic aligners, sent to lab. full photo series taken:
    next visit - review clincheck.`,

    'Invisalign-ClinCheck': `Patient Presentation:
    Patient presented today for the scheduled Invisalign ClinCheck review appointment.
    [Optional: Include any observations or specific concerns expressed by the patient.]
    ClinCheck Review:
    Showed the patient the digital 3D simulation of the proposed Invisalign treatment plan through the ClinCheck software.
    Discussed the series of aligners and the anticipated movement of teeth throughout the treatment process.
    Emphasized the expected duration and frequency of aligner changes.
    Treatment Plan Discussion:
    Clarified any adjustments or modifications made in the treatment plan compared to the initial assessment.
    Addressed any concerns or queries raised by the patient regarding the projected treatment outcome.
    Oral Health Guidance:
    Reminded the patient of proper care and maintenance during the treatment period.
    Provided instructions on the appropriate wear time for aligners, oral hygiene practices, and follow-up appointments.
    Patient Engagement and Understanding:
    Ensured the patient understood the proposed treatment trajectory and visualized the anticipated outcome.
    Encouraged the patient to ask questions and actively engage in the discussion.
    Next Steps:
    Confirmed the patient's readiness and willingness to proceed with the proposed Invisalign treatment plan.
    Scheduled the commencement date for the treatment and the subsequent follow-up appointments.
    Conclusion:
    Concluded the appointment by ensuring the patient's satisfaction and understanding of the ClinCheck review.`,

    'Invisalign-Fit': `Patient Presentation:
    Patient presented today for the scheduled Invisalign aligners fit and attachment placement appointment.
    [Optional: Include any observations or specific concerns expressed by the patient.]
    Aligners Fit Assessment:
    Evaluated the fit of the current set of aligners provided to the patient.
    Checked for proper seating, comfort, and alignment of the aligners with the patient's dentition.
    Made adjustments to ensure optimal fit and functionality.
    Interproximal Reduction (IPR):
    Conducted Interproximal Reduction as indicated in the treatment plan to create space for tooth movement.
    Utilized appropriate instruments for controlled enamel reduction, adhering to the predetermined IPR measurements.
    Attachment Placement:
    Placed attachments on specific teeth according to the treatment plan for enhanced aligner retention and effectiveness.
    Ensured precise positioning and secure bonding of attachments to the tooth surfaces.
    Oral Health Guidance:
    Advised the patient on maintaining oral hygiene with the aligners in place.
    Provided instructions on aligner wear time, care, and follow-up appointments.
    Patient Engagement and Comfort:
    Ensured the patient's comfort level with the aligners, IPR, and attachment placement.
    Addressed any discomfort or queries raised by the patient during the procedure.
    Next Steps:
    Informed the patient about the next set of aligners and the expected treatment progression. 1 weekly wear per aligner, 22 hours/day. remove for eating and drinking.
    Scheduled the subsequent follow-up appointment to monitor progress and address any concerns.
    Conclusion:
    Concluded the appointment, ensuring the patient's satisfaction with the aligners' fit, IPR, and attachment placement.
    `,

    'Invisalign-Review': `Patient Presentation:
    Patient presented for the scheduled 4-week Invisalign review appointment.
    [Optional: Include any observations or specific concerns expressed by the patient.]
    Aligner Assessment:
    Evaluated the patient's compliance and adherence to the prescribed aligner wear schedule.
    Inspected the condition of the current set of aligners for wear, fit, and potential issues.
    Treatment Progress:
    Assessed the progress of tooth movement and alignment according to the treatment plan.
    Recorded any observed improvements or deviations from the anticipated progress.
    IPR performed as per invisalign treatment plan.
    Patient Comfort and Queries:
    Inquired about the patient's comfort level and experiences with the aligners.
    Addressed any queries or concerns raised by the patient regarding the treatment.
    Oral Health Guidance:
    Reinforced oral hygiene practices during Invisalign treatment.
    Provided guidance on aligner care, cleaning, and wear-time adherence.
    Modifications or Next Steps:
    Discussed potential modifications to the treatment plan, if necessary.
    Planned the distribution of the subsequent sets of aligners based on the treatment progression.
    Patient Engagement and Follow-up:
    Encouraged the patient's continued commitment to treatment compliance and follow-up appointments.
    Scheduled the subsequent review appointment or any additional assessments as required.
    Conclusion:
    Concluded the appointment, ensuring the patient's satisfaction with the treatment progress and aligner wear.`,

    'Invisalign-Debond': `To perform the debonding of Invisalign attachments, acquire 3D scans for the fit of Vivera retainers, and capture post-treatment photos.

    Patient Presentation:
    Patient arrived for the scheduled Invisalign debond appointment.
    [Optional: Include any observations or specific concerns expressed by the patient.]
    Attachment Removal:
    Carefully removed Invisalign attachments using appropriate instruments and techniques.
    Ensured minimal disruption to tooth structure during the debonding process.
    3D Scans for Vivera Fit:
    Conducted precise 3D scans to evaluate the fit of Vivera retainers.
    Verified the alignment and accuracy of the Vivera retainer for optimal post-treatment results.
    Post-Treatment Photos:
    Captured comprehensive post-treatment intraoral and extraoral photos.
    Documented the patient's smile, bite alignment, and any notable changes after Invisalign treatment.
    Patient Comfort and Guidance:
    Assured the patient's comfort during the attachment removal and 3D scanning process.
    Provided instructions on the usage and maintenance of Vivera retainers for long-term retention.
    Oral Health Assessment:
    Conducted a brief oral health evaluation following attachment removal.
    Addressed any immediate concerns regarding oral health post-debonding.
    Conclusion:
    Concluded the debond appointment, ensuring the patient's satisfaction with the process and post-treatment guidance.`,

    'Invisalign-Retainer Fit': `Objective:
To assess the fit and functionality of Vivera retainers following Invisalign treatment.

Patient Presentation:
Patient presented for the scheduled evaluation of Vivera retainer fit post-Invisalign treatment.
[Optional: Include any observations or specific concerns expressed by the patient regarding retainer fit.]
Vivera Retainer Assessment:
Inspected and assessed the fit of Vivera retainers on both upper and lower dental arches.
Verified the retainer placement for comfort and alignment with the patient's teeth.
Bite Alignment and Occlusion Check:
Evaluated the patient's bite to ensure the retainer allows for natural occlusion and proper alignment.
Confirmed that the retainer does not interfere with the patient's bite or cause discomfort.
Patient Comfort and Feedback:
Enquired about the patient's comfort level with the Vivera retainer fit.
Addressed any discomfort or adjustments needed for enhanced comfort and functionality.
Oral Health Instructions:
Reinforced oral hygiene practices and care instructions for maintaining Vivera retainers.
Provided guidance on wearing schedules and retainer care to ensure their effectiveness.
Patient Education:
Educated the patient on the importance of consistent retainer usage to maintain treatment results.
Discussed the significance of follow-up visits and adherence to the prescribed retainer regimen.
Conclusion:
Concluded the Vivera retainer fit evaluation, ensuring the patient's satisfaction with the retainer and providing necessary guidance.`,

    'Crown Prep': `C/O – nil
MH – no changes

Explained process for todays appt- VCG. Advised re hand signals if wishes to stop/rest

Pt attending for prep for:- Emax Partial Crown

LA:

Rubber dam placed
Tooth prepped
Immediate dentine seal - etch dentine 15s, rinsed. Scotchbond applied, air thinned, cured 20s. Final 20s cured under glycerine.
Retraction cord with astringent placed, left for 2mins, removed
iTero scan
Cotton wool, Direct PMMA temp placed, occlusion checked, no adjustments required

Shade:

Sent to lab:
POIG
Pt to return in 2 week for fit
`, 

    'Crown Fit': `CO - Nil
    PMH - no changes
    rest'n received, Rx checked
    LA:
    Provisional restoration removed, rest'n checked in situ
    Rubber dam placed (removed at end of appointment)
    HFl 20s emax surface, rinse & neutralised, silane, air dried
    Phosphoric acid Etch tooth surface 15s
    Scotchbond applied tooth surface, air thinned
    Cemented with Gcem
    rest'n seated, 3s cure, excess removed, contacts cleared
    60s cure, occlusion checked with art paper and on contalateral side
    margins finished with fine diamond and polishing wheels
    Pt happy
    `, 

    'Bridge Prep': `C/O: Nil
MH: No changes
Procedure Details -
Teeth:
Pre-Appointment Explanation:

Explained today's appointment process for bridge preparation (VCG).
Advised the patient about hand signals if they wish to stop or rest during the procedure.
Bridge Preparation:

Patient attending for the preparation of a dental bridge.
Procedure Steps:

Rubber Dam Placement:

Placed rubber dam for isolation.
Tooth Preparation:

Prepared the tooth for the bridge restoration.
Immediate Dentine Seal:

Etched dentine for 15 seconds, rinsed thoroughly.
Applied Scotchbond, air-thinned, and cured for 20 seconds.
Final cure for 20 seconds under glycerine.
Retraction Cord Placement:

Placed retraction cord with astringent for 2 minutes, then removed.
iTero Scan:

Conducted iTero scan for further treatment planning.
Temporary Restoration:

Placed cotton wool, and a Direct PMMA temporary restoration.
Checked occlusion; no adjustments required.
Shade and Follow-up:

Shade Selection:

Sent details to the lab for shade selection and further processing.
Follow-up Appointment:

Instructed the patient to return in 2 weeks for the fitting of the bridge.
Conclusion:
Procedure completed as planned.
Patient instructed on the next steps and follow-up appointment.`,

    'Bridge Fit': `CO: Nil
    PMH: No changes
    Procedure Details:
    Pre-Procedure Checks:
    Restorations received and Rx checked.
    Local Anesthesia (LA) administered.
    Dental Bridge Preparation Steps:
    Provisional Restoration Removal:
    
    Verified and removed provisional restoration.
    Checked the condition of the restoration in situ.
    Rubber Dam Placement:
    
    Placed rubber dam for isolation (removed at the end of the appointment).
    Surface Preparation:
    
    Hydrofluoric acid (HFl) applied on the Emax surface for 20 seconds.
    Rinsed, neutralized, and silane applied.
    Air-dried the surface.
    Tooth Surface Etching and Bonding:
    
    Applied phosphoric acid etch on tooth surface for 15 seconds.
    Applied Scotchbond on the tooth surface and air-thinned.
    Cementation:
    
    Cemented the bridge with Gcem.
    Seated restoration, cured for 3 seconds, and removed excess material.
    Cleared contacts and ensured proper seating.
    Curing and Finishing:
    
    Cured for 60 seconds.
    Checked occlusion using articulation paper and on the contralateral side.
    Finished margins using a fine diamond and polishing wheels.
    Conclusion:
    Procedure successfully completed.
    Patient satisfied and comfortable with the treatment`,

    'Veneer Prep': `C/O: Nil
MH: No changes
Procedure Details:
Pre-Appointment Explanation:

Explained the process for today's veneer preparation (VCG).
Consent Discussion:
Advantages:
Enhanced Aesthetics: Veneers can significantly improve the appearance of teeth, covering stains, discoloration, and minor misalignments.
Minimally Invasive: Compared to some other dental procedures, veneer preparation typically involves minimal alteration of natural teeth.
Durable: High-quality veneers can offer long-lasting results when cared for properly.
Stain-Resistant: Veneers made of porcelain or composite materials are often resistant to staining, maintaining a brighter appearance over time.
Disadvantages:
Potential Sensitivity: Some patients might experience temporary tooth sensitivity after veneer placement, particularly with hot or cold substances.
Replacement Needs: Veneers may need replacement over time due to wear, chipping, or damage.
Irreversible Procedure: Tooth preparation for veneers involves removing a small amount of enamel, which is irreversible.
Risks:
Discomfort: During and after the procedure, patients might experience mild discomfort or sensitivity.
Altered Bite: In rare cases, the bite might feel slightly different after veneer placement.
Aesthetic Dissatisfaction: Despite careful planning, aesthetic expectations might not always be met.
Alternatives:
Dental Bonding: Similar to veneers, dental bonding can address aesthetic concerns but involves applying a composite resin directly to the tooth.
Orthodontic Treatment: For some cases of misalignment or spacing, orthodontic options like braces or clear aligners could be considered.
Teeth Whitening: If the primary concern is tooth discoloration, professional teeth whitening might be an alternative option.
Reviewed the wax-up model with the patient to visualize the expected results.
Advised the patient about using hand signals if they wish to stop or rest during the procedure.
Veneer Preparation:

Patient attending for the preparation of dental veneers.
Procedure Steps:

Rubber Dam Placement:

Placed rubber dam for isolation.
Tooth Preparation:

Prepared the teeth for the veneer restorations.
Immediate Dentin Sealing:

Etched dentine for 15 seconds, rinsed thoroughly.
Applied Scotchbond, air-thinned, and cured for 20 seconds.
Final cure for 20 seconds under glycerine.
Retraction Cord Placement:

Placed retraction cord with astringent for 2 minutes, then removed.
Wax-Up and Putty Stent:

Discussed and reviewed the wax-up model with the patient for their input.
Created a putty stent to aid in the fabrication of temporary veneers.
Temporary Veneers:

Fabricated and placed temporary veneers using the putty stent.

Shade and Follow-up:

Shade Selection:

Sent details to the lab for shade selection and further processing of veneers.
Follow-up Appointment:

Instructed the patient to return for the fitting of the veneers on [scheduled date].
Conclusion:
Procedure completed as planned.
Patient informed and consented regarding the procedure, including advantages, disadvantages, risks, and alternatives.
Patient instructed on the next steps and the follow-up appointment for the veneer fitting.`,

    'Veneer Fit': `Chief Complaint: Nil
    Past Medical History: No changes noted
    Teeth:
    LA:
    Procedure Overview:
    Received the patient for veneer fit; prescription verified
    Local anesthetic administered
    Removed provisional restoration, verified the fit of the new restoration
    Placed and later removed rubber dam during the appointment
    Prepared Emax surface: etched for 20 seconds, rinsed and neutralized, silane application, air dried
    Etched the tooth surface for 15 seconds using phosphoric acid
    Applied Scotchbond to the tooth surface, thinned with air
    Cemented the veneer with Gcem
    Seated the veneer, cured for 3 seconds, removed excess, ensured proper contacts
    Cured for 60 seconds, checked occlusion using art paper and on the contralateral side
    Finished the margins meticulously with fine diamond and polishing wheels
    Patient expressed satisfaction with the result`,

    'Implant-Assessment': `C/O - unable to wear P/-, wishes for fixed option 
HPC - 
MH - checked, no contraindications noted for implant treatment
Dental History: See paper notes - Brushes twice daily with F TP and occasional i/d brushes/floss/m/w

Full intra & extraoral examination conducted. Hard & soft tissue inspected, including check for any signs of benign or malignant lesions. Discussed effect of smoking & alcohol on oral & general health with cessation advice. Dietary and fluoride advice given. Periodontal health checked and BPE.

Full implant assessment completed on paper notes wishes to restore as ...... 

Discussed current periodontal status and need for continued maintenance as directed at examinations including home care and professional hygiene visits. 

CBCT taken full report and surgical plan on sidexis. Alginate imps and facebow reg for study models, surgical guide and occlusal analysis. 

Discussed bone augmentation with allograft/xenograft, failure risks, likely swelling, discomfort controlled with paracetamol and Ibuprofen, possibly time off work to recover, requirement for continued maintenance (including occlusal management if future tooth loss) with 6/12 exam and hyg, periodic radiographs (guarantee void if not carried out). Discussed time scale around 4 weeks for restoration after exposure. 
Discussed full consent letter will be emailed/posted prior to implant placement and pt to contact if wishes for further info.

EO:
tmj, nodes, lips, asymm NAD

IO:
Mucosa, FOM, H+S palate, tongue NAD

Discussions with patient:
Treatment options fully explained with associated pros/cons and costs - 
Do nothing, fixed/bonded bridgework, new P/- (Co/cr, acrylic or flexi material), fixed implant restoration
Implant retained dentures, implant supported telescopic removable bridge, fixed hybrid bridge, conventional bridge with explanation on hard and soft tissue grafting required
 
Things of note
Concerns:-
Motivators:-
DH:
SH:
OH & Perio:-
endodontic treatment and status  
occlusion - stable
NCTSL- Chronological

Diagnosis :
Generalised plaque-induced gingivitis
Caries 
Partially dentate

Treatment plan:
OHI, fluoride and diet advice

Discussions with patient 
Failure rate 2-7% and plan to replace if fails
Increased risk of peri-implantitis when perio disease present 
Increased failure risks with smoking and systemic disease
may require further soft tissue augmentation
time scales planned 
Maintenance and monitoring protocols
surgical risks
Prosthetic failure
post op swelling expected
pain control with NSAIDs

Radiographs
MH checked consent for radiographs gained 
Exposure 64kV 8mA 7.4s
View Taken:  Panoral
Justification: Assessment of pathology and bone levels
Grade: 1
Restored teeth: charted on baseline
Caries: nad
RCT: nad
Pathology: nad
Bone levels: normal

CBCT completed 5x8 maxilla 5.1s 85kv 7mA
Justification - assessment of suitability, bone levels, anatomy, planning and pathology
discussed with pt why scan is necessary and verbal consent given
Grade 1
reported on paper assessment form
`,

    'Implant-Single Placement': `Implant Placement
    Implant site:

    Procedure:
    
    Pre-Procedure Steps: Complaints - nil. Explained and obtained signed consent. Advantages of Implant Placement:
    
    Improved chewing and speech function.
    Enhanced aesthetics and facial structure.
    Long-term durability and stability.
    Disadvantages:
    
    Risk of infection or implant failure.
    Potential bone or tissue complications.
    Cost considerations compared to other treatments.
    Risks Involved:
    
    Infection or post-operative complications.
    Potential damage to adjacent teeth or structures.
    Osseointegration issues leading to implant failure.
    Alternatives to Implant Placement:
    
    Fixed bridges.
    Removable partial dentures.
    Resin-bonded bridges.Checked medical history; patient deemed fit for treatment. Pre-operative CBCT indicates a favorable site with no contraindications.
    Anesthesia: Administered 4.4ml of 2% lidocaine with 1:80,000 epinephrine.
    Preventive Measures: Prescribed 3g amoxicillin pre-op and a 30-second rinse with chlorhexidine gluconate (CHX) mouthwash.
    Procedure Details:
    LA:
    Flap raised, periosteal release. Moderate horizontal bone loss addressed.
    Osteotomy Preparation: Created a 3.8x10.5 BioHorizons tapered internal implant with a motor at 50rpm and hand torque, achieving good primary stability.
    Implant Placement: implant placed at site:
    with guided bone regeneration (GBR).
    GBR: Implemented GBR using MinerOss and Memlok membrane secured with bone tack.
    Closure: Closed with 4/0 vicryl using simple interrupted sutures. Hemostasis achieved. Provided verbal and written post-operative instructions along with emergency contact information.
    Radiographs and Justification:
    
    Radiographs: Patient identified, medical history checked. PA radiograph shows favorable placement and baseline bone level.
    Radiographic Details: Grade 1 exposure, 70kV, 0.207s, 7mA.
    `,
    'Implant-Multiple Placement': `Implant Placement
    Implant site:

    Procedure:
    
    Pre-Procedure Steps: Complaints - nil. Explained and obtained signed consent. Advantages of Implant Placement:
    
    Improved chewing and speech function.
    Enhanced aesthetics and facial structure.
    Long-term durability and stability.
    Disadvantages:
    
    Risk of infection or implant failure.
    Potential bone or tissue complications.
    Cost considerations compared to other treatments.
    Risks Involved:
    
    Infection or post-operative complications.
    Potential damage to adjacent teeth or structures.
    Osseointegration issues leading to implant failure.
    Alternatives to Implant Placement:
    
    Fixed bridges.
    Removable partial dentures.
    Resin-bonded bridges.Checked medical history; patient deemed fit for treatment. Pre-operative CBCT indicates a favorable site with no contraindications.
    Anesthesia: Administered 4.4ml of 2% lidocaine with 1:80,000 epinephrine.
    Preventive Measures: Prescribed 3g amoxicillin pre-op and a 30-second rinse with chlorhexidine gluconate (CHX) mouthwash.
    Procedure Details:
    LA:
    Flap raised, periosteal release. Moderate horizontal bone loss addressed.
    Osteotomy Preparation: Created a 3.8x10.5 BioHorizons tapered internal implant with a motor at 50rpm and hand torque, achieving good primary stability.
    Implant Placement: implant placed at site:
    with guided bone regeneration (GBR).
    GBR: Implemented GBR using MinerOss and Memlok membrane secured with bone tack.
    Closure: Closed with 4/0 vicryl using simple interrupted sutures. Hemostasis achieved. Provided verbal and written post-operative instructions along with emergency contact information.
    Radiographs and Justification:
    
    Radiographs: Patient identified, medical history checked. PA radiograph shows favorable placement and baseline bone level.
    Radiographic Details: Grade 1 exposure, 70kV, 0.207s, 7mA.`,

    'Implant-Immediate Load': `Immediate Loading Implant Placement

Pre-Procedure Assessment:

Complaints: None reported.
Consent: Explained procedure thoroughly, obtained and documented signed consent.
Patient Assessment: Reviewed medical history; patient deemed fit for immediate loading implant placement.
Advantages of Immediate Loading Implants:

Reduced treatment time compared to traditional implant procedures.
Immediate restoration of functionality and aesthetics.
Minimized bone resorption due to early functional loading.
Disadvantages:

Increased risk of implant failure or complications.
Requires meticulous planning and precise placement.
May not be suitable for all patients or implant sites.
Risks Involved:

Potential implant instability or failure.
Risk of osseointegration issues due to early loading.
Possible damage to surrounding tissues or structures.
Alternatives to Immediate Loading Implants:

Traditional delayed loading implant procedures.
Alternative prosthetic options based on individual needs.
Procedure Details:

Administered appropriate anesthesia [Specify details].
Conducted atraumatic extraction and immediate implant placement.
Achieved primary stability to support immediate loading.
Placed temporary prosthesis [Specify type and details].
Instructed the patient on post-operative care and restrictions.
Post-Procedure Recommendations:

Prescribed antibiotics and analgesics as necessary.
Advised on a soft diet and oral hygiene protocols.
Scheduled follow-up appointments for monitoring healing and prosthesis stability.
Radiographs and Justification:

Reviewed pre-operative CBCT or X-rays to confirm implant position and bone density.
Post-operative radiographs to verify immediate implant placement.`,

    'Implant-All on 4': `Implant Fast and Fixed Placement

    Consent pre-operatively obtained from patient for extractions LA administered. elevated and extracted routinely with physics forceps preserving buccal bone, full debridement and curretage of sockets and helbo disinfection
    implant placement site:
     and immediate bridge fit (later today), consent explained and signed, med hist checked verbally and paper notes checked, pt fit and well, pre op CBCT shows favourable sites, no contraindication to tx,LA, pre op 3g amoxicilling and, 30 sec CHX m/w
    mucoperiosteal apron flap, oseotomy prepared and surface cleaned of necrotic bone with waterlase, 3.5x10 (11 21) 4.5x12 (13 15), 4.5x14 (25) bredent blue sky implant with motor 50rpm and hand torque to 50ncm, good primary stability, bone smoothed to implant level and ensuring adequate clearance for prosthesis, straight abutments 11 13, 17 degree 21 15 and 35degree 25, all torque to 25ncm, closed 5/0 vicryl rapide simple interupted suture, HA, open tray non indexed coping hand tightened,impregum master imps, jet bite reg in intermediate F/ prosthesis and jaw reg In Cr and OVD maintained from pre op measurement calipers, sulcus formers placed. Full post op advice sheet given and discussed, emergency contact no. given.
    pt to return for bridge fit in 5 hours approx.
    `,

    'Implant-Exposure': `Implant Healing Cap

Complaints nil. Medical no changes. Implant exposed, closure screw removed, sulcus former cap placed, implant fully integrated with no bone or soft tissue loss, 2.2ml 2% lig 1:80000 adren

MH checked consent for radiographs gained 
Exposure 70kV 0.207s, 7mA
View Taken:  PA 
Justification: Assessment of implant pathology and bone levels
Grade: acceptable
Pathology: nil
Bone levels: Good
`,

    'Implant-Imp': `Open Tray Implant Impression

    Pre-Procedure Assessment:
    
    Patient Preparation: Patient identified and prepared for the open tray implant impression procedure.
    Consent: Obtained informed consent from the patient for the impression procedure.
    Procedure Details:
    
    Impression Type: Open tray implant impression.
    Implant Site Preparation: Verified the location of implants and their stability before beginning the impression procedure.
    Impression Procedure Steps:
    
    Impression Materials: Utilized appropriate impression materials for open tray techniques.
    Tray Selection: Chose and positioned the open tray impression tray onto the implants.
    Impression Material Placement: Carefully injected the impression material around the implant components within the tray.
    Setting Time: Allowed the impression material to set as per the manufacturer's guidelines.
    Removal of Tray: Removed the impression tray once the material had adequately set.
    Evaluation: Checked the quality of the impression for accuracy and completeness.
    Post-Impression Steps:
    
    Inspection: Assessed the impression for any deficiencies or areas requiring correction.
    Patient Comfort: Ensured patient comfort and addressed any discomfort experienced during the procedure.
    Documentation and Post-Procedure Instructions:
    
    Documentation: Recorded details of the impression procedure and any relevant observations.
    Post-Procedure Instructions: Provided post-impression care instructions to the patient, including details on follow-up appointments if necessary.`,

    'Implant-Scan': `Implant 3D Scan with Scanbodies

Pre-Procedure Assessment:

Patient Preparation: Identified and prepared the patient for the implant 3D scan procedure.
Consent: Obtained informed consent from the patient for the 3D scanning procedure.
Procedure Details:

Scan Type: Implant 3D scan utilizing scanbodies for precise imaging and measurements.
Procedure Steps:

Preparation: Positioned and secured the scanbodies onto the implant components.
Scanning Process: Utilized the 3D scanning equipment to capture detailed images and measurements of the implant area with the scanbodies in place.
Data Collection: Ensured accurate data acquisition for prosthetic planning and fabrication.
Post-Scan Steps:

Evaluation: Checked the quality of the captured data to ensure comprehensive coverage and precision.
Data Processing: Transferred the scan data to the necessary software for analysis and further treatment planning.
Documentation and Post-Procedure Instructions:

Documentation: Recorded details of the 3D scan procedure, including specifics about the scanbodies used and any relevant observations.
Post-Procedure Instructions: Provided necessary instructions for post-scan care or follow-up appointments, if required.`,

    'Implant-SR Crown Fit': `Rx Today: Fit Implant Restorations

    Restoration: Screw-Retained Implant crown restoration site:

    LA:
    
    Crown/bridgework received from lab, sulcus former removed.
    
    Restoration hand tightened onto implant and pt allowed to check porcelain work in situ for size, shape, colour - explained to patient no changes can be made once fully tightened. Patient happy with aesthetics and happy to proceed with definitive fit. Screw tightened to 30Ncm, and occlusion checked in static and lateral/protrusive excursions.
    
    PTFE pellet into screw head and composite resin over access. Occlusion re-checked with art paper and shimstock on contalateral side.
    
    Patient happy with final aesthetics. Cleaning instructions given and patient encouraged to use ID aids/water-flosser
    
    Radiographs - pt identified and MH checked
    PA  , baseline bone levels, well fitting crown
    Grade 1, exposure 70kV 0.207s, 7mA
    `,

    'Implant-SR Bridge Fit': `Rx Today: Fit Implant Restorations

    Restoration: Screw-Retained Implant Bridge site:
    
    LA:

    Crown/bridgework received from lab, sulcus former removed.
    
    Restoration hand tightened onto implant and pt allowed to check porcelain work in situ for size, shape, colour - explained to patient no changes can be made once fully tightened. Patient happy with aesthetics and happy to proceed with definitive fit. Screw tightened to 30Ncm, and occlusion checked in static and lateral/protrusive excursions.
    
    PTFE pellet into screw head and composite resin over access. Occlusion re-checked with art paper and shimstock on contalateral side.
    
    Patient happy with final aesthetics. Cleaning instructions given and patient encouraged to use ID aids/water-flosser
    
    Radiographs - pt identified and MH checked
    PA  , baseline bone levels, well fitting crown
    Grade 1, exposure 70kV 0.207s, 7mA
    `,

    'Implant-CM Crown Fit': `Rx Today: Fit Implant Restorations

Restoration: Cement-Retained Implant Crown, site:

LA:

Crown received from lab, sulcus former removed.

Abutment hand tightened onto implant, crown placed onto abutment and pt allowed to check porcelain work in situ for size, shape, colour - explained to patient no changes can be made once cemented. Patient happy with aesthetics and happy to proceed with definitive fit. Screw tightened to 30Ncm, PTFE pellet into access and restoration cemented with TempBond. Occlusion checked in static and lateral/protrusive excursions with art paper and shimstock on contalateral side.

Patient happy with final aesthetics. Cleaning instructions given and patient encouraged to use ID aids/water-flosser

Radiographs - pt identified and MH checked
PA  , baseline bone levels, well fitting crown
Grade 1, exposure 70kV 0.207s, 7mA
`,

    'Implant-CM Bridge Fit': `Rx Today: Fit Implant Restorations

    Restoration: Cement-Retained Implant Bridge, site:

    LA:
    
    bridgework received from lab, sulcus former removed.
    
    Abutment hand tightened onto implant, crown placed onto abutment and pt allowed to check porcelain work in situ for size, shape, colour - explained to patient no changes can be made once cemented. Patient happy with aesthetics and happy to proceed with definitive fit. Screw tightened to 30Ncm, PTFE pellet into access and restoration cemented with TempBond. Occlusion checked in static and lateral/protrusive excursions with art paper and shimstock on contalateral side.
    
    Patient happy with final aesthetics. Cleaning instructions given and patient encouraged to use ID aids/water-flosser
    
    Radiographs - pt identified and MH checked
    PA  , baseline bone levels, well fitting crown
    Grade 1, exposure 70kV 0.207s, 7mA
    `,

    'Implant-Bone Aug': `Tooth Extraction and Bone Augmentation with Guided Bone Regeneration

Pre-Procedure Assessment:

Patient Preparation: Identified and prepared the patient for the tooth extraction and bone augmentation procedure.
Consent: Obtained informed consent from the patient for the extraction and guided bone regeneration process.
Procedure Details:
LA:
Extraction: Extracted the tooth (specify tooth/teeth) using standard extraction techniques.
Socket Preparation: Thoroughly debrided the extraction socket to ensure complete removal of any diseased tissue or debris.
Bone Augmentation: Initiated guided bone regeneration (GBR) using Bio-Oss grafting material and a Goretex membrane to augment the bone in the extraction socket.
Graft Placement: Applied Bio-Oss graft material into the socket to facilitate bone growth and regeneration.
Membrane Placement: Positioned the Goretex membrane over the graft site to protect and contain the graft material, providing a barrier for bone regeneration.
Suturing: Closed the surgical area with appropriate sutures to secure the membrane and graft in place, ensuring optimal healing conditions.
Post-Procedure Instructions:

Post-Surgical Care: Provided detailed post-operative care instructions to the patient for proper wound healing and maintenance.
Medication: Prescribed necessary medications and antibiotics to prevent infection and aid in the healing process.
Follow-up: Scheduled a follow-up appointment to monitor healing progress and remove sutures as required.
Post-Procedure Advice:

Dietary Recommendations: Advised the patient on a soft diet and specific dietary restrictions to facilitate healing.
Oral Hygiene: Instructed the patient on appropriate oral hygiene practices to maintain oral health during the healing phase.
Activity Restrictions: Recommended limited physical activity to avoid disrupting the surgical site.`,

    'Implant-Sinus Lift': `Dental Implant Sinus Lift with Local Anesthesia

    Pre-Procedure Assessment:
    
    Patient Evaluation: Assessed patient's medical history, ensuring suitability for the sinus lift procedure.
    Informed Consent: Obtained informed consent from the patient for the sinus lift and subsequent dental implant procedure.
    Preparation: Explained the procedure details, post-operative care, and potential risks to the patient.
    Procedure Details:
    
    Local Anesthesia: Administered local anesthesia (specify type and dosage) to ensure patient comfort and pain control during the procedure.
    Surgical Site Preparation: Identified and prepared the surgical site for the sinus lift procedure.
    Flap Elevation: Created a mucoperiosteal flap to access the sinus cavity without compromising the surrounding tissues.
    Sinus Floor Elevation: Conducted a careful and controlled elevation of the sinus membrane using appropriate instruments.
    Graft Placement: Placed (specify material) bone graft material into the space created between the sinus floor and the membrane to augment the bone.
    Membrane Closure: Gently repositioned the sinus membrane and closed the flap securely to facilitate proper healing.
    Suture Closure: Closed the surgical area with absorbable sutures to ensure stabilization and protection of the graft.
    Post-Procedure Instructions:
    
    Post-Surgical Care: Provided comprehensive post-operative care instructions to promote healing and prevent complications.
    Medication: Prescribed post-operative medications and antibiotics as necessary to prevent infection and aid in healing.
    Follow-up: Scheduled a follow-up appointment to monitor the healing progress and assess the success of the sinus lift.
    Post-Procedure Advice:
    
    Dietary Recommendations: Advised the patient on a soft diet and avoidance of certain foods to promote healing and minimize discomfort.
    Oral Hygiene: Instructed the patient on proper oral hygiene practices to maintain oral health and reduce the risk of infection.
    Activity Restrictions: Advised on limitations regarding physical activities to prevent complications and ensure proper healing.`,

    'Dentures-Imps': `reason for attendance:imps for denture . Pt consents to Rx.
complaints: nil
upper and lower alg imps taken, disinfected & lab card filled in.

appt made next stage`,

    'Dentures-2nd Imps': `reason for attendance: 2nd imps. Pt consents to Rx.
    PCO nil
    Shade chosen:
    master imps in special trays (adjusted as required)- u/l alg imps taken, disinfected & lab card filled in. Design completed
    
    appt made for bite
    `,

    'Dentures-Bite': `reason for attendance: denture bite. Pt consents to Rx.
    PCO nil
    Shade chosen:
    bite recorded in wax record block and jetbite. disinfected & lab card filled in. Design completed
    
    appt made for try in`,

    'Dentures-2nd Imps & Bite': `reason for attendance: 2nd imps & bite. Pt consents to Rx.
PCO nil
Shade chosen
alg imps taken, occlusion recorded with wax record block, disinfected & lab card filled in. Design completed

appt made for try-in
`,
    'Dentures-Try In': `reason for attendance: Try-in. Pt consents to Rx.
    PCO nil
    Tried in , extensions, adaptation & occlusion good. Pt happy with appearance.
    Disinfected, lab card filled in.
    `,

    'Dentures-Retry': `reason for attendance: Re-try. Pt consents to Rx.
PCO nil
Tried in , extensions, adaptation & occlusion good. Pt happy with appearance.
Disinfected, lab card filled in.
`,

    'Dentures-Fit': `RFA fit , Pt consents to Rx.
    PCO nil
    Fit , extensions, adaptation & occlusion good. Pt happy with aesthetics
    Denture hyg advice given
    advised very likely the denture will be rubbing and will require adjustment. patient to contact if required.
    pt happy
    `,

    'Dentures-Ease': `Denture Ease Appointment

Patient Assessment:

Patient History: Reviewed patient's history and noted complaints related to denture discomfort or sores.
Visual Examination: Evaluated the oral cavity and dentures to identify areas causing irritation or discomfort.
Procedure Details:

Patient Consultation: Engaged in a discussion with the patient regarding their concerns about denture discomfort.
Denture Adjustment: Made necessary adjustments to the denture (specify areas adjusted) to alleviate pressure points causing irritation.
Soft Reline: Conducted a soft reline (if applicable) to improve denture fit and cushioning for enhanced comfort.
Patient Education: Provided guidance to the patient on proper denture care, hygiene, and tips for managing sore spots.
Reassurance: Assured the patient that the adjusted denture and sore spots will gradually heal over time.
Post-Procedure Instructions:

Healing Expectations: Informed the patient about the expected healing process and the duration required for sore spots to resolve.
Follow-up Care: Advised the patient to follow up if discomfort persists or if there are any concerns about the dentures.
Oral Health: Emphasized the importance of maintaining good oral hygiene and regular check-ups for optimal denture comfort and fit.
Post-Appointment Advice:

Comfort Measures: Recommended specific strategies (e.g., warm saltwater rinses) to manage discomfort or soreness during the healing period.
Dietary Suggestions: Provided dietary recommendations that aid in reducing irritation caused by the dentures.
Emergency Contact: Furnished emergency contact details in case the patient experiences severe discomfort or any unexpected issues.`,
   
    'Hyg': `H30
    MH- checked no changes
    Pt c/o- bleeding when brushing and ID cleaning.
    
    Discussed treatment proposed today, VCG.
    Advised re hand signals if wishes to stop/rest. 
    
    Pt currently using:
    Toothbursh:
    Interdental cleaning:
    Other (mouthwash etc)-
    Sensitivity –
    Bleeding - 
    
    Additional Factors:
    Smoker – 
    Diabetes – 
    Cardiovascular disease – 
    
    PDH - 
    BPE – 
    Soft Tissue – 
    
    O/e
    Supragingival calculus- 
    Subgingival calculus 
    OH –
    Restorative 
    Gingivae - 
    
    Rx/ Fine uss and hand scale all Q’s. Airflow all Q’s. 
    Extra checks to ensure pt comfort. Pt happy to continue at every point.
    Advised of Bleeding On Probing in all quadrants. Explained involvement of bacteria in gum disease.
    Discussed need for good OH to prevent caries also. Advised prevention of dental disease is dependent on good OH long term pt given plan for home care:
    (1) Oscillating / rotating brush 2 mins 2 x per day with 2 min timer and pressure
    sensor
    (2) Interdental cleaning aids – Demo’d Tape and Id brushings in mirror in surgery today. Reinforced daily practice
    
    Advised the patient today of possible post treatment sensitivity for a short period. 
    Discussed possible side effects of gum disease in oral cavity - possibility of recession, sensitivity, gaps between teeth, food packing bone loss.
    Advised the patient sensitive tooth paste to be used to reduce effects of any sensitivity. 
    Pt happy with all treatment checked with pt on leaving surgery. Asked if pt had any questions pt did not.
    
    Implant:
    
    Probed, probing depths less than 4 mm 
    Bleeding on probing -
    scaled with titanium/ Teflon implant scaler 
    Irrigated sub gingival 
    Re-enforced OH in this area and discussed plaque and peri-implant diseases
    `, 

    'Perio Tx': `Patient Assessment:

Patient History: Reviewed and updated the patient's medical history and specific concerns related to periodontal health.
Periodontal Examination: Conducted a thorough periodontal examination to assess gum health, pocket depths, and plaque buildup.
Procedure Details:

Patient Consultation: Discussed the treatment plan and the necessity of root surface debridement for periodontal health improvement.
Local Anesthesia Administration: Administered local anesthesia (specify type and dosage) to ensure patient comfort during the procedure.
Root Surface Debridement: Performed meticulous cleaning of the root surfaces to eliminate bacterial biofilm and calculus deposits, promoting periodontal health.
Subgingival Scaling and Curettage: Employed specialized instruments to remove plaque, calculus, and diseased tissue from below the gum line.
Smoothing of Rough Root Surfaces: Smoothed rough areas on the roots to prevent further bacterial accumulation.
Patient Education: Offered oral hygiene guidance and instructions on maintaining periodontal health post-treatment.
Post-Procedure Instructions:

Medication Guidance: Provided details about prescribed medications or oral rinses for post-treatment care (if applicable).
Dietary Recommendations: Suggested dietary adjustments or restrictions for improved periodontal healing.
Oral Hygiene Protocol: Emphasized the importance of diligent oral hygiene practices, including brushing, flossing, and using prescribed rinses.
Follow-up Care: Scheduled a follow-up appointment to assess healing progress and ensure optimal periodontal health.
Post-Treatment Advice:

Temporary Effects: Advised the patient about potential temporary effects such as slight discomfort or gum sensitivity post-treatment.
Healing Expectations: Discussed the expected healing period and informed the patient about any anticipated post-procedural changes in gum health.
Emergency Contact: Provided emergency contact information in case of unexpected complications or concerns after the procedure.`,

    'Sedation': `Verbal and written consent checked and obtained for today’s IV sedation treatment. Medical history re-checked and no contra-indications to IV midazolam sedation. ASA?? as per assessment 
    Emergency drugs and oxygen checked and available
    Midazolam 5mg per 5ml
    Flumazenil 500 ug (not administered) 
    IV cannulation right ACF with blue venflon at first attempt, flushed with saline to check intravenous. IV midazolam given at 1mg per min titrated to patient’s response. 
    Suitable sedation depth achieved with verbal contact maintained. Dental treatment carried out and patient recovered in dental chair. Patient discharged after judged fit with adult escort. 
    Contemporaneous sedation record sheet completed and scanned into notes. Post op sedation advice sheet given and reinforced with patient and escort.
    `,

    'Radiograph': ``,


'FixedOrtho-Consult' : `Chief Complaint:
The patient presents for a consultation regarding fixed orthodontic treatment.

Medical History:

[Include any relevant medical conditions or medications]
Dental History:

[Details about the patient's dental history, previous orthodontic treatment if any]
Clinical Examination:

Extraoral Examination:

No evident facial asymmetry.
Normal range of jaw movements.
[Include any specific findings]
Intraoral Examination:

Class I/II/III malocclusion.
Crowding/spacing of teeth.
Occlusal analysis.
[Include any specific findings]
Radiographic Findings:

Panoramic radiograph obtained, showing [mention any specific findings].
Cephalometric analysis reveals [mention any specific orthodontic parameters].
Diagnosis:

[Provide a concise diagnosis based on the clinical and radiographic findings.]
Treatment Plan:

Fixed orthodontic treatment proposed.
Discuss treatment options, including duration and potential discomfort.
[Include any specific treatment considerations]
Patient Education:

Educated the patient on proper oral hygiene during orthodontic treatment.
Discussed potential dietary restrictions and care for orthodontic appliances.
Follow-up:

Scheduled the patient for further records or initiation of treatment on [Date].
Consultation Summary:
The patient is a suitable candidate for fixed orthodontic treatment, and the proposed plan has been discussed in detail. Informed consent obtained, and the patient expresses understanding and willingness to proceed.
    `,

    'FixedOrtho-Scan': `Reason for 3D Scans:
    The patient presents for a full arch upper and lower 3D scan in the context of a fixed orthodontic consultation.
    
    Clinical Indications:
    
    Comprehensive assessment of dental arch morphology.
    Evaluation of tooth alignment and occlusal relationships.
    Detailed analysis for precise treatment planning.
    Procedure:
    
    Informed the patient about the 3D scanning procedure and its benefits.
    Ensured the patient's comfort and addressed any concerns.
    Utilized [Specify the name/model of the intraoral scanner] for the acquisition of full arch upper and lower digital impressions.
    Captured high-resolution images to create a 3D representation of the patient's dental anatomy.
    Clinical Findings:
    
    Clear visualization of the entire upper and lower dental arches.
    Detailed images of individual teeth, occlusal surfaces, and interproximal spaces.
    Assessment of any existing dental restorations or anomalies.
    Impressions Quality:
    
    [Specify the quality and accuracy of the digital impressions]
    Diagnosis:
    The full arch upper and lower 3D scans provide a comprehensive view of the patient's dental anatomy, aiding in accurate diagnosis and treatment planning for fixed orthodontic intervention.
    
    Treatment Plan:
    
    Based on the 3D scan data, a customized fixed orthodontic treatment plan will be developed.
    Treatment options and timelines will be discussed with the patient during the upcoming consultation.
    Patient Education:
    
    Explained the significance of 3D scans in enhancing diagnostic capabilities.
    Discussed the non-invasive nature of digital impressions compared to traditional methods.
    Follow-up:
    Scheduled the patient for a fixed orthodontic consultation to review the 3D scan results and discuss the proposed treatment plan.`,

    'FixedOrtho-Imps': `Reason for Alginate Impressions:
The patient presents for full arch upper and lower alginate impressions as part of the fixed orthodontic consultation.

Clinical Indications:

Capture detailed impressions of the dental arches.
Assessment of tooth alignment and occlusal relationships.
Provide physical models for precise treatment planning.
Procedure:

Informed the patient about the alginate impression procedure and its purpose.
Checked for any allergies or sensitivities to the impression material.
Ensured the patient's comfort and addressed any concerns before proceeding.
Utilized [Specify the brand/type of alginate impression material] to take full arch upper and lower impressions.
Maintained clear communication with the patient throughout the impression process.
Clinical Findings:

Successfully obtained detailed impressions of the entire upper and lower dental arches.
Accurate representation of individual teeth, occlusal surfaces, and interproximal spaces.
Checked for any voids or distortions in the impressions.
Impressions Quality:

[Specify the quality and accuracy of the alginate impressions]
Diagnosis:
The alginate impressions provide a physical model of the patient's dental anatomy, facilitating precise diagnosis and treatment planning for fixed orthodontic intervention.

Treatment Plan:

Based on the alginate impressions, a customized fixed orthodontic treatment plan will be developed.
Treatment options and timelines will be discussed with the patient during the upcoming consultation.
Patient Education:

Explained the role of alginate impressions in creating accurate models of the teeth.
Discussed the non-invasive nature of alginate impressions compared to digital alternatives.
Follow-up:
Scheduled the patient for a fixed orthodontic consultation to review the alginate impressions and discuss the proposed treatment plan.`,

    'FixedOrtho-Emergency': `Chief Complaint:
    The patient presents with a fixed orthodontic emergency and reports [describe the specific issue, e.g., loose bracket, broken wire].
    
    History:
    
    Date of fixed orthodontic appliance placement: [Date]
    Any recent trauma or injury to the oral cavity: [Yes/No]
    History of previous orthodontic emergencies: [Specify, if applicable]
    Clinical Examination:
    
    Visual inspection of the fixed orthodontic appliance.
    Assessment of bracket integrity, wire positioning, and any loose or displaced components.
    Evaluation of the patient's comfort and any signs of irritation or injury.
    Findings:
    
    [Specify the specific issue, e.g., detached bracket on the upper left first molar]
    [Describe the condition of the archwire, if applicable]
    Emergency Intervention:
    
    [Bracket Repair: Reattached the detached bracket using [Specify the bonding material].
    OR
    Wire Adjustment: Made necessary adjustments to the archwire to address any discomfort or irritation.
    Patient Education: Informed the patient about proper oral hygiene practices and the importance of avoiding certain foods that may contribute to bracket detachment or wire breakage.[]
    Patient Comfort:
    
    Checked for any discomfort or pain during and after the emergency intervention.
    Provided recommendations for over-the-counter pain relief if necessary.
    Instructions to the Patient:
    
    Oral Hygiene: Emphasized the importance of maintaining good oral hygiene, including proper brushing and flossing around orthodontic appliances.
    Dietary Restrictions: Advised the patient to avoid sticky, hard, or crunchy foods that may compromise the integrity of the fixed orthodontic appliance.
    Follow-up: Scheduled a follow-up appointment to monitor the repaired bracket and make any necessary adjustments.
    Patient Communication:
    
    Discussed the nature of the orthodontic emergency with the patient.
    Answered any questions or concerns related to the emergency and ongoing orthodontic treatment.
    Follow-up Plan:
    Scheduled the patient for a follow-up appointment on [Specify the date] to assess the stability of the repaired bracket and address any additional concerns.`,

    'FixedOrtho-Fit': `Objective of Appointment:
    The purpose of this appointment is to fit the patient with fixed orthodontic appliances, ensuring proper placement and functionality.
    
    Clinical Procedure:
    
    Patient Assessment: Confirmed the patient's readiness for fixed orthodontic appliance placement.
    Appliance Placement: Fitted and bonded the orthodontic brackets to the teeth using [Specify bonding material].
    Archwire Installation: Placed the initial archwire and secured it in the brackets.
    Adjustments: Made necessary adjustments to ensure proper alignment and positioning of brackets and wires.
    Patient Education: Provided oral hygiene instructions and explained the importance of proper care during orthodontic treatment.
    Discussion: Discussed the expected discomfort and adjustments the patient may experience during the initial days.
    Orthodontic Appliance Details:
    
    Brackets: [Specify type and manufacturer]
    Archwire: [Specify type and size]
    Elastics/Accessories: [Specify, if applicable]
    Post-Procedure Instructions:
    
    Oral Hygiene: Emphasized the importance of maintaining excellent oral hygiene, including brushing and flossing around orthodontic appliances.
    Dietary Guidelines: Advised the patient on dietary restrictions, avoiding foods that may damage or dislodge orthodontic components.
    Discomfort Management: Provided guidance on managing initial discomfort, including recommendations for over-the-counter pain relief if needed.
    Follow-up Appointments: Scheduled the patient for regular follow-up appointments to monitor progress and make necessary adjustments.
    Patient Communication:
    
    Addressed any questions or concerns the patient had about the fitting process.
    Encouraged open communication regarding any issues or discomfort the patient may experience during orthodontic treatment.
    Follow-up Plan:
    Scheduled the patient for the next follow-up appointment on [Specify the date] to monitor the progress of orthodontic treatment and make any necessary adjustments.`,

    'FixedOrtho-Review': `Objective of Appointment:
The purpose of this appointment is to assess the progress of the patient's fixed orthodontic treatment and make any necessary adjustments.

Clinical Procedure:

Patient Assessment: Evaluated the overall progress of the patient's orthodontic treatment.
Appliance Inspection: Checked the condition and stability of the fixed orthodontic appliances, including brackets, wires, and other components.
Archwire Adjustments: Made any required adjustments to the archwire for optimal tooth movement and alignment.
Bracket Adjustments: Inspected and, if necessary, repositioned brackets to ensure proper tooth engagement.
Interproximal Reduction (IPR): Performed interproximal reduction as needed to create space for tooth movement.
Patient Feedback: Discussed the patient's comfort level, potential issues, and any concerns related to the orthodontic appliances.
Orthodontic Appliance Details:

Brackets: [Specify type and manufacturer]
Archwire: [Specify type and size]
Elastics/Accessories: [Specify, if applicable]
Post-Procedure Instructions:

Oral Hygiene: Reinforced the importance of maintaining excellent oral hygiene, providing guidance on brushing and flossing around orthodontic appliances.
Dietary Guidelines: Reminded the patient of dietary restrictions to prevent damage or dislodgment of orthodontic components.
Discomfort Management: Addressed any discomfort the patient may be experiencing and provided guidance for relief.
Next Steps: Discussed the anticipated timeline for the next phase of treatment and potential future adjustments.
Patient Communication:

Addressed any questions or concerns the patient had about the progress of their orthodontic treatment.
Encouraged open communication regarding any issues or discomfort the patient may experience during orthodontic treatment.
Follow-up Plan:
Scheduled the patient for the next follow-up appointment on [Specify the date] to continue monitoring the progress of orthodontic treatment.`,

    'FixedOrtho-Debond': `Objective of Appointment:
    The purpose of this appointment is to safely and efficiently remove fixed orthodontic appliances, such as brackets and bands, as the patient has completed the active phase of orthodontic treatment.
    
    Clinical Procedure:
    
    Patient Assessment: Evaluated the overall success and completion of the fixed orthodontic treatment.
    Appliance Removal: Gently removed brackets, bands, and other fixed orthodontic appliances using appropriate debonding instruments.
    Residual Adhesive Removal: Carefully cleaned and polished teeth to remove any remaining adhesive and residue.
    Final Evaluation: Assessed the final alignment of teeth and occlusion after appliance removal.
    Patient Education: Provided instructions on post-debonding care, including oral hygiene practices and potential wear of retainers.
    Orthodontic Appliance Details:
    
    Brackets: [Specify type and manufacturer]
    Archwire: [Specify type and size]
    Other Appliances: [Specify, if applicable]
    Post-Debonding Instructions:
    
    Oral Hygiene: Emphasized the importance of maintaining excellent oral hygiene, especially after debonding, and provided guidance on oral care practices.
    Retainer Placement: Discussed the necessity of wearing retainers to maintain the achieved alignment and prevent relapse.
    Dietary Guidelines: Provided dietary recommendations to prevent damage to the teeth and orthodontic results.
    Patient Communication:
    
    Celebrated the successful completion of the fixed orthodontic treatment.
    Discussed the importance of consistent retainer wear to maintain the results achieved during treatment.
    Addressed any questions or concerns the patient had regarding post-treatment care.
    Follow-up Plan:
    Scheduled the patient for a follow-up appointment in [Specify the timeframe] to monitor the stability of the orthodontic results and assess retainer fit.`,

    'FixedOrtho-Retainer Fit': `Objective of Appointment:
The purpose of this appointment is to fit the patient with a fixed orthodontic retainer following the removal of active orthodontic appliances.

Clinical Procedure:

Patient Assessment: Evaluated the overall condition of the patient's dentition and oral health.
Evaluation of Post-Treatment Changes: Assessed any changes that may have occurred since the debonding appointment.
Retainer Selection: Chose an appropriate fixed orthodontic retainer based on the patient's specific case and treatment history.
Bonding Process: Applied dental adhesive to the lingual surface of the teeth and carefully placed the retainer into position.
Curing: Utilized a curing light to set the adhesive and secure the retainer in place.
Final Evaluation: Checked the occlusion and bite to ensure the retainer does not interfere with the natural occlusal harmony.
Orthodontic Retainer Details:

Type: Fixed (Bonded) Retainer
Material: [Specify material, e.g., composite, metal]
Location: Bonded to the lingual surface of [Specify teeth involved]
Post-Fit Instructions:

Oral Hygiene Guidelines: Provided detailed instructions on maintaining optimal oral hygiene, emphasizing proper cleaning around the fixed retainer.
Dietary Restrictions: Discussed dietary considerations to prevent damage to the retainer and surrounding teeth.
Monitoring Changes: Informed the patient about the importance of reporting any issues, such as breakage or discomfort, promptly.
Patient Communication:

Discussed the role of the fixed retainer in preserving orthodontic results.
Answered any questions or concerns the patient had regarding retainer care and long-term maintenance.
Follow-up Plan:
Scheduled the patient for routine follow-up appointments to monitor the stability of the orthodontic results and the condition of the fixed retainer.`,

'Enlighten-Consult': `Reason for Consultation: The patient is seeking information and guidance regarding teeth whitening options, with a specific interest in the Enlighten system.

Patient History:

Dental history reviewed, including previous whitening treatments, if any.
Inquired about any history of tooth sensitivity or other dental concerns.
Discussion:

Explained the Enlighten teeth whitening system and its dual-approach, involving both in-office and at-home treatments.
Highlighted the manufacturer's guarantee, assuring a shade of B1. This typically involves two weeks of at-home whitening followed by a one-hour clinic visit for additional whitening, with the possibility of extended treatment in certain cases.
Discussed the potential benefits of the system, including professional-grade whitening, reduced sensitivity, and long-lasting results.
Emphasized the importance of individualized treatment plans based on tooth shade, lifestyle, and patient preferences.
Addressed any questions or concerns the patient had regarding the whitening process.
Treatment Plan:

Recommended an in-office Enlighten whitening session followed by at-home treatments using custom-fitted trays.
Provided an overview of the expected treatment timeline, including two weeks of at-home whitening and a subsequent one-hour clinic visit.
Discussed post-treatment care and the use of maintenance gels for long-term results.
Manufacturer's Guarantee:

Emphasized that Enlighten guarantees achieving a shade of B1. In case the desired shade is not reached, additional photos will be taken, and Enlighten will provide more gels at no cost to the patient.
Cost and Logistics:

Provided an estimate of the costs associated with the Enlighten whitening treatment.
Discussed scheduling options for the in-office session and the process for obtaining custom trays.
Follow-up:

Scheduled a follow-up appointment to address any additional questions and finalize the treatment plan.
Provided contact information for further inquiries.
Next Steps:

Patient expressed interest in proceeding with the Enlighten whitening treatment.
Follow-up appointment scheduled for [Date].`,

'Enlighten-Scan': `Complaints nil, Medical no changes. Procedure:

The patient underwent an Enlighten 3D Scan for a comprehensive assessment of their dental structure and alignment.
The 3D scanning technology provides detailed images of the teeth, allowing for precise treatment planning.
Purpose of the Scan:

The scan was conducted as part of the initial assessment for an Enlighten teeth whitening treatment.
The 3D images will aid in customizing the whitening treatment plan based on the individual characteristics of the patient's teeth.
Patient Comfort:

The scanning process is non-invasive and generally well-tolerated by patients.
The patient was briefed on the procedure, and any concerns or questions were addressed before initiating the scan.
Next Steps:

The 3D scan results will be used to create custom trays for the Enlighten at-home whitening treatment.
A follow-up appointment will be scheduled to discuss the treatment plan based on the scan findings.
Follow-up:

The patient has been scheduled for a follow-up consultation on [Date] at [Time].
Any additional questions or concerns can be addressed during the follow-up appointment.
Additional Notes:

No immediate issues or concerns were identified during the 3D scan.
The patient expressed enthusiasm for the upcoming Enlighten teeth whitening treatment.`,

'Enlighten-Imps': `Procedure:

The patient underwent alginate impressions as part of the initial assessment for the Enlighten teeth whitening treatment.
Alginate impressions are a non-invasive method used to create precise molds of the patient's teeth.
Purpose of the Impressions:

The impressions will be utilized in the creation of custom trays for the Enlighten at-home whitening treatment.
Custom trays ensure an optimal fit for the whitening gel, enhancing the effectiveness of the treatment.
Impression Process:

The patient's oral structures were carefully examined before the alginate material was applied.
The alginate material was allowed to set briefly, capturing an accurate imprint of the patient's teeth.
Patient Comfort:

Alginate impressions are generally well-tolerated and do not cause discomfort.
The patient was briefed on the impression process, and any concerns or questions were addressed before proceeding.
Next Steps:

The impressions will be used to create custom trays tailored to the unique dental anatomy of the patient.
A follow-up appointment will be scheduled to provide the custom trays and instructions for the at-home Enlighten whitening treatment.
Follow-up:

The patient has been scheduled for a follow-up consultation on [Date] at [Time].
Any additional questions or concerns can be addressed during the follow-up appointment.
Additional Notes:

The alginate impressions were successfully obtained without any immediate issues.
The patient is looking forward to the next steps in the Enlighten teeth whitening process.`,

'Enlighten-Fit': `complaints nil. medical no changes. Preparation:

A series of full photos were taken before the fit procedure for comprehensive documentation.
The initial tooth shade was recorded to establish a baseline for the Enlighten teeth whitening treatment.
Fit Process:

Custom trays, crafted based on the earlier alginate impressions, were provided to the patient.
The fit of the trays was carefully assessed to ensure optimal comfort and coverage of the teeth.
Photo Series:

A full photo series was captured, documenting the current condition of the patient's teeth.
High-quality images were taken from various angles to track the progress of the whitening treatment.
Shade Assessment:

The current tooth shade was compared to the baseline recorded during the initial consultation.
Any changes in shade will be monitored throughout the Enlighten whitening process.
Instructions:

The patient received detailed instructions on the proper usage of the custom trays and the Enlighten whitening gel.
Proper care and maintenance of the trays were explained to maximize the effectiveness of the treatment.
Follow-up Appointments:

Follow-up appointments were scheduled to monitor progress and address any concerns.
The next appointment is scheduled for [Date] at [Time].
Patient's Experience:

The patient reported a comfortable fit of the custom trays.
Any questions or concerns were addressed during the fit appointment.
Next Steps:

The patient will initiate the at-home whitening treatment using the Enlighten gel and custom trays.
Regular follow-ups will track progress and determine the need for additional in-clinic sessions.
Additional Notes:

The fit procedure was successful, and the patient is motivated to begin the Enlighten whitening journey.
The dental team is available for any assistance or guidance during the course of the treatment.`,

'Enlighten-InOffice': `Procedure: Enlighten In-Office Whitening (2x 30-minute applications)
Procedure Details:

The patient underwent an in-office whitening session using the Enlighten system.
Two sessions, each lasting 30 minutes, were conducted to enhance the effectiveness of the whitening process.
Application Process:

The Enlighten whitening gel was carefully applied to the patient's teeth.
The gel was allowed to remain on the teeth for the prescribed duration, without the use of an activating light.
Thorough monitoring was conducted during each 30-minute session to ensure optimal whitening results.
Patient Comfort:

The patient reported minimal discomfort during the procedure.
Adequate measures were taken to ensure the patient's comfort throughout the entire process.
Shade Assessment:

The current tooth shade was assessed before and after the in-office whitening sessions.
Immediate shade improvement was observed following the procedure.
Post-Whitening Instructions:

The patient received post-whitening care instructions to maintain and enhance the results.
Recommendations on diet and habits that may affect tooth color were provided.
Follow-up Recommendations:

Follow-up appointments were discussed to monitor the long-term success of the whitening treatment.
Recommendations for at-home maintenance using Enlighten products were provided.
Patient's Feedback:

The patient expressed satisfaction with the in-office whitening experience.
Any post-procedure sensitivity or concerns were addressed during the appointment.
Next Steps:

The patient will follow post-whitening care instructions for optimal results.
Regular follow-ups will be scheduled to assess the stability of the achieved tooth shade.
Additional Notes:

The Enlighten In-Office Whitening procedure was well-tolerated by the patient.
The dental team is available to address any questions or concerns during the post-whitening period.`,

'Enlighten-ExtraGels': `Patient Concern:

The patient expressed dissatisfaction with the current tooth shade after completing the Enlighten whitening process.
The desired shade of B1 has not yet been achieved.
Procedure Steps:

Thorough examination and assessment of the current tooth shade.
Full series of photographs taken to document the current color of the teeth.
Comparison with the initial shade assessment and the expected shade B1.
Communication with Enlighten:

The patient's case and photographs have been sent to Enlighten for further evaluation.
A request for additional whitening gels has been made to address the current shade discrepancy.
Patient Education:

The patient has been informed that achieving the desired shade may take additional time, especially in challenging cases.
Emphasis on the need for compliance with post-whitening care instructions.
Next Steps:

Await response and recommendations from Enlighten regarding additional whitening gels.
Schedule a follow-up appointment to apply the supplemental whitening as recommended.
Patient Reassurance:

The patient has been reassured that efforts will be made to address the shade concerns effectively.
Clear communication regarding the steps to be taken for optimal results has been provided.
Additional Notes:

It is not uncommon for some cases to require additional whitening sessions to reach the target shade.
The dental team is committed to ensuring patient satisfaction and will monitor progress closely.`,

'Enlighten-Review': `Final Review Appointment:

Date and Time of Appointment: [Date] at [Time]
Reason for Visit: Successful Completion of Enlighten Whitening
Achievements:

The patient has successfully achieved the desired tooth shade of B1 as per the Enlighten whitening process.
Full series of photographs were taken to document the final results.
Procedure Steps:

Comprehensive examination to assess the achieved shade and overall oral health.
Review of the initial shade assessment and comparison with the final results.
Full series of photographs taken to capture the improved tooth color.
Patient Satisfaction:

The patient has expressed satisfaction with the final results of the Enlighten whitening treatment.
Positive feedback received regarding the effectiveness of the whitening process.
Post-Whitening Care:

Reinforcement of post-whitening care instructions for maintaining the achieved shade.
Recommendation for regular dental check-ups and oral hygiene practices.
Documentation:

Detailed documentation of the final shade, including photographs and relevant notes.
Archiving of before-and-after images for future reference.
Patient Education:

Discussion on factors influencing tooth shade and strategies to prevent discoloration.
Encouragement to continue practicing good oral hygiene habits.
Next Steps:

Provide the patient with a record of their achieved B1 shade for their reference.
Schedule follow-up appointments as part of routine dental care.
Appreciation:

Commendation for the patient's commitment to the whitening process and adherence to guidelines.`,

'Basic Exam': `
EXAMINATION

Visit Completed: Yes  
Medical History Updated: Yes

COMPLAINTS
Complaints: None  

Medical History: Checked and updated, nil changes noted.  
Dental History: Regular attender, no issues reported.  
Social History: [Job, pets, holidays, stress in here]  
Smoking: None  
Alcohol: None  

CLINICAL EXAMINATION  
Extraoral Examination -
TMJ: NAD (No abnormality detected). No clicking, popping, or locking history.  
Face: NAD  
Neck: NAD  
Lips: NAD  
Skin: NAD  
Lymph Nodes: NAD  
Salivary Glands: NAD  
Swelling: NAD  
Deviation: NAD  

Intraoral Examination -
Soft Tissues: NAD  
Buccal Mucosa: NAD  
Floor of Mouth: NAD  
Tongue: NAD  
Hard Palate: NAD  
Soft Palate: NAD  
Fauces/Pharynx: NAD  

PERIODONTAL EXAMINATION
BPE: 000/000  
Periodontal Condition: Healthy  
Periodontal Diagnosis: None  
Comment on Periodontal Condition: Stable  
Oral Hygiene: Good  

OCCLUSION AND WEAR 
Wear Facets: None  
Basic Erosive Wear Examination (BEWE): 000/000  
Occlusion: No issues in static and dynamic  
Crossbites: None  
Anterior Open Bite: None  
Overbite: Normal  
Overjet: Normal  

Canine relationship right side: Class I
Molar Relationship right side: Class I
Canine relationship left side: Class I
Molar Relationship left side: Class I
Incisor Relationship: Class I

RADIOGRAPHIC EXAMINATION
Radiographs: None indicated today  
Radiograph Report: N/A  
Radiograph Exposure Settings: N/A  
Maximum Bone Loss: 0%  

RISK ASSESSMENT
Caries Risk: Low  
Periodontal Risk: Low  
Oral Cancer Risk: Low  
Tooth Fracture Risk: Low  

CLINICAL OBSERVATIONS 
Observations: None  

DIAGNOSIS AND TREATMENT PLAN
Diagnoses: None  
Recommended Treatment: None  
Alternatives: None  

DISCUSSION AND RISKS 
Discussion: Nil  
Risks: None  
Benefits: None  
Discussion of Fees: N/A  
Further Discussion: Nil

TREATMENT AND PREVENTATIVE PLAN
Treatment Today: None  
Preventative Plan: Regular hygiene visits, fluoride application 1x/year  

Next Visit: In 6 months for a routine examination`,

};



  
  export default stockTxNoteTemplates;
  
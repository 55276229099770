// imports.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faTimes, faMicrophone, faPaperPlane, faStop, faPause, faPlay, faRocket, faPlus } from '@fortawesome/free-solid-svg-icons';
import { collection, doc, getDoc, setDoc, updateDoc, getDocs, query, where, onSnapshot } from 'firebase/firestore';
import { firestore, auth } from '../../../firebase';
import LoadingPopup from  '../../patientnotes/LoadingPopup';
import LoadingPopup2 from  '../../popup/LoadingPopup2';
import CopyButton from '../../CopyButton';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PatientLetters from '../../letters/PatientLetters';
import ReferralLetters from '../../letters/ReferralLetters';
import ReferralResponseLetter from '../../letters/ReferralResponseLetter';
import PastExamModal from '../../basicexam/PastExamModal';
import ActiveAudioAnalyser from '../../activeaudioanalyser/ActiveAudioAnalyser';
import RiskBankChecker from '../../riskbank/RiskBankChecker';
import DentalChart from '../dentalChart';
import { handleButtonClickMega, generateDiscussion, generateTxplan, generateRisks, generateNote, combineAllPartsNoTemplate } from '../noteGeneration';
import { fetchTemplates, handleSelectTemplate, useFetchTemplatesEffect, selectTemplate } from '../utils/templateUtils';


export { 
    useState, useEffect, useRef, useParams, useNavigate, FontAwesomeIcon, 
    faTriangleExclamation, faTimes, faMicrophone, faPaperPlane, faStop, faPause, faPlay, faRocket, faPlus,
    collection, doc, getDoc, setDoc, updateDoc, getDocs, query, where, onSnapshot, firestore, auth,
    LoadingPopup, LoadingPopup2, CopyButton, getStorage, ref, uploadBytes, getDownloadURL,
    PatientLetters, ReferralLetters, ReferralResponseLetter, PastExamModal, ActiveAudioAnalyser,
    RiskBankChecker, DentalChart, handleButtonClickMega, generateDiscussion, generateTxplan, generateRisks, generateNote, combineAllPartsNoTemplate, 
    fetchTemplates, handleSelectTemplate, useFetchTemplatesEffect, selectTemplate
};

export const useTreatmentPlannerState = () => {
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [isInfoVisible, setIsInfoVisible] = useState(true); 
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [showLoadingPopup2, setShowLoadingPopup2] = useState(false);
    const [nursesInput, setNursesInput] = useState('');
    const [examTemplates, setExamTemplates] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [selectedExamTemplate, setSelectedExamTemplate] = useState(null);
    const searchInputRef = useRef(null);
    const [filteredTreatments, setFilteredTreatments] = useState([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const [generatedNote, setGeneratedNote] = useState('');
    const [isPastExamModalOpen, setIsPastExamModalOpen] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [useMegaFunction, setUseMegaFunction] = useState(false);
    const [teethData, setTeethData] = useState([]);
    const [treatmentplanData, setTreatmentplanData] = useState([]);
    const [viewTreatmentPlan, setViewTreatmentPlan] = useState(false);
    const [isAppending, setIsAppending] = useState(false);
    const { noteId } = useParams();
    const navigate = useNavigate();
  
    return {
      assistantResponse, setAssistantResponse,
      isFetching, setIsFetching,
      copySuccess, setCopySuccess,
      isInfoVisible, setIsInfoVisible,
      showLoadingPopup, setShowLoadingPopup,
      isRecording, setIsRecording,
      audioData, setAudioData,
      mediaRecorder, setMediaRecorder,
      audioChunks, setAudioChunks,
      transcribedNote, setTranscribedNote,
      canStopRecording, setCanStopRecording,
      patientId, setPatientId,
      downloadUrl, setDownloadUrl,
      showLoadingPopup2, setShowLoadingPopup2,
      nursesInput, setNursesInput,
      examTemplates, setExamTemplates,
      searchInput, setSearchInput,
      filteredTemplates, setFilteredTemplates,
      selectedExamTemplate, setSelectedExamTemplate,
      searchInputRef,
      filteredTreatments, setFilteredTreatments,
      focusedItemIndex, setFocusedItemIndex,
      generatedNote, setGeneratedNote,
      isPastExamModalOpen, setIsPastExamModalOpen,
      mediaStream, setMediaStream,
      isPaused, setIsPaused,
      useMegaFunction, setUseMegaFunction,
      teethData, setTeethData,
      treatmentplanData, setTreatmentplanData,
      viewTreatmentPlan, setViewTreatmentPlan,
      noteId, navigate,
      isAppending, setIsAppending
    };
  };

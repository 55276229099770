import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getFirestore, collection, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase'; // Adjust the import path as needed
import LoadingPopup from  '../patientnotes/LoadingPopup';
import { dentistDetails, introduction,
    generalDentalRisks,
    dentalcareDetails,
    compositeDetails,
    rootCanalDetails,
    crownOrOnlayDetails,
    dentureDetails,
    hygieneDetails,
    bridgeDetails,
    extractionDetails,
    treatmentAgreement,
    whiteningDetails,
    dentalimplantDetails,
    patientBehaviour,
    amalgamDetails,
    fissuresealantDetails,
    stainlesssteelcrownDetails,
    compositebondingDetails,
    invisalignDetails,
    fixedorthoDetails,
    veneerDetails,
    sedationDetails,
    fullarchimplantsDetails,
    immediatedentureDetails,
    enlightenDetails } from '../TreatmentDetails';
import axios from 'axios';

const MolarMailerLetters = ({ noteContent, patientId, className, disabled }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [consentForms, setConsentForms] = useState({});

    useEffect(() => {
        // Function to fetch user profile
        const fetchUserProfile = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setUserProfile(userDoc.data());
                }
            }
        };

        // Function to fetch custom consent form templates
        const fetchConsentForms = async () => {
            const uid = auth.currentUser.uid;
            const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
            
            try {
                const consentFormsSnap = await getDoc(consentFormsRef);
                
                if (consentFormsSnap.exists()) {
                    setConsentForms(consentFormsSnap.data());
                } else {
                    console.log('No custom consent form data found. Using default forms.');
                    
                    // Use default consent forms from TreatmentDetails.js
                    setConsentForms({
                        introduction,
                        generalDentalRisks,
                        dentalcareDetails,
                        compositeDetails,
                        rootCanalDetails,
                        crownOrOnlayDetails,
                        dentureDetails,
                        hygieneDetails,
                        bridgeDetails,
                        extractionDetails,
                        treatmentAgreement,
                        whiteningDetails,
                        dentalimplantDetails,
                        patientBehaviour,
                        amalgamDetails,
                        fissuresealantDetails,
                        stainlesssteelcrownDetails,
                        compositebondingDetails,
                        invisalignDetails,
                        fixedorthoDetails,
                        veneerDetails,
                        sedationDetails,
                        fullarchimplantsDetails,
                        immediatedentureDetails,
                        enlightenDetails
                    });
                }
            } catch (error) {
                console.error('Error fetching custom consent form data:', error);
            }
        };

        // Call both fetch functions
        fetchUserProfile();
        fetchConsentForms();
    }, []);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
        const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

        return `${day}/${month}/${year}`;
    }

    const todayDate = formatDate(new Date());

    const stripHtml = (htmlString) => {
        return htmlString.replace(/<[^>]*>?/gm, '');
    };    

    const generateDOCX = async (docxContent) => {
        try {
            console.log("Starting DOCX generation process");
            
            // Fetching the DOCX from DocxCreator
            const response = await axios.post('https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreatorMM', {
                textContent: docxContent,
            });
            
            console.log("Received response from server. Processing data...");
            
            // Convert base64 string to a Blob
            const docxBase64 = response.data.base64;
            const blob = base64ToBlob(docxBase64, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    
            // Create download link
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Consent - Dr. ${userProfile.name}.docx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
    
            console.log("DOCX generation and download process completed");
    
        } catch (error) {
            console.error('Error:', error);
            // Handle errors
        }
    };
    
    // Helper function to convert base64 to Blob
    function base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: mimeType});
    }
    

    const handleButtonClick = async () => {
        setIsProcessing(true);
        setShowLoadingPopup(true);
        console.log("Sending content to cloud function:", noteContent);
        try {

            // Step A: Parse text for treatment plan array
            const treatArrayResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/molarmailIdArray', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: noteContent }),
            });

            if (!treatArrayResponse.ok) {
                throw new Error('Network response was not ok in PatientLetters');
            }

            const treatmentArrayResponse = await treatArrayResponse.json();
            let treatmentArrayText = treatmentArrayResponse.text;

            // Remove the unwanted characters and phrases
            const treatmentArrayCode = treatmentArrayText.replace(/```/g, '').replace(/jsx/g, '').trim();

            console.log("Parsed Tx Plan Array:", treatmentArrayCode);

            console.log("Creating patient letter...");

            console.log("DocX gen now...");
            // ${userProfile.letterheadURL ? `${userProfile.letterheadURL}` : ''}

            const removeLeadingWhitespace = (htmlString) => {
                return htmlString
                // Remove leading white space before HTML tags on each line
                .replace(/^\s+</gm, '<')
                // Remove specific HTML tags
                .replace(/<\/?(ol|ul|strong|i|u|b)>/gm, '');
            };

            const formatHTMLText = (htmlText) => {
                let formattedText = htmlText
                    // Insert newline before certain tags
                    .replace(/(<(p|h1|h2|h3|li|div)[^>]*>)/g, "\n$1")
                    // Remove opening and closing <ul> and <ol> tags
                    .replace(/<\/?(ul|ol|br)>/g, "");
            
                return formattedText;
            };

            let introContent = removeLeadingWhitespace(`Dr. ${userProfile.name}\n${userProfile.practiceName}\n${userProfile.practiceAddress}\n${userProfile.practicePostcode}\n${userProfile.practiceEmail}\n${userProfile.practicePhoneNumber}\n<br>\n${todayDate}\n<br>\n`);
            let fixedLetter = formatHTMLText(noteContent);
            let generalDentalRisks = formatHTMLText(consentForms.dentalcareDetails);
            let dentalCareDetails = formatHTMLText(consentForms.dentalcareDetails);
            let amalgamDetails = formatHTMLText(consentForms.amalgamDetails);
            let bridgeDetails = formatHTMLText(consentForms.bridgeDetails);
            let compositebondingDetails = formatHTMLText(consentForms.compositebondingDetails);
            let rootCanalDetails = formatHTMLText(consentForms.rootCanalDetails);
            let compositeDetails = formatHTMLText(consentForms.compositeDetails);
            let crownOrOnlayDetails = formatHTMLText(consentForms.crownOrOnlayDetails);
            let dentureDetails = formatHTMLText(consentForms.dentureDetails);
            let enlightenDetails = formatHTMLText(consentForms.enlightenDetails);
            let extractionDetails = formatHTMLText(consentForms.extractionDetails);
            let fixedorthoDetails = formatHTMLText(consentForms.fixedorthoDetails);
            let fullarchimplantsDetails = formatHTMLText(consentForms.fullarchimplantsDetails);
            let immediatedentureDetails = formatHTMLText(consentForms.immediatedentureDetails);
            let dentalimplantDetails = formatHTMLText(consentForms.dentalimplantDetails);
            let invisalignDetails = formatHTMLText(consentForms.invisalignDetails);
            let hygieneDetails = formatHTMLText(consentForms.hygieneDetails);
            let sedationDetails = formatHTMLText(consentForms.sedationDetails);
            let veneerDetails = formatHTMLText(consentForms.veneerDetails);
            let whiteningDetails = formatHTMLText(consentForms.whiteningDetails);
            let patientBehaviour = formatHTMLText(consentForms.patientBehaviour);
            let treatmentAgreement = formatHTMLText(consentForms.treatmentAgreement);
            let docXContent = removeLeadingWhitespace(`${introContent.trim()}\n` +
                `<br>\n` +
                `<p>Dear ${patientId},</p>\n` +
                `${fixedLetter.trim()}\n<br>\n` +
                `${generalDentalRisks.trim()}\n<br>\n` +
                `${treatmentArrayCode.includes("amalgam") ? `${amalgamDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("bridge") ? `${bridgeDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("composite-bonding") ? `${compositebondingDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("root-canal") ? `${rootCanalDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("composite-filling") ? `${compositeDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("crown") ? `${crownOrOnlayDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("denture") ? `${dentureDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("enlighten") ? `${enlightenDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("extraction") ? `${extractionDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("fixed-ortho") ? `${fixedorthoDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("full arch implants") ? `${fullarchimplantsDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("immediate-denture") ? `${immediatedentureDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("implant") ? `${dentalimplantDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("invisalign") ? `${invisalignDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("hygiene") ? `${hygieneDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("sedation") ? `${sedationDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("veneer") ? `${veneerDetails.trim()}\n<br>\n` : ''}` +
                `${treatmentArrayCode.includes("whitening") ? `${whiteningDetails.trim()}\n<br>\n` : ''}` +
                `${dentalCareDetails.trim()}\n<br>\n` +
                `${patientBehaviour.trim()}\n<br>\n` +
                `${treatmentAgreement.trim()}`
            );
            
            console.log("DocX Content:", docXContent);
            
            




            await generateDOCX(docXContent);

            setIsProcessing(false);
            setShowLoadingPopup(false);

        } catch (error) {
            console.error('Error:', error);
            setIsProcessing(false);
            setShowLoadingPopup(false);
            alert('Please go to "Profile" and add your letterhead/logo and profile details, then try again.');

        }
    };

    return (
        <>
        <button onClick={handleButtonClick} disabled={disabled || isProcessing} className={className}>
            {isProcessing ? 'Writing Letter...' : 'Consent'}
        </button>

        {showLoadingPopup && <LoadingPopup />}
        </>
    );
};

export default MolarMailerLetters;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import LoadingPopup from  '../patientnotes/LoadingPopup';
import './VoiceChart.css';
import CopyButton from '../CopyButton';

const VoiceChart = () => {
    const [userInput, setUserInput] = useState('');
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [isInfoVisible, setIsInfoVisible] = useState(true); 
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [audioLink, setAudioLink] = useState('');
    const [timer, setTimer] = useState(1200); // 20 minutes in seconds

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        return formattedTime;
    };

    useEffect(() => {
        let countdown;

        if (isRecording) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(countdown);
        };
    }, [isRecording]);


    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(assistantResponse).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000); // Reset message after 2 seconds
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const saveNoteToFirestore = async (cleanedResponse) => {
        if (!cleanedResponse) return;
    
        const uid = auth.currentUser.uid;
        const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
        try {
            await setDoc(noteRef, {
                title: `VoiceChart`,
                content: cleanedResponse,
                timestamp: new Date()
            });
            console.log('Note saved to Firestore');
        } catch (error) {
            console.error('Error saving note to Firestore:', error);
        }
    };
    

    const saveTranscriptToFirestore = async (transcribedNote) => {
        if (!transcribedNote) return;
    
        const uid = auth.currentUser.uid;
        const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
        
        console.log('Audio Link:', audioLink);
        try {
            await setDoc(noteRef, {
                title: `VoiceChart Transcript`,
                content: transcribedNote,
                audio: audioLink,
                timestamp: new Date()
            });
            console.log('Transcript saved to Firestore');
        } catch (error) {
            console.error('Error saving transcript to Firestore:', error);
        }
    };
   

    const fetchAssistantResponse = async () => {
        setIsFetching(true);
        setShowLoadingPopup(true);
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/VoiceChart', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: userInput }),
            });
            
            const result = await response.json();
            const cleanedResponse = result.text.replace(/\*\*/g, '');
            setAssistantResponse(cleanedResponse);
            saveNoteToFirestore(cleanedResponse);
        } catch (error) {
            setAssistantResponse('Error fetching response.');
            setShowLoadingPopup(false);
            setIsFetching(false);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsFetching(false);
            setShowLoadingPopup(false);
        }
    };

    const handleButtonClick = () => {
        if (userInput.trim() !== '') {
            fetchAssistantResponse();
        }
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    
            // Set mimeType to 'audio/webm' for MediaRecorder
            const newMediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            console.log('MediaRecorder started');
            setCanStopRecording(true);
    
            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
    
            newMediaRecorder.onstop = async () => {
                console.log('Recording stopped, creating audio blob');
    
                // Change the type to 'audio/mp3' for Blob
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                console.log('Audio blob created:', (audioBlob.size / (1024 * 1024)).toFixed(2), 'MB');
    
                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const currentDateTime = new Date().toISOString().replace(/[-:.]/g, '');
                    const fileName = `${uid}-${currentDateTime}.webm`; // Change the file extension to webm
                    const filePath = `recordings/${fileName}`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    console.log('Recording uploaded to storage:', filePath);

                    // Send only the filePath to the cloud function
                    setAudioLink(filePath);
                    // sendAudioToCloudFunction(filePath);
                    
                }
            };
    
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
            setTimeout(() => {
                stopRecording();
            }, 1200000);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };

    useEffect(() => {
        if (audioLink) {
            sendAudioToCloudFunction(audioLink);
        }
    }, [audioLink]);
    
    
    const stopRecording = () => {
        if (mediaRecorder) {
            console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setCanStopRecording(false); // Disable the stopRecording button after recording stops
            setTranscribedNote("Loading...");
            setTimer(1200);
        }
    };
    
    useEffect(() => {
        if (audioData) {
            console.log('Audio Blob is set, now sending to cloud function');
            // Send only the filePath to the cloud function
            sendAudioToCloudFunction(audioData.filePath);
        }
    }, [audioData]);
    
    const sendAudioToCloudFunction = async (filePath) => {
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/whisper', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ filePath }),
            });
    
            const result = await response.json();
            console.log('Response body:', result);
    
            setTranscribedNote(result.text);
            setUserInput(result.text);
            setIsRecording(false);
            saveTranscriptToFirestore(result.text);
        } catch (error) {
            console.error('Error sending audio URL:', error);
        }
    };
    
    const downloadAudio = async () => {
        if (audioLink) {
            try {
                const storageRef = ref(getStorage(), audioLink);
                const audioUrl = await getDownloadURL(storageRef);
    
                // Create a temporary anchor element to trigger the download
                const a = document.createElement('a');
                a.href = audioUrl;
                a.download = `audio_recording_${new Date().toISOString()}.webm`;
                
                // Set the target attribute to _blank
                a.target = '_blank';
    
                // Append the anchor to the body and trigger the click event
                document.body.appendChild(a);
                a.click();
    
                // Remove the anchor from the body
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const [consentFormLink, setConsentFormLink] = useState('');

    // Fetch the consent form link once when the component mounts
    useEffect(() => {
        const fetchConsentFormLink = async () => {
            const link = await getConsentFormDownloadLink();
            setConsentFormLink(link);
        };

        fetchConsentFormLink();
    }, []);
    
    const getConsentFormDownloadLink = async () => {
        const consentFormStorageUrl = `recordings/VoiceChart Consent Form.docx`;
        const consentstorageRef = ref(getStorage(), consentFormStorageUrl);
        
        try {
            const consentURL = await getDownloadURL(consentstorageRef);
            return consentURL;
        } catch (error) {
            console.error('Error getting consent form download link:', error);
            return null;
        }
    };

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, ''); // Remove ###
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    
    
    

    return (
        <div className="voice-chart-container">
            <h2>
              VoiceChart
            </h2>
            <div className="voicechart-timer">Recording Time Remaining: {formatTime(timer)}</div>
            <div className="voicechart-paragraph-content-box">
                <p>Welcome to VoiceChart – record your appointment notes effortlessly.</p>
                <p>Just hit "Start Rec" and record the entire appointment.</p>
                <p>Speak into the microphone, and let VoiceChart do the rest.</p>
                <p>Get an audio recording, a transcript, and an AI-generated note seamlessly. It's all saved in your Notes List.</p>
                <p>It may be prudent to get your patient to sign a consent form regarding this. <a href={consentFormLink} target="_blank" rel="noopener noreferrer">Click here for a template you may use if you so wish.</a></p>
            </div>
            <div className="info-box">
                <p>Use a wireless bluetooth microphone and you can record as you work:</p>
                <p>> <a target="_blank" href="https://www.amazon.co.uk/dp/B0BTPH4BP4/ref=sspa_dk_detail_6?psc=1&amp;pd_rd_i=B0BTPH4BP4&amp;pd_rd_w=Ed9zy&amp;content-id=amzn1.sym.7b0d8b34-54be-4fd2-9baf-2d658b11dc53&amp;pf_rd_p=7b0d8b34-54be-4fd2-9baf-2d658b11dc53&amp;pf_rd_r=XNQNES43180KA1ZWKADY&amp;pd_rd_wg=ZUZfz&amp;pd_rd_r=59df37a3-0ccd-4a1d-b3b9-fac69c14f5a1&amp;s=electronics&amp;sp_csd=d2lkZ2V0TmFtZT1zcF9kZXRhaWxfdGhlbWF0aWM&_encoding=UTF8&tag=k3vshannon-21&linkCode=ur2&linkId=6b197e4196fd77db6b077306095e811c&camp=1634&creative=6738" rel="noreferrer">BlueTooth Microphone</a></p>
                <p>> <a target="_blank" href="https://www.amazon.co.uk/Adapter-Compatible-Notebook-Smartphone-Samsung/dp/B09JV5FM2S/ref=sr_1_3?crid=36IZ2MXS0LH9A&amp;keywords=usb+c+to+usb&amp;qid=1705499847&amp;s=electronics&amp;sprefix=usbc+to+usb%252Celectronics%252C73&amp;sr=1-3&_encoding=UTF8&tag=k3vshannon-21&linkCode=ur2&linkId=1c39e058617c70db5207bfd010980dc9&camp=1634&creative=6738" rel="noreferrer">Adapter</a></p>
            </div>

            {showLoadingPopup && <LoadingPopup />}
            <div className="voicechart-input-output-container">
                <div className="voicechart-input-section">
                <textarea
                        placeholder="Record the appointment..."
                        value={transcribedNote}
                        onChange={(e) => setTranscribedNote(e.target.value)}
                        readOnly={true} 
                    ></textarea>
                    <div className="voicechart-button-container">
                        <button
                            onClick={startRecording}
                            disabled={isRecording}
                            className={isRecording ? "recording-button" : ""}
                        >
                            {isRecording ? "Recording..." : "Start Rec"}
                        </button>
                        <button
                            onClick={stopRecording}
                            disabled={!canStopRecording}
                        >
                            Stop Rec
                        </button>
                        <button
                        onClick={downloadAudio}
                        disabled={!audioLink}
                        >
                        Download Audio
                    </button>
                    </div>
                    <button
                    onClick={handleButtonClick}
                    disabled={!transcribedNote}
                    className={isFetching ? 'loading' : ''}
                    >
                    {isFetching ? 'Fetching...' : 'Generate AI Note'}
                    </button>
                    
            </div>
                
                <div className="voicechart-output-section">
                    <textarea
                        value={removeDoubleAsterisks(assistantResponse)}
                        placeholder="Notes will load here..."
                    />
                    <div className="voicechart-button-container">
                    <CopyButton textToCopy={assistantResponse} />
                    
                </div>
                </div>
            </div>
            </div>
        
    );
};

export default VoiceChart;
import React from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const UpdatePopup = ({ onOk, onDoNotShowAgain }) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onDoNotShowAgain();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const addBasicExamTemplate = async () => {
    try {
      const user = auth.currentUser;
  
      if (!user) {
        alert('No user is currently logged in.');
        return;
      }
  
      const uid = user.uid;
  
      const examTemplateRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');
  
      const basicExamTemplate = `
EXAMINATION

Visit Completed: Yes  
Medical History Updated: Yes

COMPLAINTS
Complaints: None  

Medical History: Checked and updated, nil changes noted.  
Dental History: Regular attender, no issues reported.  
Social History: [Job, pets, holidays, stress in here]  
Smoking: None  
Alcohol: None  

CLINICAL EXAMINATION  
Extraoral Examination - 
TMJ: NAD (No abnormality detected). No clicking, popping, or locking history.  
Face: NAD  
Neck: NAD  
Lips: NAD  
Skin: NAD  
Lymph Nodes: NAD  
Salivary Glands: NAD  
Swelling: NAD  
Deviation: NAD  

Intraoral Examination - 
Soft Tissues: NAD  
Buccal Mucosa: NAD  
Floor of Mouth: NAD  
Tongue: NAD  
Hard Palate: NAD  
Soft Palate: NAD  
Fauces/Pharynx: NAD  

PERIODONTAL EXAMINATION
BPE: 000/000  
Periodontal Condition: Healthy  
Periodontal Diagnosis: None  
Comment on Periodontal Condition: Stable  
Oral Hygiene: Good  

OCCLUSION AND WEAR 
Wear Facets: None  
Basic Erosive Wear Examination (BEWE): 000/000  
Occlusion: No issues in static and dynamic  
Crossbites: None  
Anterior Open Bite: None  
Overbite: Normal  
Overjet: Normal  

Canine relationship right side: Class I
Molar Relationship right side: Class I
Canine relationship left side: Class I
Molar Relationship left side: Class I
Incisor Relationship: Class I

RADIOGRAPHIC EXAMINATION
Radiographs: None indicated today  
Radiograph Report: N/A  
Radiograph Exposure Settings: N/A  
Maximum Bone Loss: 0%  

RISK ASSESSMENT
Caries Risk: Low  
Periodontal Risk: Low  
Oral Cancer Risk: Low  
Tooth Fracture Risk: Low  

CLINICAL OBSERVATIONS 
Observations: None  

DIAGNOSIS AND TREATMENT PLAN
Diagnoses: None  
Recommended Treatment: None  
Alternatives: None  

DISCUSSION AND RISKS 
Discussion: Nil  
Risks: None  
Benefits: None  
Discussion of Fees: N/A  
Further Discussion: Nil

TREATMENT AND PREVENTATIVE PLAN
Treatment Today: None  
Preventative Plan: Regular hygiene visits, fluoride application 1x/year  

Next Visit: In 6 months for a routine examination
      `;
  
      await setDoc(examTemplateRef, { text: basicExamTemplate });
  
      alert("Basic exam template added to your account successfully.");
      onDoNotShowAgain(); // Close the modal and do not show it again
    } catch (error) {
      console.error('Error adding template:', error);
      alert('An error occurred while adding the template.');
    }
  };
  
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-[100] bg-black bg-opacity-50"
      onClick={handleBackgroundClick}
    >
      <div
        className="relative p-8 w-full max-w-2xl bg-white rounded-lg shadow max-h-[90vh] overflow-auto tracking-tighter text-sm"
        onClick={handleContentClick}
      >
        <h1 className="text-xl font-bold mb-6 text-center">🔧 Latest Updates 🔧</h1>

        <div className="mb-6">
          {/* Platform Simplification */}
          <h2 className="text-xl font-semibold mb-2">Platform Simplification</h2>
          <p className="mb-4">
            To enhance your experience, we've streamlined our platform by merging <strong>Platypus</strong> and <strong>Jazmode</strong> into a single, intuitive feature: <strong>Exam Notes</strong>. This consolidation makes managing and accessing your exam-related information more straightforward than ever.
          </p>

          {/* New Features */}
          <h2 className="text-xl font-semibold mb-2">New Features</h2>
          <p className="mb-4">
            <strong>Effortless Chart Viewing:</strong> Access the chart easily by clicking the button located in the top right corner of the Exam Notes page.
          </p>

          {/* Under-the-Hood Enhancements */}
          <h2 className="text-xl font-semibold mb-2">Under-the-Hood Enhancements</h2>
          <p className="mb-4">
            We've implemented several behind-the-scenes fixes to ensure smoother performance and enhanced reliability across the platform.
          </p>

          {/* Critical Instruction */}
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
            <strong className="font-bold">Important!</strong>
            <span className="block sm:inline"> If you encounter any issues, please <span className="underline">clear your browser cache and refresh the page</span>. This simple step resolves most minor glitches and ensures you benefit from our latest updates.</span>
          </div>

          {/* Coming Soon
          <h2 className="text-xl font-semibold text-blue-500 mb-2">Coming Soon:</h2>
          <ul className="list-disc list-inside mb-4">
            <li>Template Sharing Between Users</li>
            <li>Integrated Support Chat</li>
            <li>Community Forum for Discussions</li>
          </ul> */}
        </div>

        {/* Calls to Action */}
        <div className="flex flex-col justify-center mt-4 space-y-4">
          {/* <button
            onClick={addBasicExamTemplate}
            className="px-4 py-2 text-xs text-white bg-green-500 rounded-lg hover:bg-green-600"
          >
            Add Recommended Basic Exam Template
          </button> */}
          <div className="flex justify-center space-x-2">
            <button
              onClick={onOk}
              className="px-4 py-2 text-xs text-gray-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
            >
              Close
            </button>
            <button
              onClick={onDoNotShowAgain}
              className="px-4 py-2 text-xs text-gray-400 rounded-lg border border-gray-200 hover:bg-primary-400 hover:text-white"
            >
              Do Not Show Again
            </button>
          </div>
        </div>
      </div>
    </div>
);
};

export default UpdatePopup;

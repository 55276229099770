import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getFirestore, collection, getDoc } from 'firebase/firestore';
import { auth } from '../../firebase'; // Adjust the import path as needed
import LoadingPopup from  '../patientnotes/LoadingPopup';

const ReferralResponseLetter = ({ noteContent }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, ''); // Remove ###
        formattedText = formattedText.replace(/【\d+†.*?】/g, ''); // Remove citations
        return formattedText;
    };

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(getFirestore(), 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handleButtonClick = async () => {
        setIsProcessing(true);
        setShowLoadingPopup(true);
        console.log("Sending content to cloud function:", noteContent);
        try {
            // Step 1: Send content to PatientLetters to format it
            const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
            Dentist's Name: ${dentistName}.
            ${noteContent}`;

            const letterResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/referralresponseletter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }),
            });

            if (!letterResponse.ok) {
                throw new Error('Network response was not ok in PatientLetters');
            }

            const letterData = await letterResponse.json();
            console.log("Formatted letter content:", letterData.text);
    
            // Clean up the letter content using removeDoubleAsterisks function
            const cleanedLetterText = removeDoubleAsterisks(letterData.text);
            console.log("Cleaned letter content:", cleanedLetterText);
    
            // Step 2: Send cleaned content to DocxCreator to create a .docx file
            const docxResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ textContent: cleanedLetterText }),
            });
    
            if (!docxResponse.ok) {
                throw new Error('Network response was not ok in DocxCreator');
            }
    
            const docxData = await docxResponse.json();

            // Convert base64 string to a Blob
            const docxContent = atob(docxData.base64);
            const arrayBuffer = new Uint8Array(docxContent.length);
            for (let i = 0; i < docxContent.length; i++) {
                arrayBuffer[i] = docxContent.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

            // Create download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'ReferralResponseLetter.docx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();

            const uid = auth.currentUser.uid; // Get the UID of the logged-in user
            const storage = getStorage();
            const storageRef = ref(storage, `letters/${uid}/ReferralResponseLetter.docx`);

            // Upload the blob to Firebase Storage
            const snapshot = await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Save the download URL to Firestore
            const firestore = getFirestore();
            const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
            await setDoc(noteRef, {
                title: 'Referral Response Letter',
                content: downloadURL,
                timestamp: new Date()
            });

            setIsProcessing(false);
            setShowLoadingPopup(false);

        } catch (error) {
            console.error('Error:', error);
            setIsProcessing(false);
            setShowLoadingPopup(false);
            alert('Sorry, there was an error with the AI. Please try again.');

        }
    };

    return (
        <>
        <button 
        onClick={handleButtonClick} 
        disabled={isProcessing} 
        className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
        >
            {isProcessing ? 'Writing Letter...' : 'Referral Reply Letter'}
        </button>

        {showLoadingPopup && <LoadingPopup />}
        </>
    );
};

export default ReferralResponseLetter;

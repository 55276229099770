import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';

const RadiographSettings = () => {
  const [selectedRadiograph, setSelectedRadiograph] = useState('');
  const [radiographSettings, setRadiographSettings] = useState({
    BW: '',
    Periapical: '',
    OPG: '',
    CBCT: ''
  });
  const radiographTypes = ['BW', 'Periapical', 'OPG', 'CBCT'];
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev); // Toggle visibility state
  };

  useEffect(() => {
    const loadRadiographSettings = async () => {
      const uid = auth.currentUser.uid;
      const radiographRef = doc(firestore, 'customers', uid, 'templates', 'xraysettings');
      const radiographSnap = await getDoc(radiographRef);
      if (radiographSnap.exists()) {
        setRadiographSettings(radiographSnap.data());
      }
    };
    loadRadiographSettings();
  }, []);

  const handleRadiographSelection = (type) => {
    setSelectedRadiograph(type);
  };

  const handleInputChange = (value) => {
    setRadiographSettings(prev => ({
      ...prev,
      [selectedRadiograph]: value
    }));
  };

  const handleSaveRadiographSettings = async () => {
    const uid = auth.currentUser.uid;
    const radiographRef = doc(firestore, 'customers', uid, 'templates', 'xraysettings');
    try {
      await setDoc(radiographRef, radiographSettings);
      console.log('Radiograph settings saved successfully');
      alert('Radiograph setting saved successfully');
    } catch (error) {
      console.error('Error saving radiograph settings:', error);
    }
  };

  return (
    <div className="">
      <div className="" onClick={toggleVisibility}>
        <h3>Radiograph Settings</h3>
      </div>
      {isVisible && (
        <div className="templates-content">
          <div className="treatment-buttons-settings">
            {radiographTypes.map(type => (
              <button
                key={type}
                className={`treatment-button ${selectedRadiograph === type ? 'selected' : ''}`}
                onClick={() => handleRadiographSelection(type)}
              >
                {type}
              </button>
            ))}
          </div>
          {selectedRadiograph && (
            <textarea
              className="template-textbox"
              value={radiographSettings[selectedRadiograph]}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder={`Enter text for ${selectedRadiograph}`}
            />
          )}
          <div className="save-buttons-container">
            <button className="save-template-button" onClick={handleSaveRadiographSettings}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RadiographSettings;

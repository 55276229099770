import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import 'react-quill/dist/quill.snow.css';
import './settings.css';
import {
  introduction,
  generalDentalRisks,
  dentalcareDetails,
  compositeDetails,
  rootCanalDetails,
  crownOrOnlayDetails,
  dentureDetails,
  hygieneDetails,
  bridgeDetails,
  extractionDetails,
  treatmentAgreement,
  whiteningDetails,
  dentalimplantDetails,
  fullarchimplantsDetails,
  patientBehaviour,
  amalgamDetails,
  fissuresealantDetails,
  stainlesssteelcrownDetails,
  compositebondingDetails,
  invisalignDetails,
  fixedorthoDetails,
  veneerDetails,
  sedationDetails,
  immediatedentureDetails,
  enlightenDetails
} from '../TreatmentDetails';

Quill.register('modules/imageUploader', ImageUploader);

const ConsentForms = () => {
  const [selectedSection, setSelectedSection] = useState('');
  const [consentForms, setConsentForms] = useState({});
  const [newFormName, setNewFormName] = useState('');
  const sections = [
    'introduction', 'generalDentalRisks', 'dentalcareDetails', 'compositeDetails', 'amalgamDetails', 'fissuresealantDetails', 'stainlesssteelcrownDetails',
    'rootCanalDetails', 'crownOrOnlayDetails', 'veneerDetails', 'compositebondingDetails', 'dentureDetails', 'hygieneDetails',
    'bridgeDetails', 'extractionDetails', 'immediatedentureDetails', 'whiteningDetails', 'enlightenDetails', 'invisalignDetails', 'fixedorthoDetails',
    'dentalimplantDetails', 'fullarchimplantsDetails', 'sedationDetails', 'patientBehaviour', 'treatmentAgreement'
  ];
  const [isConsentFormsVisible, setIsConsentFormsVisible] = useState(false);
  const textareaRef = useRef(null);
  const sectionNames = {
    'introduction': 'Introduction',
    'generalDentalRisks': 'General Dental Risks',
    'dentalcareDetails': 'Dental Care Details',
    'compositeDetails': 'Composite',
    'rootCanalDetails': 'Root Canal',
    'crownOrOnlayDetails': 'Crown/Onlay',
    'dentureDetails': 'Dentures',
    'hygieneDetails': 'Hygiene',
    'bridgeDetails': 'Bridge',
    'extractionDetails': 'Extraction',
    'immediatedentureDetails': 'Immediate Denture',
    'whiteningDetails': 'Whitening',
    'enlightenDetails': 'Enlighten',
    'dentalimplantDetails': 'Dental Implant',
    'fullarchimplantsDetails': 'Full Arch Implants',
    'patientBehaviour': 'Patient Behaviour',
    'treatmentAgreement': 'Treatment Agreement',
    'amalgamDetails': 'Amalgam Filling',
    'fissuresealantDetails': 'Fissure Sealant',
    'stainlesssteelcrownDetails': 'Stainless Steel Crown',
    'compositebondingDetails': 'Composite Bonding',
    'invisalignDetails': 'Invisalign',
    'fixedorthoDetails': 'Fixed Orthodontics',
    'veneerDetails': 'Veneers',
    'sedationDetails': 'Sedation',
  };  
  
  const toggleConsentFormsVisibility = () => {
    setIsConsentFormsVisible(!isConsentFormsVisible);
  };

  useEffect(() => {
    const loadConsentForms = async () => {
      const uid = auth.currentUser.uid;
      const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
      const consentFormsSnap = await getDoc(consentFormsRef);
  
      if (consentFormsSnap.exists()) {
        const loadedConsentForms = consentFormsSnap.data();
        setConsentForms(loadedConsentForms);
      } else {
        const initialConsentForms = {};
        sections.forEach((section) => {
          initialConsentForms[section] = defaultContents[section];
        });
        await setDoc(consentFormsRef, initialConsentForms);
        setConsentForms(initialConsentForms);
      }
  
      const defaultSection = sections[0];
      handleSectionSelection(defaultSection);
    };
  
    loadConsentForms();
  }, []);
  
  const handleSectionSelection = (section) => {
    setSelectedSection(section);
    if (textareaRef.current) {
      textareaRef.current.focus(); 
    }
  };  

  const handleSaveSection = async () => {
    const uid = auth.currentUser.uid;
    const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
    try {
      await updateDoc(consentFormsRef, { [selectedSection]: consentForms[selectedSection] });
      alert('Template saved successfully');
    } catch (error) {
      console.error('Error saving consent form section:', error);
    }
  };
  
  const handleSaveAllSections = async () => {
    const uid = auth.currentUser.uid;
    try {
      const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
      await updateDoc(consentFormsRef, consentForms);
      alert('Templates saved successfully');
    } catch (error) {
      console.error('Error saving consent form sections:', error);
    }
  };

  const handleAddConsentForm = async () => {
    const trimmedFormName = newFormName.trim();
    if (trimmedFormName && !consentForms[trimmedFormName]) {
      const updatedConsentForms = {
        ...consentForms,
        [trimmedFormName]: ''
      };
      setConsentForms(updatedConsentForms);
      setNewFormName('');

      const uid = auth.currentUser.uid;
      const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
      try {
        await setDoc(consentFormsRef, updatedConsentForms);
        console.log('Consent form added successfully');
      } catch (error) {
        console.error('Error adding consent form:', error);
      }
    } else {
      alert('Invalid or duplicate form name');
    }
  };

  const handleDeleteConsentForm = async () => {
    const confirmed = window.confirm(`Are you sure you want to delete the consent form "${selectedSection}"? This action cannot be undone.`);
    if (!confirmed) return;

    const updatedConsentForms = { ...consentForms };
    delete updatedConsentForms[selectedSection];

    const uid = auth.currentUser.uid;
    const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');
    try {
      await setDoc(consentFormsRef, updatedConsentForms);
      console.log('Consent form deleted successfully');
    } catch (error) {
      console.error('Error deleting consent form:', error);
    }

    const remainingSections = Object.keys(updatedConsentForms);
    setSelectedSection(remainingSections.length > 0 ? remainingSections[0] : '');
    setConsentForms(updatedConsentForms);
  };
  
  const handleTextChange = (content, delta, source, editor) => {
    if (selectedSection && consentForms[selectedSection] !== editor.getHTML()) {
      const sanitizedHtml = sanitizeHtml(editor.getHTML());
      setConsentForms(prevConsentForms => ({
        ...prevConsentForms,
        [selectedSection]: sanitizedHtml
      }));
    }
  };
  
  // Function to sanitize HTML and remove empty paragraphs and unnecessary line breaks
  const sanitizeHtml = (html) => {
    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Remove empty paragraphs
    const paragraphs = tempDiv.getElementsByTagName('p');
    for (let i = paragraphs.length - 1; i >= 0; i--) {
      if (paragraphs[i].innerHTML.trim() === '') {
        paragraphs[i].parentNode.removeChild(paragraphs[i]);
      }
    }
  
    // Remove unnecessary line breaks
    const cleanedHtml = tempDiv.innerHTML.replace(/<br>\s*<br>/g, '<br>');
  
    return cleanedHtml;
  };
  
  const defaultContents = {
    introduction,
    generalDentalRisks,
    dentalcareDetails,
    compositeDetails,
    rootCanalDetails,
    crownOrOnlayDetails,
    dentureDetails,
    hygieneDetails,
    bridgeDetails,
    extractionDetails,
    treatmentAgreement,
    whiteningDetails,
    dentalimplantDetails,
    patientBehaviour,
    amalgamDetails,
    fissuresealantDetails,
    stainlesssteelcrownDetails,
    compositebondingDetails,
    invisalignDetails,
    fixedorthoDetails,
    veneerDetails,
    sedationDetails,
    fullarchimplantsDetails,
    immediatedentureDetails,
    enlightenDetails
  };

  const handleResetToDefault = () => {
    if (selectedSection) {
      const confirmReset = window.confirm(`Are you sure you want to reset '${sectionNames[selectedSection]}' to default?`);
      if (confirmReset) {
        const sanitizedDefaultContent = sanitizeHtml(defaultContents[selectedSection]);
        setConsentForms(prevConsentForms => ({
          ...prevConsentForms,
          [selectedSection]: sanitizedDefaultContent
        }));
      }
    }
  };

  const handleResetAllToDefault = () => {
    const confirmReset = window.confirm(`Are you sure you want to reset all sections to default? This action cannot be undone.`);
    if (confirmReset) {
      const sanitizedDefaultContents = {};
      for (const section in defaultContents) {
        sanitizedDefaultContents[section] = sanitizeHtml(defaultContents[section]);
      }
      setConsentForms(sanitizedDefaultContents);
    }
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-full mx-auto">

      {/* FORM ADDING SECTION */}
      <div className="flex items-center justify-center mt-4 mb-4">
        <input
          type="text"
          placeholder="New Consent Form Name"
          value={newFormName}
          onChange={(e) => setNewFormName(e.target.value)}
          className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          className="ml-2 px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
          onClick={handleAddConsentForm}
        >
          Add Form
        </button>
      </div>

      {/* TREATMENT BUTTONS */}
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col h-64 mx-auto">
          <div className="flex flex-wrap justify-center gap-4 mb-4 mt-8">
            {Object.keys(consentForms).map(section => (
              <button
                key={section}
                className={`${selectedSection === section ? 'p-2 bg-primary-400 text-white text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400' : 'p-2 bg-white text-gray-700 text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400'}`}
                onClick={() => handleSectionSelection(section)}
              >
                {sectionNames[section] || section} {/* Use the mapped name */}
              </button>
            ))}
          </div>
          <div className="flex mx-auto">
            <button 
              className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white" 
              onClick={handleSaveAllSections}
            >
              Save All
            </button>
              {selectedSection && (
              <button
                className="px-4 py-2 text-xs text-red-400 bg-white border border-gray-200 hover:bg-red-400 hover:text-white"
                onClick={handleDeleteConsentForm}
              >
                Delete Form
              </button>
            )}
            
            {selectedSection && (
              <button 
                className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
                onClick={handleResetToDefault}
              >
                Reset {sectionNames[selectedSection]} to Default
              </button>
            )}
            <button 
              className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
              onClick={handleResetAllToDefault}
            >
              Reset ALL to Default
            </button>
          </div>

        </div>

        <div className="text-md">
          <ReactQuill
            theme="snow"
            key={selectedSection}
            value={consentForms[selectedSection] || ''}
            onChange={handleTextChange}
            modules={ConsentForms.modules}
            formats={ConsentForms.formats}
            placeholder="Type the content here..."
            style={{ marginBottom: '15px', width: '100%' }}
          />
        </div>
      </div>
    </main>
  );
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});


ConsentForms.modules = {
  toolbar: {
    container: [
      [{'header': [2, 3, false]}],
      [{'list': 'bullet'},],
      ['image'],
    ],
  },
  imageUploader: {
    upload: file => {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const storageRef = ref(storage, 'images/' + file.name); // Adjust path as needed
        uploadBytes(storageRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!');
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve(downloadURL);
          });
        }).catch((error) => {
          console.error("Error uploading file to Firebase Storage:", error);
          reject("Upload failed");
        });
      });
    }
  }
};

ConsentForms.formats = [
  'header', 'list', 'image'
];

export default ConsentForms;

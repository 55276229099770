import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, query, orderBy, limit, startAfter, startAt, getCountFromServer, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import './NotesList.css';
import CopyButton from '../CopyButton';
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import MolarMailerConsent from '../molarmailer/MolarMailerConsent';
import CreateMolarMailButton from '../modularletters/CreateMolarMailButton';
import CircleLoader from '../circleloader/CircleLoader';
import { useSelectedNote } from './SelectedNoteContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const NotesList = () => {
    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);
    const [groupedNotes, setGroupedNotes] = useState({});
    const { selectNote } = useSelectedNote();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const notesPerPage = 20;

    const fetchNotes = async (page = 1) => {
        setIsLoading(true);
        const uid = auth.currentUser.uid;
        let notesQuery = query(
            collection(firestore, 'customers', uid, 'notes'),
            orderBy('timestamp', 'desc'),
            limit(notesPerPage)
        );

        if (page > 1) {
            const startDoc = page > currentPage ? lastDoc : firstDoc;
            notesQuery = page > currentPage
                ? query(notesQuery, startAfter(startDoc))
                : query(notesQuery, startAt(startDoc));
        }

        const notesSnapshot = await getDocs(notesQuery);
        const notesData = notesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp.toDate(),
            audioLink: doc.data().audio
        })).filter(note => !note.delete);

        setLastDoc(notesSnapshot.docs[notesSnapshot.docs.length - 1]);
        setFirstDoc(notesSnapshot.docs[0]);
        setNotes(notesData);

        const grouped = {};
        notesData.forEach(note => {
            const dateKey = note.timestamp.toLocaleDateString('en-GB');
            if (!grouped[dateKey]) {
                grouped[dateKey] = [];
            }
            grouped[dateKey].push(note);
        });
        setGroupedNotes(grouped);
        setCurrentPage(page);
        setIsLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        const fetchTotalPages = async () => {
            const uid = auth.currentUser.uid;
            const notesCollectionRef = collection(firestore, 'customers', uid, 'notes');
            const snapshot = await getCountFromServer(notesCollectionRef);
            setTotalPages(Math.ceil(snapshot.data().count / notesPerPage));
        };

        fetchTotalPages();
        fetchNotes();
    }, []);

    const handleNoteClick = (note, event) => {
        if (event.target.closest('.delete-note-button')) {
            return;
        }
        event.stopPropagation();
        setSelectedNote(note);
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleClosePopup = (event) => {
        event.stopPropagation();
        setSelectedNote(null);
    };

    const handleOverlayClick = () => {
        setSelectedNote(null);
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    };

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
        formattedText = formattedText.replace(/##/g, '');
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };

    const containsReferral = selectedNote ? /referr(al|er)/i.test(selectedNote.content) : false;

    const filteredNotes = Object.entries(groupedNotes).reduce((acc, [date, notes]) => {
        const filtered = notes.filter(note => {
            const formattedDate = formatDate(date).toLowerCase();
            const formattedTime = formatTime(note.timestamp).toLowerCase();
            return (
                note.title.toLowerCase().includes(searchTerm) ||
                (note.content && note.content.toLowerCase().includes(searchTerm)) ||
                (note.patientId && note.patientId.toLowerCase().includes(searchTerm)) ||
                formattedDate.includes(searchTerm) ||
                formattedTime.includes(searchTerm)
            );
        });
        if (filtered.length > 0) {
            acc[date] = filtered;
        }
        return acc;
    }, {});

    const handleDeleteNote = async (event, noteId) => {
        event.stopPropagation();
        if (window.confirm("Are you sure you want to delete this note? This can't be undone.")) {
            const uid = auth.currentUser.uid;
            const noteRef = doc(firestore, 'customers', uid, 'notes', noteId);
            const deletedNoteRef = doc(collection(firestore, 'deletedNotes'));

            try {
                const noteSnap = await getDoc(noteRef);
                if (noteSnap.exists()) {
                    await setDoc(deletedNoteRef, noteSnap.data());
                    await deleteDoc(noteRef);
                    fetchNotes(currentPage);
                }
            } catch (error) {
                console.error('Error deleting note:', error);
            }
        }
    };

    const handleLoadTreatmentPlanner = (noteId) => {
        navigate(`/mvp/treatmentplanner/${noteId}`);
    };

    const handleDownloadAudio = async (downloadURL) => {
        if (downloadURL) {
            try {
                const response = await fetch(downloadURL);
                if (!response.ok) throw new Error('Network response was not ok.');
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `audio_recording_${new Date().toISOString()}.webm`;
                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            fetchNotes(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (currentPage === 1) {
            endPage = Math.min(5, totalPages);
        } else if (currentPage === totalPages) {
            startPage = Math.max(1, totalPages - 4);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i}>
                    <button
                        onClick={() => handlePageChange(i)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight ${
                            currentPage === i
                                ? 'text-primary-600 border border-primary-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700'
                                : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
        {/* <div className="bg-primary-50 border border-primary-500 text-gray-700 px-4 py-3 rounded-lg relative tracking-tighter text-sm mb-8 w-3/5">
        <strong className="font-bold">Letters not working? </strong>
        <span className="block sm:inline">
          We recently upgraded our letters to include images and letterheads. You can add these in Customise > Custom Forms, and in the Profile section.
          <p className="mt-2">
            There may be a mismatch in how your browser processes the Word documents. If you encounter an error when trying to open the letters:
          </p>
          <p className="mt-2">
            Please clear your browser cache.
          </p>
        </span>
      </div> */}

            <div className="flex items-center w-1/5 mx-auto justify-center mb-4">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-primary-500 focus:border-primary-500"
                />
            </div>

            {isLoading ? <CircleLoader /> : (
                <div className="overflow-x-auto w-3/5 text-sm text-left text-gray-500">
                    {Object.entries(filteredNotes).map(([date, notes]) => (
                        <React.Fragment key={date}>
                            <div className="text-xs text-gray-600 rounded-lg uppercase bg-gradient-to-r from-primary-50 to-primary-100 p-4">
                                {formatDate(date)}
                            </div>
                            {notes.map(note => (
                                <div key={note.id} className="grid grid-cols-4 border-b p-2 cursor-pointer hover:text-white hover:bg-primary-200" onClick={(e) => handleNoteClick(note, e)}>
                                    <div>{formatTime(note.timestamp)}</div>
                                    <div>{note.patientId}</div>
                                    <div>{note.title}</div>
                                    <button className="text-primary-400 hover:animate-pulse" onClick={(e) => handleDeleteNote(e, note.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            )}

            <nav aria-label="Page navigation example" className="mt-4">
                <ul className="flex items-center -space-x-px h-8 text-sm">
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                        >
                            <span className="sr-only">Previous</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </button>
                    </li>
                    {renderPageNumbers()}
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                        >
                            <span className="sr-only">Next</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>

            {selectedNote && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4 text-sm tracking-tighter" onClick={handleOverlayClick}>
                    <div className="relative bg-white shadow-lg mx-auto w-3/5 p-4 flex flex-col items-center rounded-xl" onClick={(e) => e.stopPropagation()}>
                    {(selectedNote.title === "Audio Recording - Jazmode" ||
                        selectedNote.title === "Audio Recording - Platypus" ||
                        selectedNote.title.includes("Audio Recording")) ? (
                        <div className="noteslistta">
                                <a
                                    href={selectedNote.content}
                                    download={`audio_recording_${new Date().toISOString()}.webm`}
                                    className="download-audio-button"
                                >
                                    Right-click here (or control-click on Mac) and choose 'Save link as...' to download the audio transcript
                                </a>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full">
                                <textarea className="bg-gray-50 p-2 rounded-lg mb-4 border" value={selectedNote.transcript} rows="5" readOnly></textarea>
                                <textarea className="bg-gray-50 p-2 rounded-lg mb-4 border" value={removeDoubleAsterisks(selectedNote.content)} rows="25" readOnly></textarea>
                                {selectedNote.content2 && (
                                    <textarea value={removeDoubleAsterisks(selectedNote.content2)} readOnly></textarea>
                                )}
                            </div>
                        )}
                        <div className="flex gap-2">
                            {selectedNote.title === "Exam Notes" && (
                                <button
                                    className="ml-2 px-2 py-1 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
                                    onClick={() => handleLoadTreatmentPlanner(selectedNote.id)}
                                >
                                    Re-Load Exam
                                </button>
                            )}
                            <CopyButton textToCopy={removeDoubleAsterisks(selectedNote.content)} />
                            {selectedNote.content2 && (
                                <CopyButton textToCopy={removeDoubleAsterisks(selectedNote.content2)} buttonText="Copy #2" />
                            )}
                            <div>
                                <PatientLetters noteContent={removeDoubleAsterisks(selectedNote.content)} />
                                {containsReferral && (
                                    <ReferralLetters noteContent={removeDoubleAsterisks(selectedNote.content)} />
                                )}
                                <ReferralResponseLetter noteContent={removeDoubleAsterisks(selectedNote.content)} />
                            </div>
                            <MolarMailerConsent className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" noteContent={removeDoubleAsterisks(selectedNote.content)} patientId={selectedNote.patientId} />
                            <CreateMolarMailButton
                                className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
                                clinicalRecordText={removeDoubleAsterisks(selectedNote.content)}
                                teethData={selectedNote.teethData}
                                treatmentPlanData={selectedNote.treatmentPlanData}
                            />
                            <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" onClick={() => selectNote(selectedNote)}>
                                Edit Note
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export default NotesList;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarSection from './SidebarSection'; // New Component for Collapsible Sections
import SidebarLink from './SidebarLink'; // New Component for Individual Links
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faPuzzlePiece,
  faStethoscope,
  faClipboardList,
  faTeeth,
  faBars,
  faJedi,
  faMailBulk,
  faEnvelope,
  faFileAlt,
  faUser,
  faCog,
  faWrench,
  faExchangeAlt,
  faPiggyBank,
  faCircleQuestion,
  faTools,
  faVideo,
  faFileSignature,
  faCogs,
  faBook,
  faBoltLightning,
  faPen,
  faLifeRing
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Sidebar = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState('');

  const handleLogout = () => {
    navigate('/logged-out');
  };

  const openDocumentation = () => {
    window.open('https://digitaltco.co.uk/docs', '_blank');
  };

  useEffect(() => {
    const countdown = () => {
      const targetDate = new Date('2024-07-25T13:15:00');
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeLeft('Launched!');
        return;
      }

      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      setTimeLeft(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    };

    const timer = setInterval(countdown, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
<div className="antialiased bg-gray-50 text-sm">
  <Tooltip 
      id="legacyTip" 
      place="right" 
      effect="solid" 
      content="Legacy Features - We recommend using 'Exam Notes' for all exams—whether you're live recording or not. It's cleaner, better, and more efficient." 
      style={{
          backgroundColor: '#cd66ef', // Use your primary color with opacity
          color: '#fff',
          maxWidth: '15%',
          zIndex: 9999
      }} 
  />
  <aside className="fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 shadow-">
    <div className="overflow-y-auto py-6 px-4 h-full bg-white tracking-tight flex flex-col">
      <ul className="space-y-2">
        <SidebarLink to="/mvp/notes-list" icon={faBars} label="Notes List" />
        <SidebarLink to="/mvp/templateexchange" icon={faExchangeAlt} label="Template Exchange" />

        <hr className="" />
        <div className="sidebar-link-main space-y-2">
          <SidebarLink to="/mvp/super-dentist-cheat-mode" icon={faBoltLightning} label="Super Dentist Cheat Mode" />
          <SidebarLink to="/mvp/treatmentplanner" icon={faPen} label="Exam Notes" />
          <SidebarLink to="/mvp/tx-notes" icon={faTeeth} label="Treatment Notes" />
        </div>

        <hr />

        {/* Invisible wrapper for Joyride */}
        <span className="sidebar-link-aux space-y-2" data-tooltip-id="legacyTip">
          <SidebarSection title="Post-Appt Tools" icon={faFileSignature} >
            <SidebarLink to="/mvp/basicexam" icon={faStethoscope} label="Smart Exam" />
            <SidebarLink to="/mvp/examsandconsults" icon={faClipboardList} label="The Exam Suite" />
          </SidebarSection>

          <SidebarSection title="Real-Time Recording" icon={faVideo} >
            <SidebarLink to="/mvp/jazmode" icon={faJedi} label="Jazmode" />
          </SidebarSection>
        </span>

        <SidebarSection title="Communication Hub" icon={faTools}>
          <SidebarLink to="/mvp/molarmail" icon={faMailBulk} label="Molar Mail" />
          <SidebarLink to="/mvp/cariespondence" icon={faEnvelope} label="Cariespondence" />
          <SidebarLink to="/mvp/consent-forms" icon={faFileAlt} label="Custom Forms" />
        </SidebarSection>

        <hr />

        <span className="sidebar-link-setiings flex flex-col" style={{ display: 'contents' }}>
          <SidebarSection title="Customise" icon={faCogs}>
            <SidebarLink to="/mvp/profile" icon={faUser} label="Profile" />
            <SidebarLink to="/mvp/settings" icon={faCog} label="Your Templates" />
            <SidebarLink to="/mvp/templatefixer" icon={faWrench} label="Template Fixer" />
            <hr />
            <SidebarLink to="/mvp/risk-bank" icon={faPiggyBank} label="Spiel Store" />
          </SidebarSection>
        </span>

        <div className="sidebar-link-learn">
          <SidebarSection title="Knowledge Center" icon={faBook}>
            <SidebarLink to="/mvp/knowledgevault" icon={faCircleQuestion} label="Knowledge Vault" />
            <li>
              <button
                onClick={openDocumentation}
                className="flex items-center p-2 text-sm font-normal text-gray-400 hover:text-gray-700 rounded-lg hover:bg-primary-100 group w-full"
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className="w-6 h-6 text-primary-300 group-hover:text-primary-400 mr-3"
                />
                Documentation
              </button>
            </li>
          </SidebarSection>
        </div>
      </ul>

      <div className="mt-auto py-1 text-center">
          <button
            onClick={handleLogout}
            className="text-primary-400 bg-primary-100 py-2 px-4 text-xs rounded-lg hover:bg-gray-400 hover:text-white focus:outline-none focus:shadow-outline"
          >
            <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
          </button>

          <button
            onClick={() => window.location.href = 'mailto:kevin@digitaltco.co.uk?subject=Support'}
            className="text-gray-400 bg-gray-200 py-2 px-4 text-xs rounded-lg ml-2 hover:bg-primary-200 hover:text-white focus:outline-none focus:shadow-outline"
          >
            <FontAwesomeIcon icon={faLifeRing} /> Support
          </button>
      </div>
    </div>
  </aside>
</div>
  );
};

export default Sidebar;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getMetadata } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane, faStop } from '@fortawesome/free-solid-svg-icons';
import './ExamsAndConsultsPage.css';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import LoadingPopup2 from  '../popup/LoadingPopup2';
import CopyButton from '../CopyButton';
import { getDownloadURL } from 'firebase/storage';
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';




  
const VoiceChartPage = () => {
    const [noteTemplate, setNoteTemplate] = useState('');
    const [generatedNote, setGeneratedNote] = useState('');
    const { treatmentId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [templates, setTemplates] = useState({});
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const templateRef = useRef(null);
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const globalNoteRef = useRef(null);
    const [showLoadingPopup2, setShowLoadingPopup2] = useState(false);


    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };
  
    const compressAudio = async (filePath) => {
        console.log("File Path =", filePath);
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/compressAudio', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ filePath }),
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok during compression');
        }
      
        const data = await response.json();
        console.log('Compression response:', data);
        return data.outputPath; // Return the path of the compressed file
    };
    
 

    useEffect(() => {
        if (templateRef.current) {
            templateRef.current.style.height = 'auto'; // Reset height to compute the new height
            templateRef.current.style.height = templateRef.current.scrollHeight + 'px';
        }
    }, [noteTemplate]); // Depend on noteTemplate to update height when it changes  

    useEffect(() => {
        const loadTemplate = async () => {
            // Ensure the user is logged in and auth.currentUser is not null
            if (auth.currentUser) {
                const uid = auth.currentUser.uid; // Get the user ID from Firebase Auth
                let templateRef;
    
                // Check if treatmentId is one of the predefined treatments
                if (['Basic Exam'].includes(treatmentId)) {
                    templateRef = doc(firestore, 'customers', uid, 'templates', treatmentId);
                } else {
                    // If not, fetch from exam_templates collection
                    templateRef = doc(firestore, 'customers', uid, 'exam_templates', treatmentId);
                }
    
                const templateSnap = await getDoc(templateRef);
    
                if (templateSnap.exists()) {
                    setNoteTemplate(templateSnap.data().text);
                } else {
                    console.log(`No template found for ${treatmentId}`);
                    alert(`You need to add your ${treatmentId} template in settings.`);
                }
            } else {
                console.log('User not logged in');
            }
        };
    
        loadTemplate();
    }, [treatmentId]);
    
    
    const sendAudioToCloudFunction = async (filePath, downloadURL) => {
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/whisper', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ filePath }),
            });
            const result = await response.json();
            console.log('Received response:', result);
            setTranscribedNote(result.text);
            setIsRecording(false);
            setShowLoadingPopup2(false);

            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            await setDoc(noteRef, {
                title: `Voice Chart - ${treatmentId}`,
                content: '', // This will be updated later
                transcript: result.text,
                audio_recording: downloadURL,
                patientId: patientId,
                timestamp: new Date()
            });
            console.log('Note record ID:', noteRef.id);
            console.log('NoteRef after setDoc:', noteRef);
            globalNoteRef.current = noteRef;
            console.log("global note ref audio2cloudfunction:", globalNoteRef.current);
            console.log("global note ref ID audio2cloudfunction:", globalNoteRef.current.id);
        } catch (error) {
            console.error('Error sending audio URL:', error);
            setShowLoadingPopup2(false);
            alert("Error sending audio, download the audio and try again.")
        }
    };  

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const newMediaRecorder = new MediaRecorder(stream);
            console.log('MediaRecorder started');
            setCanStopRecording(true);
    
            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
    
            newMediaRecorder.onstop = async () => {
                console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                console.log('Audio blob created:', audioBlob.size);
    
                if (audioBlob.size > 0) {
                    const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                    const filePath = `recordings/${userUID}${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        console.log('Download URL:', downloadURL);
                        setDownloadUrl(downloadURL);
    
                        let finalFilePath = filePath;
                        if (audioBlob.size > 23000000) { // 23MB
                            console.log("File size exceeds 23MB, compressing...");
                            setShowLoadingPopup2(true);
                            finalFilePath = await compressAudio(filePath);
                        }
    
                        sendAudioToCloudFunction(finalFilePath, downloadURL);
                    });
                }
            };
    
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setCanStopRecording(false); // Disable the stopRecording button after recording stops
            setTranscribedNote("Loading...");
        }
    };

    useEffect(() => {
        if (audioData) {
            console.log('Audio Blob is set, now sending to cloud function');
            sendAudioToCloudFunction(audioData);
        }
    }, [audioData]);

    const saveNoteToFirestore = async (noteContent) => {
        const uid = auth.currentUser?.uid;
        if (!uid) {
            console.error('User is not authenticated');
            return;
        }
    
        const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
        try {
            await setDoc(noteRef, {
                title: treatmentId, // Use treatmentId as the title
                content: noteContent, // This is the generated text
                timestamp: new Date(),
                patientId: patientId, // Save patient ID
            });
            console.log(`Note for ${treatmentId} successfully saved to Firestore`);
        } catch (error) {
            console.error('Error saving note to Firestore:', error);
        }
    };
    
    
    const callGenerateNoteFunction = async (prompt) => {
        try {
          const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/voicechartv2', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          setGeneratedNote(responseData.text);

          console.log("global note ref:", globalNoteRef);
            
            if (globalNoteRef.current) {
                try {
                    await updateDoc(globalNoteRef.current, {
                        content: responseData.text
                    });
                    console.log("Document updated successfully");
                } catch (error) {
                    console.error("Error updating document: ", error);
                }
            }
        } catch (error) {
          console.error('Error generating note:', error);
          setShowLoadingPopup(false);
          setIsGenerating(false);
          alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsGenerating(false);
            setShowLoadingPopup(false);
          }
        }; 

        const handleGenerateNote = () => {
            setIsGenerating(true);
            setShowLoadingPopup(true);
    
            const prompt = `Exam Note Template: ${noteTemplate}
                            Dentist's Notes: ${transcribedNote}.`;

            callGenerateNoteFunction(prompt);
        }; 

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, ''); // Remove ###
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    

        const handleGeneratedNoteChange = (e) => {
            setGeneratedNote(e.target.value);
        };

        const containsReferral = /referr(al|er)/i.test(generatedNote);

        const handleCheckAudioQuality = () => {
            if (downloadUrl) {
                window.open(downloadUrl, '_blank');
            }
        };


        // const handleFileChange = async (event) => {
        //     const file = event.target.files[0];
        //     if (file) {
        //         console.log('File selected:', file.name);
        
        //         const filePath = `recordings/${Date.now()}-${file.name}`;
        //         const storageRef = ref(getStorage(), filePath);
        
        //         try {
        //             await uploadBytes(storageRef, file);
        //             const downloadURL = await getDownloadURL(storageRef);
        
        //             let finalFilePath = filePath;
        //             if (file.size > 23000000) { // 23MB
        //                 console.log("File size exceeds 23MB, compressing...");
        //                 setShowLoadingPopup2(true);
        //                 finalFilePath = await compressAudio(filePath);
        //             }
        
        //             sendAudioToCloudFunction(finalFilePath, downloadURL);
        //         } catch (error) {
        //             console.error('Error uploading file:', error);
        //         }
        //     }
        // };

    return (
        <div className="basic-exam-container">
            <h2 className="basic-exam-h2">Jaz Mode <sup>beta</sup> - {treatmentId}</h2>
            {showLoadingPopup && <LoadingPopup />}
            {showLoadingPopup2 && <LoadingPopup2 />}

            <div className="patient-id-input">
                <label htmlFor="patientId">Patient ID:</label>
                <input
                    type="text"
                    id="patientId"
                    value={patientId}
                    onChange={handlePatientIdChange}
                    placeholder="Enter Patient ID"
                />
            </div>
    
            <div className="basic-exam-content">
                <div className="left-column">
                    <div className="note-recording">
                    <textarea
                        ref={templateRef}
                        readOnly
                        value={noteTemplate}
                        placeholder={`${treatmentId} Template will be displayed here...`}
                    ></textarea>

                        <textarea
                            placeholder="Hit record..."
                            value={transcribedNote}
                            onChange={(e) => setTranscribedNote(e.target.value)}
                            readOnly={isRecording || transcribedNote === "Loading..."} // Make textarea readonly while recording or loading
                        ></textarea>
                        <div className="button-container">
                            <button
                                onClick={startRecording}
                                disabled={isRecording}
                                className={isRecording ? "recording-button active" : "recording-button"}
                            >
                                {isRecording ? "Recording..." : <FontAwesomeIcon icon={faMicrophone} />}
                            </button>
                            <button
                                onClick={stopRecording}
                                disabled={!canStopRecording}
                                className="stop-recording-button"
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </button>
                            {downloadUrl && <button onClick={handleCheckAudioQuality}>Check Audio Quality</button>}
                        </div>

                        {/* <input
                            type="file"
                            onChange={handleFileChange}
                            accept="audio/*"
                        /> */}

                    </div>
                    <button 
                        onClick={handleGenerateNote} 
                        disabled={isGenerating || isRecording}
                        className={`generate-note-button ${isGenerating ? 'loading' : ''}`}
                    >
                        {isGenerating ? 'Generating...' : <FontAwesomeIcon icon={faPaperPlane} />}
                    </button>
                </div>
    
                <div className="right-column">
                    <textarea 
                        value={removeDoubleAsterisks(generatedNote)}
                        placeholder="Generated notes will be displayed here..."
                        onChange={handleGeneratedNoteChange}
                    ></textarea>
                    <div className="button-container">
                        <CopyButton textToCopy={removeDoubleAsterisks(generatedNote)} />
                        {generatedNote && (
                            <PatientLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                        )}
                        {generatedNote && (
                            <ReferralResponseLetter noteContent={removeDoubleAsterisks(generatedNote)} />
                        )}
                        {containsReferral && (
                            <ReferralLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                        )}
                    </div>       
                </div>
            </div>
        </div>
    );  
};

export default VoiceChartPage;
import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { videos } from './videos';

const VideoPage = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [watched, setWatched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideo = async () => {
      console.log('Fetching video details...');
      const selectedVideo = videos.find(v => v.id === videoId);
      setVideo(selectedVideo);

      const user = auth.currentUser;
      if (user) {
        console.log('Fetching user video document...');
        const userVideoDoc = doc(firestore, 'customers', user.uid, 'knowledgevault', videoId);
        const userSnapshot = await getDoc(userVideoDoc);
        if (userSnapshot.exists()) {
          console.log('User video document exists:', userSnapshot.data());
          setWatched(userSnapshot.data().watched);
        } else {
          console.log('User video document does not exist.');
          setWatched(false);
        }
      }
    };

    fetchVideo();
  }, [videoId]);

  useEffect(() => {
    if (video) {
      const onYouTubeIframeAPIReady = () => {
        console.log('YouTube IFrame API ready.');
        const player = new window.YT.Player('player', {
          videoId: video.url,
          events: {
            onStateChange: onPlayerStateChange
          }
        });
      };

      const onPlayerStateChange = (event) => {
        console.log('Player state changed:', event.data);
        if (event.data === window.YT.PlayerState.ENDED) {
          console.log('Video ended. Marking as watched...');
          markAsWatched();
        }
      };

      // Check if the YouTube IFrame API is already loaded
      if (!window.YT) {
        console.log('Loading YouTube IFrame API...');
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Set the onYouTubeIframeAPIReady function
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      } else {
        // If the API is already loaded, call the function directly
        onYouTubeIframeAPIReady();
      }
    }
  }, [video]);

  const markAsWatched = async () => {
    const user = auth.currentUser;
    if (user) {
      console.log('Updating watched status in Firestore...');
      const userVideoDoc = doc(firestore, 'customers', user.uid, 'knowledgevault', videoId);
      await setDoc(userVideoDoc, { watched: true });
      setWatched(true);
      console.log('Watched status updated.');
    }
  };

  const goToNextVideo = () => {
    const currentIndex = videos.findIndex(v => v.id === videoId);
    const nextIndex = currentIndex + 1;
    if (nextIndex < videos.length) {
      navigate(`/mvp/knowledgevault/video/${videos[nextIndex].id}`);
    } else {
      console.log('No more videos.');
    }
  };

  if (!video) {
    return <div>Loading...</div>;
  }

  return (
    <main key={videoId} className="flex flex-col items-center tracking-tighter w-4/5 mx-auto">
      <Link to="/mvp/knowledgevault" className="text-primary-400 p-2 border border-primary-400 rounded-full px-4 hover:text-white hover:bg-primary-400 mb-4 block">
        Back to Knowledge Vault
      </Link>
      <h1 className="text-2xl font-bold">{video.title}</h1>
      <p>{video.description}</p>
      <div className="">
        <div id="player"></div>
      </div>
      {!watched ? (
        <button onClick={markAsWatched} className="mt-4 px-4 py-2 bg-gray-300 text-gray-500 rounded">
          Mark as Watched
        </button>
      ) : (
        <button onClick={goToNextVideo} className="mt-4 px-4 py-2 bg-malachite-500 text-white rounded">
          Next Video
        </button>
      )}
    </main>
  );
};

export default VideoPage;

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane, faStop, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import './TxNotesPage.css';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import CopyButton from '../CopyButton';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';
import AudioErrorModal from '../elements/AudioErrorModal';
import SilenceErrorModal from '../elements/SilenceErrorModal';
import AudioEditor from '../audioeditor/AudioEditor';



const DEFAULT_LA_TYPES = [
    '2% Lidocaine 2.2ml 1:80k adr', 
    '4% Articaine 2.2ml 1:100k adr', 
    '4% Articaine 2.2ml 1:200k adr', 
    '3% Citanest 2.2ml 0.54μg Octapressin', 
    'Scandonest 3% plain',
    '3% Mepivacaine 2.2ml',
    '0.5% Bupivacaine 2.2ml 1:200k adr', 
    '4% Prilocaine 2.2ml 1:200k felypressin', 
];


const RCT_STAGES = [
    'RCT-Complete', 
    'RCT-1st Stage', 
    'RCT-2nd Stage'
];
const BLEACHING_STAGES = [
    'Bleaching-UL Impressions',
    'Bleaching-Full Arch 3D Scans',
    'Bleaching-Fit',
    'Bleaching-Review',
    'Bleaching-Internal',
];
const INVISALIGN_STAGES = [
    'Invisalign-Consult',
    'Invisalign-Scan',
    'Invisalign-ClinCheck',
    'Invisalign-Fit',
    'Invisalign-Review',
    'Invisalign-Debond',
    'Invisalign-Retainer Fit'
  ];
const FIXEDORTHO_STAGES = [
    'FixedOrtho-Consult',
    'FixedOrtho-Scan',
    'FixedOrtho-Imps',
    'FixedOrtho-Emergency',
    'FixedOrtho-Fit',
    'FixedOrtho-Review',
    'FixedOrtho-Debond',
    'FixedOrtho-Retainer Fit'
  ];
const ENLIGHTEN_STAGES = [
    'Enlighten-Consult',
    'Enlighten-Scan',
    'Enlighten-Imps',
    'Enlighten-Fit',
    'Enlighten-InOffice',
    'Enlighten-ExtraGels',
    'Enlighten-Review',
  ];
const DENTURES_STAGES = [
    'Dentures-Imps',
    'Dentures-2nd Imps',
    'Dentures-Bite',
    'Dentures-2nd Imps & Bite',
    'Dentures-Try In',
    'Dentures-Retry',
    'Dentures-Fit',
    'Dentures-Ease'
];
const XLA_STAGES = [
    'XLA-Basic', 
    'XLA-Soft Tissue', 
    'XLA-Bone Removal', 
];
const IMPLANT_STAGES = [
    'Implant-Assessment',
    'Implant-Single Placement',
    'Implant-Multiple Placement',
    'Implant-Immediate Load',
    'Implant-All on 4',
    'Implant-Exposure',
    'Implant-Imp',
    'Implant-Scan',
    'Implant-SR Crown Fit',
    'Implant-SR Bridge Fit',
    'Implant-CR Crown Fit',
    'Implant-CR Bridge Fit',
    'Implant-Bone Aug',
    'Implant-Sinus Lift',
]
  
const TxNotesPage = () => {
    const [noteTemplate] = useState('');
    const [generatedNote, setGeneratedNote] = useState('');
    const [setSelectedTeeth] = useState([]);
    const [selectedDeciduousTeeth, setSelectedDeciduousTeeth] = useState([]);
    const [selectedPermanentTeeth, setSelectedPermanentTeeth] = useState([]);
    const [selectedComboDeciduousTeeth, setSelectedComboDeciduousTeeth] = useState([]);
    const [selectedComboPermanentTeeth, setSelectedComboPermanentTeeth] = useState([]);
    const { treatmentId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [anaesthetics, setAnaesthetics] = useState({});
    const [selectedAnaesthetic, setSelectedAnaesthetic] = useState('');
    const [selectedAnaestheticSite, setSelectedAnaestheticSite] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [selectedRCTStage, setSelectedRCTStage] = useState('');
    const [selectedBleachingStage, setSelectedBleachingStage] = useState('');
    const [selectedEnlightenStage, setSelectedEnlightenStage] = useState('');
    const [selectedInvisalignStage, setSelectedInvisalignStage] = useState('');
    const [selectedDenturesStage, setSelectedDenturesStage] = useState('');
    const [selectedXLAStage, setSelectedXLAStage] = useState('');
    const [selectedFixedOrthoStage, setSelectedFixedOrthoStage] = useState('');
    const [templates, setTemplates] = useState({});
    const [allTreatments, setAllTreatments] = useState([]);
    const [selectedImplantStage, setSelectedImplantStage] = useState('');
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [treatments, setTreatments] = useState([]);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [filteredTreatments, setFilteredTreatments] = useState([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const [showCombinedTreatmentTeethMap, setShowCombinedTreatmentTeethMap] = useState(false);
    const [selectedCombinedTreatmentTeeth, setSelectedCombinedTreatmentTeeth] = useState([]);
    const [selectedCombinedTreatment, setSelectedCombinedTreatment] = useState('');
    const searchInputRef = useRef(null);
    const [combinedTreatmentAnaesthetics, setCombinedTreatmentAnaesthetics] = useState({});
    const [selectedCombinedAnaesthetic, setSelectedCombinedAnaesthetic] = useState('');
    const [selectedCombinedAnaestheticSite, setSelectedCombinedAnaestheticSite] = useState('');
    const [showAdditionalLA, setShowAdditionalLA] = useState(false);
    const [selectedAdditionalLA, setSelectedAdditionalLA] = useState('');
    const [selectedAdditionalLASite, setSelectedAdditionalLASite] = useState('');
    const [isDeciduousChart, setIsDeciduousChart] = useState(false);
    const [patientId, setPatientId] = useState('');
    const [mode, setMode] = useState('normal');
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [nursesInput, setNursesInput] = useState('');
    const [chartingMethod, setChartingMethod] = useState('FDI'); // Default charting method
    const [isTemplateVisible, setIsTemplateVisible] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');
    const [isAudioErrorModalVisible, setIsAudioErrorModalVisible] = useState(false);
    const audioDetectedRef = useRef(false);
    const [analyserKey, setAnalyserKey] = useState(0);
    const [isSilenceErrorModalVisible, setIsSilenceErrorModalVisible] = useState(false);
    const [isAudioEditorVisible, setIsAudioEditorVisible] = useState(false);
    const [showEditAudioButton, setShowEditAudioButton] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');

    const hasRepetitiveWords = (transcript) => {
        const words = transcript.split(/\s+/);
        let repeatCount = 1;
        for (let i = 1; i < words.length; i++) {
            if (words[i] === words[i - 1]) {
                repeatCount++;
                if (repeatCount >= 4) { // Adjust the threshold as needed
                    return true;
                }
            } else {
                repeatCount = 1;
            }
        }
        return false;
    };

    const handleAudioEdited = async (editedAudioBlob) => {
        const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
        const filePath = `sdcm/${userUID}/${Date.now()}_edited.webm`;
        const storageRef = ref(getStorage(), filePath);
        await uploadBytes(storageRef, editedAudioBlob);
    
        const downloadURL = await getDownloadURL(storageRef);
    
        // Reprocess the audio
        sendAudioToCloudFunction(filePath, downloadURL);
    
        // Hide the audio editor
        setIsAudioEditorVisible(false);
    };  


    useEffect(() => {
        const fetchChartingMethod = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setChartingMethod(userDoc.data().chartingMethod || 'FDI'); // Load charting method from Firestore
                }
            }
        };
        fetchChartingMethod();
    }, []);

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };

    const toggleDeciduousChart = () => {
        setIsDeciduousChart(!isDeciduousChart);
        setSelectedTeeth([]); // Reset selected teeth when toggling charts
    };
  
    const handleCombinedTreatmentAnaestheticSelection = (type, isType) => {
        if (isType) {
            // Toggling anaesthetic type selection
            if (selectedCombinedAnaesthetic === type) {
                setSelectedCombinedAnaesthetic('');
                setCombinedTreatmentAnaesthetics(prev => ({
                    ...prev,
                    [selectedCombinedTreatment]: { ...prev[selectedCombinedTreatment], anaesthetic: '' }
                }));
            } else {
                setSelectedCombinedAnaesthetic(type);
                setCombinedTreatmentAnaesthetics(prev => ({
                    ...prev,
                    [selectedCombinedTreatment]: { ...prev[selectedCombinedTreatment], anaesthetic: type }
                }));
            }
        } else {
            // Toggling anaesthetic site selection
            if (selectedCombinedAnaestheticSite === type) {
                setSelectedCombinedAnaestheticSite('');
                setCombinedTreatmentAnaesthetics(prev => ({
                    ...prev,
                    [selectedCombinedTreatment]: { ...prev[selectedCombinedTreatment], site: '' }
                }));
            } else {
                setSelectedCombinedAnaestheticSite(type);
                setCombinedTreatmentAnaesthetics(prev => ({
                    ...prev,
                    [selectedCombinedTreatment]: { ...prev[selectedCombinedTreatment], site: type }
                }));
            }
        }
    };
    

    
    useEffect(() => {
        setTreatments(Object.keys(templates));
      }, [templates]);

    useEffect(() => {
        // Check if the search box is shown and the input reference is set
        if (showSearchBox && searchInputRef.current) {
            // Focus on the input element
            searchInputRef.current.focus();
        }
    }, [showSearchBox]);

    const handleSearch = (input) => {
        setSearchInput(input);
        if (input.trim() === '') {
            setFilteredTreatments([]);
        } else {
            const filtered = treatments.filter(treatment => 
                treatment.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredTreatments(filtered);
        }
    };
   
   
    const anaestheticSites = ['Inferior Dental Block', 'Long buccal', 'B infiltration', 'B/P infiltrations', 'B/L infiltrations', 'Akinosi Block', 'Gow Gates Block'];

    const toggleSelectedAnaesthetic = (type) => {
        setSelectedAnaesthetic(prevSelected => prevSelected === type ? '' : type);
    };

    const toggleSelectedAnaestheticSite = (site) => {
        setSelectedAnaestheticSite(prevSelected => prevSelected === site ? '' : site);
    };

    const fdiToothTextMap = {
        1: '18', 2: '17', 3: '16', 4: '15', 5: '14', 6: '13', 7: '12', 8: '11',
        9: '21', 10: '22', 11: '23', 12: '24', 13: '25', 14: '26', 15: '27', 16: '28',
        17: '48', 18: '47', 19: '46', 20: '45', 21: '44', 22: '43', 23: '42', 24: '41',
        25: '31', 26: '32', 27: '33', 28: '34', 29: '35', 30: '36', 31: '37', 32: '38',
    };
    
    const fdiDeciduousToothTextMap = {
        'Upper Right Primary Second Molar': '55', 
        'Upper Right Primary First Molar': '54', 
        'Upper Right Primary Canine': '53', 
        'Upper Right Primary Lateral Incisor': '52', 
        'Upper Right Primary Central Incisor': '51',
        'Upper Left Primary Central Incisor': '61', 
        'Upper Left Primary Lateral Incisor': '62', 
        'Upper Left Primary Canine': '63', 
        'Upper Left Primary First Molar': '64', 
        'Upper Left Primary Second Molar': '65',
        'Lower Right Primary Second Molar': '85', 
        'Lower Right Primary First Molar': '84', 
        'Lower Right Primary Canine': '83', 
        'Lower Right Primary Lateral Incisor': '82', 
        'Lower Right Primary Central Incisor': '81',
        'Lower Left Primary Central Incisor': '71', 
        'Lower Left Primary Lateral Incisor': '72', 
        'Lower Left Primary Canine': '73', 
        'Lower Left Primary First Molar': '74', 
        'Lower Left Primary Second Molar': '75',
    };
    
    const palmerToothTextMap = {
        1: 'UR8', 2: 'UR7', 3: 'UR6', 4: 'UR5', 5: 'UR4', 6: 'UR3', 7: 'UR2', 8: 'UR1',
        9: 'UL1', 10: 'UL2', 11: 'UL3', 12: 'UL4', 13: 'UL5', 14: 'UL6', 15: 'UL7', 16: 'UL8',
        17: 'LR8', 18: 'LR7', 19: 'LR6', 20: 'LR5', 21: 'LR4', 22: 'LR3', 23: 'LR2', 24: 'LR1',
        25: 'LL1', 26: 'LL2', 27: 'LL3', 28: 'LL4', 29: 'LL5', 30: 'LL6', 31: 'LL7', 32: 'LL8',
    };
    
    const palmerDeciduousToothTextMap = {
        'Upper Right Primary Second Molar': 'URE', 
        'Upper Right Primary First Molar': 'URD', 
        'Upper Right Primary Canine': 'URC', 
        'Upper Right Primary Lateral Incisor': 'URB', 
        'Upper Right Primary Central Incisor': 'URA',
        'Upper Left Primary Central Incisor': 'ULA', 
        'Upper Left Primary Lateral Incisor': 'ULB', 
        'Upper Left Primary Canine': 'ULC', 
        'Upper Left Primary First Molar': 'ULD', 
        'Upper Left Primary Second Molar': 'ULE',
        'Lower Right Primary Second Molar': 'LRE', 
        'Lower Right Primary First Molar': 'LRD', 
        'Lower Right Primary Canine': 'LRC', 
        'Lower Right Primary Lateral Incisor': 'LRB', 
        'Lower Right Primary Central Incisor': 'LRA',
        'Lower Left Primary Central Incisor': 'LLA', 
        'Lower Left Primary Lateral Incisor': 'LLB', 
        'Lower Left Primary Canine': 'LLC', 
        'Lower Left Primary First Molar': 'LLD', 
        'Lower Left Primary Second Molar': 'LLE',
    };
    
    const universalToothTextMap = {
        1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8',
        9: '9', 10: '10', 11: '11', 12: '12', 13: '13', 14: '14', 15: '15', 16: '16',
        17: '32', 18: '31', 19: '30', 20: '29', 21: '28', 22: '27', 23: '26', 24: '25',
        25: '24', 26: '23', 27: '22', 28: '21', 29: '20', 30: '19', 31: '18', 32: '17',
    };
    
    const universalDeciduousToothTextMap = {
        'Upper Right Primary Second Molar': 'A', 
        'Upper Right Primary First Molar': 'B', 
        'Upper Right Primary Canine': 'C', 
        'Upper Right Primary Lateral Incisor': 'D', 
        'Upper Right Primary Central Incisor': 'E',
        'Upper Left Primary Central Incisor': 'F', 
        'Upper Left Primary Lateral Incisor': 'G', 
        'Upper Left Primary Canine': 'H', 
        'Upper Left Primary First Molar': 'I', 
        'Upper Left Primary Second Molar': 'J',
        'Lower Right Primary Second Molar': 'T', 
        'Lower Right Primary First Molar': 'S', 
        'Lower Right Primary Canine': 'R', 
        'Lower Right Primary Lateral Incisor': 'Q', 
        'Lower Right Primary Central Incisor': 'P',
        'Lower Left Primary Central Incisor': 'O', 
        'Lower Left Primary Lateral Incisor': 'N', 
        'Lower Left Primary Canine': 'M', 
        'Lower Left Primary First Molar': 'L', 
        'Lower Left Primary Second Molar': 'K',
    };
     

    const toothChart = isDeciduousChart 
    ? (chartingMethod === 'FDI' 
        ? fdiDeciduousToothTextMap 
        : chartingMethod === 'Universal' 
        ? universalDeciduousToothTextMap 
        : palmerDeciduousToothTextMap)
    : (chartingMethod === 'FDI' 
        ? fdiToothTextMap 
        : chartingMethod === 'Universal' 
        ? universalToothTextMap 
        : palmerToothTextMap);

    const toothKeys = Object.keys(toothChart);
    

    const handleToothClick = (tooth) => {
        if (isDeciduousChart) {
            setSelectedDeciduousTeeth(prevSelected => {
                const isToothSelected = prevSelected.includes(tooth);
                if (isToothSelected) {
                    return prevSelected.filter(t => t !== tooth);
                } else {
                    return [...prevSelected, tooth];
                }
            });
        } else {
            setSelectedPermanentTeeth(prevSelected => {
                const isToothSelected = prevSelected.includes(tooth);
                if (isToothSelected) {
                    return prevSelected.filter(t => t !== tooth);
                } else {
                    return [...prevSelected, tooth];
                }
            });
        }
    };
    

    useEffect(() => {
        const loadTemplates = async () => {
            const uid = auth.currentUser.uid;
            const loadedTemplates = {};
    
            const templatesRef = collection(firestore, 'customers', uid, 'templates');
            const querySnapshot = await getDocs(templatesRef);
  
            querySnapshot.forEach((doc) => {
                loadedTemplates[doc.id] = doc.data().text;
            });
    
            setTemplates(loadedTemplates);
            setAllTreatments(Object.keys(loadedTemplates));
        };
    
        loadTemplates();
    }, []);
    
    
    useEffect(() => {
        const loadAnaesthetics = async () => {
          try {
            const uid = auth.currentUser.uid;
            const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
            const anaestheticSnap = await getDoc(anaestheticRef);
    
            if (anaestheticSnap.exists()) {
              setAnaesthetics(anaestheticSnap.data());
            } else {
              // console.log('No anaesthetic data found');
            }
          } catch (error) {
            console.error('Error loading anaesthetics:', error);
          }
        };
    
        loadAnaesthetics();
      }, []);

    const sendAudioToCloudFunction = async (filePath) => {
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }

            const result = await response.json();
            // console.log('Received response:', result);
            setTranscribedNote(result.text);

            if (hasRepetitiveWords(result.text)) {
                setIsSilenceErrorModalVisible(true);
                setShowEditAudioButton(true);
            }

            setIsRecording(false);
        } catch (error) {
            console.error('Error sending audio URL:', error);
        }
    };    

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            // console.log('MediaRecorder started');
            setCanStopRecording(true);

            audioDetectedRef.current = false;
            setAnalyserKey(prevKey => prevKey + 1);


            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            newMediaRecorder.onstop = async () => {
                // console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                // console.log('Audio blob created:', audioBlob.size);

                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const filePath = `txnotes/${uid}/${treatmentId}-${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    // Send the file path to the cloud function
                    sendAudioToCloudFunction(filePath);
                }
            };

            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setTranscribedNote("Loading...");
    
            // Check if any audio was detected during the recording
            if (!audioDetectedRef.current) {
                setIsAudioErrorModalVisible(true); // Show the error modal
                setTranscribedNote('');
                return;
            }
        }
    };
    

    // useEffect(() => {
    //     if (audioData) {
    //         // console.log('Audio Blob is set, now sending to cloud function');
    //         sendAudioToCloudFunction(audioData);
    //     }
    // }, [audioData]);

    const saveNoteToFirestore = async (noteTitle, noteContent) => {
        const uid = auth.currentUser.uid;
        const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
        try {
            await setDoc(noteRef, {
                title: noteTitle,
                content: noteContent,
                timestamp: new Date(),
                patientId: patientId,
            });
            // console.log('Note saved to Firestore');
        } catch (error) {
            console.error('Error saving note to Firestore:', error);
        }
    };

    const callGenerateNoteFunction = async (prompt) => {
        const endpoint = mode === 'normal' ? 'txNote' : 'txNoteSimple';
        try {
          const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          setGeneratedNote(responseData.text);
          saveNoteToFirestore(treatmentId, responseData.text);
        } catch (error) {
          console.error('Error generating note:', error);
          setShowLoadingPopup(false);
          setIsGenerating(false);
          alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsGenerating(false);
            setShowLoadingPopup(false);
          }
        }; 

        const handleGenerateNote = () => {
            const isStageRequired = [
                'RCT', 'Bleaching', 'Invisalign', 
                'Fixed Ortho', 'Enlighten', 'Dentures', 
                'XLA', 'Implant'
            ].includes(treatmentId);
            
            const isStageSelected = selectedRCTStage || selectedBleachingStage || 
                                    selectedEnlightenStage || selectedInvisalignStage || 
                                    selectedDenturesStage || selectedXLAStage || 
                                    selectedFixedOrthoStage || selectedImplantStage;
        
            if (isStageRequired && !isStageSelected) {
                alert('Please select a stage before generating the note.');
                return; // Prevent further execution
            }
        
            setIsGenerating(true);
            setShowLoadingPopup(true);
        
            // console.log("Selected Deciduous Teeth for Combined Treatment:", selectedComboDeciduousTeeth);
            // console.log("Selected Permanent Teeth for Combined Treatment:", selectedComboPermanentTeeth);
        
            // Determine the correct tooth chart mapping for the main treatment
            const mainTreatmentDeciduousTeeth = selectedDeciduousTeeth
                .map(tooth => toothChart[tooth])
                .join(', ');
        
            const mainTreatmentPermanentTeeth = selectedPermanentTeeth
                .map(tooth => toothChart[tooth])
                .join(', ');
        
            // Combine all teeth for main treatment
            const mainTreatmentTeeth = [mainTreatmentDeciduousTeeth, mainTreatmentPermanentTeeth]
                .filter(text => text)
                .join(', ');
        
            // console.log("Main Treatment Teeth: ", mainTreatmentTeeth);
        
            // Determine the correct tooth chart mapping for the combined treatment
            const combinedTreatmentDeciduousTeeth = selectedComboDeciduousTeeth
                .map(tooth => toothChart[tooth])
                .join(', ');
        
            const combinedTreatmentPermanentTeeth = selectedComboPermanentTeeth
                .map(tooth => toothChart[tooth])
                .join(', ');
        
            // Combine all teeth for combined treatment
            const combinedTreatmentTeeth = [combinedTreatmentDeciduousTeeth, combinedTreatmentPermanentTeeth]
                .filter(text => text)
                .join(', ');
        
            // console.log("Combined Treatment Teeth: ", combinedTreatmentTeeth);
        

        const isLASettingsProvided = anaesthetics[selectedAnaesthetic]?.percentage && anaesthetics[selectedAnaesthetic]?.concentration && anaesthetics[selectedAnaesthetic]?.volume && anaesthetics[selectedAnaesthetic]?.batchNo && anaesthetics[selectedAnaesthetic]?.expiry;
        const anaestheticInfo = selectedAnaesthetic 
            ? isLASettingsProvided
                ? `LA: ${anaesthetics[selectedAnaesthetic]?.percentage}% ${selectedAnaesthetic}, ${anaesthetics[selectedAnaesthetic]?.concentration}, Volume: ${anaesthetics[selectedAnaesthetic]?.volume} ml, Batch: ${anaesthetics[selectedAnaesthetic]?.batchNo}, Expiry: ${anaesthetics[selectedAnaesthetic]?.expiry}`
                : `LA: ${selectedAnaesthetic}`
                : 'No LA used';

        const siteInfo = selectedAnaestheticSite ? `Site: ${selectedAnaestheticSite}` : '';

        const additionalLAInfo = selectedAdditionalLA 
            ? `Additional LA: ${selectedAdditionalLA}${selectedAdditionalLASite ? ', Site: ' + selectedAdditionalLASite : ''}`
            : '';
        
        let templateKey = treatmentId;

        switch (treatmentId) {
            case 'RCT':
                templateKey = selectedRCTStage ? selectedRCTStage : treatmentId;
                break;
            case 'Dentures':
                templateKey = selectedDenturesStage ? selectedDenturesStage : treatmentId;
                break;
            case 'Bleaching':
                templateKey = selectedBleachingStage ? selectedBleachingStage : treatmentId;
                break;
            case 'Enlighten':
                templateKey = selectedEnlightenStage ? selectedEnlightenStage : treatmentId; // Add this line
                break;
            case 'XLA':
                templateKey = selectedXLAStage ? selectedXLAStage : treatmentId;
                break;
            case 'Invisalign':
                templateKey = selectedInvisalignStage ? selectedInvisalignStage : treatmentId;
                break;
            case 'Fixed Ortho':
                templateKey = selectedFixedOrthoStage ? selectedFixedOrthoStage : treatmentId;
                break;
            case 'Implant':
                templateKey = selectedImplantStage ? selectedImplantStage : treatmentId; // Add this line
                break;
            // ... [Other cases as needed]
        }


        let templateForPrompt = templates[templateKey] || noteTemplate;
        // console.log(templateForPrompt);

        let combinedTreatmentAnaestheticInfo = '';
        if (selectedCombinedTreatment) {
            const anaesthetic = combinedTreatmentAnaesthetics[selectedCombinedTreatment]?.anaesthetic;
            const site = combinedTreatmentAnaesthetics[selectedCombinedTreatment]?.site;

            // Assuming anaesthetics state has similar structure for each anaesthetic type
            const isLACombinedSettingsProvided = anaesthetic && anaesthetics[anaesthetic]?.percentage && anaesthetics[anaesthetic]?.concentration && anaesthetics[anaesthetic]?.volume && anaesthetics[anaesthetic]?.batchNo && anaesthetics[anaesthetic]?.expiry;
            combinedTreatmentAnaestheticInfo = isLACombinedSettingsProvided 
            ? `LA for additional treatment: ${anaesthetics[anaesthetic]?.percentage}% ${anaesthetic}, ${anaesthetics[anaesthetic]?.concentration}, Volume: ${anaesthetics[anaesthetic]?.volume} ml, Batch: ${anaesthetics[anaesthetic]?.batchNo}, Expiry: ${anaesthetics[anaesthetic]?.expiry}, Site: ${site || 'Not specified'}`
            : `LA for additional treatment: ${anaesthetic || 'Not specified'}, Site: ${site || 'Not specified'}`;
        }

         // Construct the combined treatment prompt including the anaesthetic information
    let combinedTreatmentPrompt = '';
    if (selectedCombinedTreatment) {
        combinedTreatmentPrompt = `\n\nWe also carried out this treatment. Integrate it so we have one complete note - ${selectedCombinedTreatment}:\n${templates[selectedCombinedTreatment] || ''} \nTeeth: ${combinedTreatmentTeeth}\n LA:${combinedTreatmentAnaestheticInfo}`;
    }

    const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                    Dentist's Name: ${dentistName}.
                    Note Template: ${templateForPrompt}
                    Teeth treated today: ${mainTreatmentTeeth}.
                    Local Anesthetic: ${anaestheticInfo ? anaestheticInfo : 'None'}${siteInfo ? ', ' + siteInfo : ''}. 
                                        ${additionalLAInfo ? additionalLAInfo + '. ' : ''}. 
                                        
                    ${combinedTreatmentPrompt}.
                    Additional Information to be incorporated into the notes:
                    ${transcribedNote} ${nursesInput}.                         
                    Note: Modify the note to suit the specific treatment, including or excluding information as relevant to the treatment type. 
                    (For non-tooth specific treatments, use the 'Teeth treated' to indicate treatment sites or other relevant details eg. implant site, denture teeth etc).
                    `;

    callGenerateNoteFunction(prompt);
};
      
    const sortedAnaestheticTypes = Object.keys(anaesthetics).sort();
    const sortedAnaestheticSites = [...anaestheticSites].sort();

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, ''); // Remove ###
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    

    const laTypesToDisplay = Object.keys(anaesthetics).length > 0 ? Object.keys(anaesthetics).sort() : DEFAULT_LA_TYPES;

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault(); // Prevent the default action of the arrow down key
            setFocusedItemIndex(prev => 
                prev < filteredTreatments.length - 1 ? prev + 1 : prev
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault(); // Prevent the default action of the arrow up key
            setFocusedItemIndex(prev => 
                prev > 0 ? prev - 1 : 0
            );
        } else if (e.key === "Enter" && focusedItemIndex >= 0) {
            e.preventDefault(); // Prevent form submission
            selectTreatment(filteredTreatments[focusedItemIndex]);
        }
    };
    
    const selectTreatment = (treatment) => {
        setSelectedCombinedTreatment(treatment);
        setSearchInput(treatment);
        setShowSearchBox(false);
        setShowCombinedTreatmentTeethMap(true);
        setFocusedItemIndex(-1);
        if (searchInputRef.current) {
            searchInputRef.current.focus(); // Focus on the input element
        }
    };

    const handleCombinedTreatmentToothClick = (tooth) => {
        // console.log("Tooth clicked for combined treatment:", tooth);
        if (isDeciduousChart) {
            setSelectedComboDeciduousTeeth(prevSelected => {
                const isToothSelected = prevSelected.includes(tooth);
                const updatedSelected = isToothSelected ? prevSelected.filter(t => t !== tooth) : [...prevSelected, tooth];
    
                // console.log('Deciduous Teeth Selected for Combined Treatment:', updatedSelected);
                return updatedSelected;
            });
        } else {
            setSelectedComboPermanentTeeth(prevSelected => {
                const isToothSelected = prevSelected.includes(tooth);
                const updatedSelected = isToothSelected ? prevSelected.filter(t => t !== tooth) : [...prevSelected, tooth];
    
                // console.log('Permanent Teeth Selected for Combined Treatment:', updatedSelected);
                return updatedSelected;
            });
        }
    };
    
    
    
    const toggleAdditionalLA = (type) => {
        setSelectedAdditionalLA(prevSelected => {
            const newSelected = prevSelected === type ? '' : type;
            // console.log(`Additional LA selected: ${newSelected}`);
            return newSelected;
        });
    };
    
    const toggleAdditionalLASite = (site) => {
        setSelectedAdditionalLASite(prevSelected => {
            const newSelected = prevSelected === site ? '' : site;
            // console.log(`Additional LA Site selected: ${newSelected}`);
            return newSelected;
        });
    };   

    const handleAssistantResponseChange = (e) => {
        setGeneratedNote(e.target.value);
    };

    const containsReferral = /referr(al|er)/i.test(generatedNote);

    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            {showLoadingPopup && <LoadingPopup />}
            <AudioErrorModal 
                isVisible={isAudioErrorModalVisible} 
                onClose={() => setIsAudioErrorModalVisible(false)} 
            />
            <SilenceErrorModal 
                isVisible={isSilenceErrorModalVisible} 
                onClose={() => setIsSilenceErrorModalVisible(false)} 
                onEditAudio={() => {
                    setIsAudioEditorVisible(true);
                    setIsAudioErrorModalVisible(false);
                }}
            />

            {isAudioEditorVisible && (
                <AudioEditor
                    audioUrl={downloadUrl}
                    onSave={handleAudioEdited}
                    onCancel={() => setIsAudioEditorVisible(false)}
                />
            )}

            

            {/* TOP BAR */}
            <div className="flex items-center w-[65%] mx-auto justify-center">
                    <div className="pt-1.5 flex">
                    
                        <input
                            type="text"
                            id="patientId"
                            value={patientId}
                            onChange={handlePatientIdChange}
                            placeholder="Patient ID"
                            className="h-8 block px-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                            autoFocus
                        />
                        <div className="">
                        <button onClick={toggleDeciduousChart} className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200">
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {isDeciduousChart ? "Show Adult Teeth Chart" : "Show Deciduous Teeth Chart"}
                            </span>
                        </button>

                        <button 
                            onClick={() => setShowSearchBox(!showSearchBox)}
                            className={selectedCombinedTreatment ? "mt-1 ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 bg-gradient-to-br from-malachite-400 to-malachite-500 group group-hover:from-malachite-400 group-hover:to-malachite-500 hover:text-white overflow-hidden text-xs font-medium text-gray-900 rounded-lg " : "ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                            {selectedCombinedTreatment ? "Treatment Combo Initiated" : "Combo Tx"}
                            </span>
                        </button>
                        </div>
                    </div>
                {showSearchBox && (
                    <div>
                        <input
                            ref={searchInputRef}
                            type="text"
                            placeholder="Search for treatment"
                            value={searchInput}
                            onChange={(e) => handleSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="realtive block ml-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                            autoFocus
                        />
                        {filteredTreatments.length > 0 && (
                            <div className="absolute ml-4 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                                <ul className="py-2 text-sm text-gray-700 ">
                                    {filteredTreatments.map((treatment, index) => (
                                            <li
                                                key={treatment}
                                                className={focusedItemIndex === index ? "block px-4 py-2 bg-gray-50" : "block px-4 py-2 hover:cursor-pointer hover:bg-gray-50"}
                                                onClick={() => selectTreatment(treatment)}
                                                
                                            >
                                                {treatment}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>
                
                )}
            </div>


            {/* CHART */}
            {/* COMBO CHART AND LA */}
            <div className="flex w-100 flex-col items-center justify-center mt-8">
                {showCombinedTreatmentTeethMap && (
                    <div className="w-4/5">
                        <h3 className="block mb-2 text-xs text-primary-400">Teeth for {selectedCombinedTreatment}</h3>
                            <div className={`${isDeciduousChart ? 'grid gap-0 grid-cols-10 justify-center shadow-lg mb-8' : 'grid w-full gap-0 grid-cols-16 justify-center shadow-lg mb-8'}`}>
                                {isDeciduousChart ? (
                                    toothKeys.slice(0, 20).map(tooth => ( // Assuming the first 20 keys in toothKeys are for deciduous teeth
                                        <div
                                            key={tooth}
                                            className={`p-2 text-center bg-white text-gray-500 border border-gray-300 hover:text-white hover:bg-gradient-to-br hover:from-malachite-400 hover:to-malachite-500 hover:cursor-pointer ${selectedComboDeciduousTeeth.includes(tooth) ? 'bg-gradient-to-bl from-malachite-400 to-malachite-500 text-white' : ''}`}
                                            onClick={() => handleCombinedTreatmentToothClick(tooth)}
                                        >
                                            {toothChart[tooth]}
                                        </div>
                                    ))
                                ) : (
                                    toothKeys.map(tooth => (
                                        <div
                                            key={tooth}
                                            className={`p-2 text-center bg-white text-gray-500 border border-gray-300 hover:text-white hover:bg-gradient-to-br hover:from-malachite-400 hover:to-malachite-500 hover:cursor-pointer ${selectedComboPermanentTeeth.includes(tooth) ? 'bg-gradient-to-bl from-malachite-400 to-malachite-500 text-white' : ''}`}
                                            onClick={() => handleCombinedTreatmentToothClick(tooth)}
                                        >
                                            {toothChart[tooth]}
                                        </div>
                                    ))
                                )}
                            </div>

                            {/* Local Anaesthetic Selection for Combined Treatment */}
                            <div className="flex flex-wrap items-center justify-center mx-auto">
                                {laTypesToDisplay.map(type => (
                                    <button
                                    key={type}
                                    onClick={() => handleCombinedTreatmentAnaestheticSelection(type, true)}
                                    className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                        ${selectedCombinedAnaesthetic === type
                                            ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                            : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                        }`
                                    }
                                    >
                                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                            {type}
                                        </span>
                                    </button>
                                
                                ))}
                            </div>

                        
                            <div className="flex flex-wrap items-center justify-center mx-auto mt-4">
                                {sortedAnaestheticSites.map((site) => (
                                    <button
                                        key={site}
                                        onClick={() => handleCombinedTreatmentAnaestheticSelection(site, false)}
                                        className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-200
                                            ${selectedCombinedAnaestheticSite === site
                                                ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                                : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                            }`
                                        }
                                    >
                                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                            {site}
                                        </span>
                                    </button>
                                ))}
                            </div>
                    </div>  
                )}
            </div>    

            
            {/* REGULAR CHART & LA */}
            <div className="flex w-100 flex-col items-center justify-center">
                <div className="w-4/5">
                    <h3 className="block mb-2 text-xs text-primary-400">Teeth for {treatmentId}</h3>
                    <div className={`${isDeciduousChart ? 'grid gap-0 grid-cols-10 justify-center shadow-lg mb-8' : 'grid w-full gap-0 grid-cols-16 justify-center shadow-lg mb-8'}`}>
                        {isDeciduousChart ? (
                            toothKeys.slice(0, 20).map(tooth => ( // Assuming the first 20 keys in toothKeys are for deciduous teeth
                                <div
                                    key={tooth}
                                    className={`p-2 text-center bg-white text-gray-500 border border-gray-300 hover:text-white hover:bg-gradient-to-br hover:from-malachite-400 hover:to-malachite-500 hover:cursor-pointer ${selectedDeciduousTeeth.includes(tooth) ? 'bg-gradient-to-bl from-malachite-400 to-malachite-500 text-white' : ''}`}
                                    onClick={() => handleToothClick(tooth)}
                                >
                                    {toothChart[tooth]}
                                </div>
                            ))
                        ) : (
                            toothKeys.map(tooth => (
                                <div
                                    key={tooth}
                                    className={`p-2 text-center bg-white text-gray-500 border border-gray-300 hover:text-white hover:bg-gradient-to-br hover:from-malachite-400 hover:to-malachite-500 hover:cursor-pointer ${selectedPermanentTeeth.includes(tooth) ? 'bg-gradient-to-bl from-malachite-400 to-malachite-500 text-white' : ''}`}
                                    onClick={() => handleToothClick(tooth)}
                                >
                                    {toothChart[tooth]}
                                </div>
                            ))
                        )}
                    </div>               

                    <div className="flex flex-wrap items-center justify-center mx-auto">
                            {laTypesToDisplay.map(type => (
                                <button
                                    key={type}
                                    className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                                ${selectedAnaesthetic === type
                                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                                }`
                                            }
                                    onClick={() => toggleSelectedAnaesthetic(type)}
                                >
                                    <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                        {type}
                                    </span>
                                </button>
                            ))}
                    </div>
                
                    <div className="flex flex-wrap items-center justify-center mx-auto mt-4">
                        {sortedAnaestheticSites.map(site => (
                            <button
                                key={site}
                                className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-200
                                    ${selectedAnaestheticSite === site
                                        ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                        : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white "
                                    }`
                                }
                                onClick={() => toggleSelectedAnaestheticSite(site)}
                            >
                                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                    {site}
                                </span>
                            </button>
                        ))}
                    </div>

                    <div className="flex flex-wrap items-center justify-center mx-auto mt-4">
                            <button 
                            onClick={() => setShowAdditionalLA(!showAdditionalLA)}
                            className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
                            >
                                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                    {showAdditionalLA ? "Hide Additional LA" : "Additional LA"}
                                </span>
                            </button>
                            {showAdditionalLA && (
                                <>
                                    <div className="flex flex-wrap items-center justify-center mx-auto mt-4">
                                        {laTypesToDisplay.map((type) => (
                                            <button
                                                key={type}
                                                className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                                    ${selectedAdditionalLA === type
                                                        ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                                        : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                                    }`
                                                }
                                                onClick={() => toggleAdditionalLA(type)}
                                            >
                                                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                                    {type}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                    <div className="flex flex-wrap items-center justify-center mx-auto mt-4">
                                        {sortedAnaestheticSites.map((site) => (
                                            <button
                                                key={site}
                                                className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                                    ${selectedAdditionalLASite === site
                                                        ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                                        : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                                    }`
                                                }
                                                onClick={() => toggleAdditionalLASite(site)}
                                            >
                                                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                                    {site}
                                                </span>
                                            </button>
                                        ))}
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </div>

            {/* STAGE BUTTONS */}
            {treatmentId === 'RCT' && (
                <div className="rct-stages-buttons tx-notes-spacing">
                    {RCT_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedRCTStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                                onClick={() => setSelectedRCTStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Bleaching' && (
                <div className="bleaching-stages-buttons tx-notes-spacing">
                    {BLEACHING_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedBleachingStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedBleachingStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Enlighten' && (
                <div className="enlighten-stages-buttons tx-notes-spacing">
                    {ENLIGHTEN_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                            ${selectedEnlightenStage === stage
                                ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                            }`
                        }
                        onClick={() => setSelectedEnlightenStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Invisalign' && (
                <div className="invisalign-stages-buttons tx-notes-spacing">
                    {INVISALIGN_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedInvisalignStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedInvisalignStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Fixed Ortho' && (
                <div className="fixedortho-stages-buttons tx-notes-spacing">
                    {FIXEDORTHO_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedFixedOrthoStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedFixedOrthoStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Dentures' && (
                <div className="dentures-stages-buttons tx-notes-spacing">
                    {DENTURES_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedDenturesStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedDenturesStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'XLA' && (
                <div className="xla-stages-buttons tx-notes-spacing">
                    {XLA_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedXLAStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedXLAStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}
            {treatmentId === 'Implant' && (
                <div className="implant-stages-buttons tx-notes-spacing">
                    {IMPLANT_STAGES.map(stage => (
                        <button
                            key={stage}
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                                ${selectedImplantStage === stage
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={() => setSelectedImplantStage(stage)}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {stage.split('-')[1]}
                            </span>
                        </button>
                    ))}
                </div>
            )}


            {/* MAIN SECTION */}
            <div className="mt-2 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
            {/* LEFT INPUT COLUMN */}
            <div className="flex flex-col">
                {/* TOGGLE */}
                <div className="flex mx-auto">
                    <div className="mode-toggle-container text-center mt-2">
                        <label className="simple-toggle-label">
                            <input
                                type="checkbox"
                                checked={mode === 'simplified'}
                                onChange={() => setMode(mode === 'normal' ? 'simplified' : 'normal')}
                                className="simple-toggle-input" 
                            />
                            <span className="simple-toggle-slider"></span>
                        </label>
                        <span className="mode-label">{mode.charAt(0).toUpperCase() + mode.slice(1).toLowerCase()} Mode</span>
                    </div>
                    <button
                        onClick={() => setIsTemplateVisible(!isTemplateVisible)}
                        className="mt-1 ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200">
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {isTemplateVisible ? "Hide Template" : "Show Template"}
                            </span>
                    </button>
                </div>

                {isTemplateVisible && (
                    <div className="mt-4 p-4 border rounded-lg bg-gray-100">
                        <pre className="text-sm text-gray-700 whitespace-pre-wrap">
                            {/* Update this part to display the correct template based on selected stage */}
                            {(() => {
                                // Default to the treatment template
                                let templateKey = treatmentId;

                                // If the treatment has a stage, use the selected stage template
                                switch (treatmentId) {
                                    case 'RCT':
                                        templateKey = selectedRCTStage || treatmentId;
                                        break;
                                    case 'Dentures':
                                        templateKey = selectedDenturesStage || treatmentId;
                                        break;
                                    case 'Bleaching':
                                        templateKey = selectedBleachingStage || treatmentId;
                                        break;
                                    case 'Enlighten':
                                        templateKey = selectedEnlightenStage || treatmentId;
                                        break;
                                    case 'XLA':
                                        templateKey = selectedXLAStage || treatmentId;
                                        break;
                                    case 'Invisalign':
                                        templateKey = selectedInvisalignStage || treatmentId;
                                        break;
                                    case 'Fixed Ortho':
                                        templateKey = selectedFixedOrthoStage || treatmentId;
                                        break;
                                    case 'Implant':
                                        templateKey = selectedImplantStage || treatmentId;
                                        break;
                                    // Add more cases if necessary
                                }

                                return templates[templateKey] || "No template available for this treatment.";
                            })()}
                        </pre>
                    </div>
                )}

                <label className="mt-4 block mb-2 text-xs text-primary-400">Nurse's notes</label>
                <textarea
                    placeholder="Nurses Input"
                    value={nursesInput}
                    onChange={(e) => setNursesInput(e.target.value)}
                    className="block overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="5"
                ></textarea>

                <label className="mt-4 block mb-2 text-xs text-primary-400">Dentist's observations and notes</label>
                <textarea
                    placeholder="Deviations from your standard protocol..."
                    value={transcribedNote}
                    onChange={(e) => setTranscribedNote(e.target.value)}
                    readOnly={isRecording || transcribedNote === "Loading..."}
                    className="block overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="10"
                ></textarea>

                {mediaStream && (
                    <div className="active-audio-analyser-container">
                        <ActiveAudioAnalyser
                            key={analyserKey}
                            audioStream={mediaStream}
                            isPaused={isPaused}
                            onAudioDetected={() => {
                                audioDetectedRef.current = true;
                            }}
                        />
                    </div>
                )}

                {/* BUTTONS */}
                <div className="mx-auto mt-2">
                    <div className="inline-flex rounded-md shadow-sm" role="group">
                        <button 
                            onClick={startRecording} 
                            disabled={isRecording || isPaused}
                            className={`${isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                        >
                            <FontAwesomeIcon icon={faMicrophone} />
                        </button>

                        {isRecording && (
                            <button 
                                onClick={isPaused ? resumeRecording : pauseRecording}
                                className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            >
                                {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                            </button>
                        )}

                        <button 
                            onClick={stopRecording} 
                            disabled={!isRecording || isPaused}
                            className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                        >
                            <FontAwesomeIcon icon={faStop} />
                        </button>
            
                        <button 
                            onClick={handleGenerateNote} 
                            disabled={isGenerating || isRecording || isPaused}
                            className={`${isGenerating || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            </div>


            {/* RIGHT OUTPUT COLUMN */}
            <div className="mt-6 flex flex-col">
            
            <label className="block mb-2 text-xs text-primary-400">AI Output</label>

                    <textarea 
                        onChange={handleAssistantResponseChange} 
                        value={removeDoubleAsterisks(generatedNote)}
                        placeholder="Comprehensive notes will show here..."
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="25"
                    ></textarea>

                    <div className="flex flex-col items-center justify-center mx-auto mt-2">
                        <CopyButton textToCopy={removeDoubleAsterisks(generatedNote)} />
                        <div className="mx-auto mt-2">
                            {generatedNote && (
                                <PatientLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                            {generatedNote && (
                                <ReferralResponseLetter noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                            {containsReferral && (
                                <ReferralLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                        </div>
                    </div>    

                </div>
            </div>
        </main>
    );
};

export default TxNotesPage;
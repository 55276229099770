import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './txnotes.css';

// Define the treatments with stages
const treatmentsWithStages = {
    RCT: [
        'RCT-Complete', 
        'RCT-1st Stage', 
        'RCT-2nd Stage'
    ],
    Bleaching: [
        'Bleaching-UL Impressions',
        'Bleaching-Full Arch 3D Scans',
        'Bleaching-Fit',
        'Bleaching-Review',
        'Bleaching-Internal',
    ],
    Invisalign: [
        'Invisalign-Consult',
        'Invisalign-Scan',
        'Invisalign-ClinCheck',
        'Invisalign-Fit',
        'Invisalign-Review',
        'Invisalign-Debond',
        'Invisalign-Retainer Fit'
    ],
    FixedOrtho: [
        'FixedOrtho-Consult',
        'FixedOrtho-Scan',
        'FixedOrtho-Imps',
        'FixedOrtho-Emergency',
        'FixedOrtho-Fit',
        'FixedOrtho-Review',
        'FixedOrtho-Debond',
        'FixedOrtho-Retainer Fit'
    ],
    Enlighten: [
        'Enlighten-Consult',
        'Enlighten-Scan',
        'Enlighten-Imps',
        'Enlighten-Fit',
        'Enlighten-InOffice',
        'Enlighten-ExtraGels',
        'Enlighten-Review'
    ],
    Dentures: [
        'Dentures-Imps',
        'Dentures-2nd Imps',
        'Dentures-Bite',
        'Dentures-2nd Imps & Bite',
        'Dentures-Try In',
        'Dentures-Retry',
        'Dentures-Fit',
        'Dentures-Ease'
    ],
    XLA: [
        'XLA-Basic', 
        'XLA-Soft Tissue', 
        'XLA-Bone Removal'
    ],
    Implant: [
        'Implant-Assessment',
        'Implant-Single Placement',
        'Implant-Multiple Placement',
        'Implant-Immediate Load',
        'Implant-All on 4',
        'Implant-Exposure',
        'Implant-Imp',
        'Implant-Scan',
        'Implant-SR Crown Fit',
        'Implant-SR Bridge Fit',
        'Implant-CR Crown Fit',
        'Implant-CR Bridge Fit',
        'Implant-Bone Aug',
        'Implant-Sinus Lift'
    ]
};

const TxNotes = () => {
    const [selectedTreatments, setSelectedTreatments] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [additionalTreatments, setAdditionalTreatments] = useState({});

    useEffect(() => {
        const loadAdditionalTreatments = async () => {
            const uid = auth.currentUser.uid;
            const templatesRef = collection(firestore, 'customers', uid, 'templates');
            const querySnapshot = await getDocs(templatesRef);
    
            const firestoreTreatments = {};
            querySnapshot.forEach((doc) => {
                if (!['consentforms', 'xraysettings', 'anaesthetics', 'Basic Exam'].includes(doc.id)) {
                    firestoreTreatments[doc.id] = { label: doc.id };
                }
            });
    
            setAdditionalTreatments(firestoreTreatments);
        };
    
        loadAdditionalTreatments();
    }, []);
    
    const isTreatmentWithStages = (treatmentName) => {
        return Object.keys(treatmentsWithStages).some(treatment => treatmentName.startsWith(treatment));
    };

    const getUniqueTreatments = (firestoreTreatments) => {
        const uniqueTreatments = {};
    
        Object.keys(firestoreTreatments).forEach(treatmentName => {
            const stagedTreatment = Object.keys(treatmentsWithStages).find(treatment => 
                treatmentName.startsWith(treatment)
            );
    
            if (stagedTreatment) {
                if (!uniqueTreatments[stagedTreatment]) {
                    uniqueTreatments[stagedTreatment] = { label: stagedTreatment };
                }
            } else {
                uniqueTreatments[treatmentName] = { label: treatmentName };
            }
        });
    
        return uniqueTreatments;
    };    
    
    const allTreatments = getUniqueTreatments(additionalTreatments);

    const filteredTreatments = Object.entries(allTreatments).filter(([key]) => 
        key.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            <input
                type="text"
                placeholder="Search treatment name..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                autoFocus
            />
            <div className="grid grid-cols-6 gap-8 mt-8">
                {filteredTreatments.map(([key, value]) => (
                    <div key={key}>
                        <input
                            id={key}
                            type="checkbox"
                            className="hidden"
                        />
                        <label htmlFor={key} className="ml-8 w-full h-full text-center inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 shadow-lg">
                            <Link to={`/mvp/tx-notes/${key}`} className="w-full h-full flex items-center justify-center px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {value.label}
                            </Link>
                        </label>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default TxNotes;

import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, writeBatch, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';
import ShareTemplateButton from '../sharedtemplates/ShareTemplateButton';

const Templates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [searchInput, setSearchInput] = useState('');
  let treatments = Object.keys(templates); // Now dynamic, based on the templates object
  const [isTemplatesVisible, setIsTemplatesVisible] = useState(false);
  const textareaRef = useRef(null);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateContent, setNewTemplateContent] = useState('');

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const removeHyphens = (str) => {
    return str.replace(/-/g, ' ');
  };

  const saveNewTemplate = async () => {
    let trimmedTemplateName = newTemplateName.trim();
    trimmedTemplateName = removeHyphens(trimmedTemplateName);

    if (!trimmedTemplateName || !newTemplateContent.trim()) {
      alert("Please enter a valid template name and content.");
      return;
    }

    const uid = auth.currentUser.uid;
    const newTemplateRef = doc(firestore, 'customers', uid, 'templates', trimmedTemplateName);

    try {
      await setDoc(newTemplateRef, { text: newTemplateContent });
      alert('New template saved successfully');
      // Update the templates object and close the popup
      setTemplates({ ...templates, [trimmedTemplateName]: newTemplateContent });
      setNewTemplateName('');
      setNewTemplateContent('');
      togglePopup();
    } catch (error) {
      console.error('Error saving new template:', error);
    }
  };

  const toggleTemplatesVisibility = () => {
    setIsTemplatesVisible(!isTemplatesVisible);
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const uid = auth.currentUser.uid;
      const loadedTemplates = {};
  
      // Fetch all template documents from Firestore
      const templatesRef = collection(firestore, 'customers', uid, 'templates');
      const querySnapshot = await getDocs(templatesRef);
  
      querySnapshot.forEach((doc) => {
        // Add each template to the loadedTemplates object
        loadedTemplates[doc.id] = doc.data().text;
      });
  
      setTemplates(loadedTemplates);
    };
  
    loadTemplates();
  }, []);

  const handleTemplateSelection = (treatment) => {
    setSelectedTreatment(treatment);
    setSelectedTemplate(treatment); // Set the selectedTemplate to the treatment selected
  };

  const updateSelectedTemplate = (newTemplate) => {
    setSelectedTemplate(newTemplate);
    // Ensure the textarea is updated with the new template
    if (textareaRef.current) {
        textareaRef.current.focus();
    }
  };

  const handleSaveTemplate = async () => {
    if (!selectedTemplate) return;

    const uid = auth.currentUser.uid;
    const templateRef = doc(firestore, 'customers', uid, 'templates', selectedTemplate);
    try {
      await setDoc(templateRef, { text: templates[selectedTemplate] });
      alert('Template saved successfully');
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleSaveAllTemplates = async () => {
    const uid = auth.currentUser.uid;
    try {
      const batch = writeBatch(firestore);

      for (const [key, value] of Object.entries(templates)) {
        const templateRef = doc(firestore, 'customers', uid, 'templates', key);
        batch.set(templateRef, { text: value });
      }

      await batch.commit();
      alert('All templates saved successfully');
    } catch (error) {
      console.error('Error saving all templates:', error);
    }
  };

  const handleTextChange = (e) => {
    setTemplates({ ...templates, [selectedTemplate]: e.target.value });
  };

  const excludedTemplates = ['consentforms', 'anaesthetics', 'xraysettings'];

  const filteredTreatments = treatments.filter(treatment =>
      treatment.toLowerCase().includes(searchInput.toLowerCase()) &&
      !excludedTemplates.includes(treatment.toLowerCase())
  );

  const handleDeleteTemplate = async (templateName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the template "${templateName}"? This action cannot be undone.`);
    
    if (confirmDelete) {
      const uid = auth.currentUser.uid;
      const templateRef = doc(firestore, 'customers', uid, 'templates', templateName);

      try {
        await deleteDoc(templateRef);
        alert('Template deleted successfully');

        // Update local state to reflect the deletion
        const updatedTemplates = { ...templates };
        delete updatedTemplates[templateName];
        setTemplates(updatedTemplates);
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-full mx-auto">
       {/* TOP BAR */}
       <div className="flex items-center w-[65%] mx-auto justify-center my-4">
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="block w-2/5 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
            autoFocus
          />
          <button onClick={togglePopup} 
                    className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
                    >
            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
              Create New Treatment Template
            </span>
            </button>
      </div>

          {/* TREATMENT BUTTONS */}
          <div className="grid grid-cols-2 gap-4">
  {/* Treatment Buttons Area */}
  <div className="flex flex-wrap justify-center gap-4 mb-4 min-h-[200px]">
    {filteredTreatments.length > 0 ? (
      filteredTreatments.map((treatment) => (
        <button
          key={treatment}
          className={`${
            selectedTreatment === treatment
              ? 'p-2 bg-primary-400 text-white text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400'
              : 'p-2 bg-white text-gray-700 text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400'
          }`}
          onClick={() => handleTemplateSelection(treatment)}
        >
          {treatment}
        </button>
      ))
    ) : (
      <p className="text-sm text-gray-500">No treatments found.</p>
    )}
  </div>

  {/* Text Area */}
  <textarea
    className="block p-2.5 mb-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
    rows="20"
    value={templates[selectedTemplate] || ''}
    onChange={handleTextChange}
    placeholder="Type the template here..."
    ref={textareaRef}
    disabled={!selectedTreatment}
  />
</div>


          <div className="flex">
            <button
            className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
            onClick={handleSaveTemplate}>
              Save
            </button>
            <button 
            className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
            onClick={() => handleDeleteTemplate(selectedTemplate)} 
              disabled={!selectedTemplate}
            >
              Delete
            </button>
            <button
             className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
             onClick={handleSaveAllTemplates}>
              Save All
            </button>
            
          </div>
        
      

      {isPopupVisible && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4 text-sm tracking-tighter">
        <div className="relative bg-white shadow-lg mx-auto w-2/5 p-4 flex flex-col items-center rounded-xl gap-2">
          <input
            type="text"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
            placeholder="Treatment Name"
            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
          />
          <textarea
            value={newTemplateContent}
            onChange={(e) => setNewTemplateContent(e.target.value)}
            placeholder="Template Content"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            rows="30"
          />
          <div className="flex">
          <button 
          onClick={saveNewTemplate}
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
          >
          Save
          </button>

          <button 
          onClick={togglePopup}
          className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
          >
          Close
          </button>
          </div>
        </div>
        </div>
      )}
    </main>
  );
};

export default Templates;

import React, { useState, useEffect } from 'react';
import { doc, collection, addDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import ThreeDotsWave from './ThreeDotsWave';
import './LoadingPopup.css';

const LoadingPopup = () => {
  const [showNPSForm, setShowNPSForm] = useState(false);
  const [npsScore, setNpsScore] = useState(null);
  const [npsSubmitted, setNpsSubmitted] = useState(false);
  const [contentToShow, setContentToShow] = useState(null);

  const loadingMessages = [
    "Analyzing dental mysteries... Please hold the drill!",
    "Crunching numbers, not teeth. One moment, please!",
    "Compiling patient smiles. Just a sec!",
    "Polishing up your data... Shine takes time!",
    "Our tech is flossing through your request. Hang tight!",
    "Brewing up something great. Dentists deserve a coffee break too!",
    "Just like a root canal, good things take time. We're almost there!",
    "Aligning the braces of our code. Straightening things up!",
    "We're on it! Like plaque on teeth, but in a good way.",
    "Calculating the perfect bite. This won't take long!",
    "Extracting wisdom... from data, not teeth!",
    "Sterilizing bytes and bits. Safety first!",
    "Implanting code enhancements. Please stand by!",
    "Brushing up our algorithms. Just a moment!",
    "Filling cavities in our code. No anesthesia needed!",
    "Rinsing data, expect sparkling results shortly!",
    "Your request is scaling our server. Pearly whites in progress!",
    "Mixing the perfect shade of digital enamel. Patience, please!",
    "Dental humor loading... brace yourself!",
    "Checking gum lines and code lines. Almost done!",
    "Why did the king go to the dentist? To get his teeth crowned!",
    "What does a dentist call an astronaut's cavity? A black hole!",
    "Why did the dentist become a gardener? He wanted to get to the root of the problem!",
    "What did the tooth say to the departing dentist? Fill me in when you get back!",
    "Why did the computer go to the dentist? It had a Bluetooth problem!",
    "What do you call a dentist who doesn't like tea? Denis!",
    "Why was the dentist in Panama? To check out the Canal!",
    "What's a dentist's favorite dinosaur? A Flossoraptor!",
    "Why did the deer need braces? It had buck teeth!",
    "How do you fix a broken tooth? With toothpaste!",
    "Why did the tree go to the dentist? It needed a root canal!",
    "What do you call a bear with no teeth? A gummy bear!",
    "Why did the smartphone go to the dentist? It lost its Bluetooth!",
    "What do dentists call their x-rays? Tooth pics!",
    "What did the judge say to the dentist? Do you swear to pull the tooth, the whole tooth, and nothing but the tooth?",
    "Why did the termite eat the chair? It wanted to have a bite!",
    "What game did the dentist play when she was a child? Caps and robbers!",
    "Why did the dentist break up with the manicurist? They fought tooth and nail!",
    "What does the dentist of the year get? A little plaque!",
    "Why did the dentist become a baseball coach? He knows the drill!",
    "Decoding cavities. This might get a bit 'filling'!",
    "We're like orthodontists - straightening out the kinks in the code!",
    "Flossing through your files... No plaque left behind!",
    "Our servers are getting a wisdom tooth extraction. Almost there!",
    "Why did the tooth go to jail? For committing a 'molar' offense!",
    "What do you call a dentist’s advice? His 'floss-ophy'!",
    "Why did the dentist become a DJ? He knows how to brace the beat!",
    "What does a dentist do on a roller coaster? He braces himself!",
    "Whitening our pixels. Smiles are getting brighter!",
    "Our algorithms are like toothpaste – they remove bugs in a squeeze!",
    "What's a dentist's favorite time? Tooth-hurty!",
    "Why did the toothbrush go to school? To brush up on its skills!",
    "Refreshing our braces... I mean, bases!",
    "Why was the dentist a good detective? He could get to the root of every problem!",
    "Our code is undergoing a dental exam. It's cavity-free!",
    "What do dentists call a group of decisions? Molar-tiple choices!",
    "Why did the dentist join the army? He wanted to fight tooth and nail!",
    "Why do dentists like potatoes? Because they're so filling!",
    "How does a dentist introduce himself in Italy? 'I’m a pasta-dontist!'",
    "We’re finding all the cavities in the code. No hole left unexplored!",
    "Why do trees hate going to the dentist? They're afraid of root canals!",
    "How does a dentist fix a website? By removing its cookies!",
    "Our tech team is better than dentists – they fix every byte!",
    "Why did the computer visit the dentist? It had too many chips! (Oh this one really is bad haha!)",
    "In the world of data, we're the tooth fairies. Magic in progress!",
    "What did the molar say to the incisor? 'Brace yourself, the dentist is coming!'",
    "Why did the doughnut go to the dentist? It needed a filling!",
    "Just like a toothbrush, we're brushing away the digital plaque!",
    "Why did the dentist make a good coach? Because he knew the drill!",
    "If our servers were teeth, they'd be shining by now!",
    "What did the dentist say to Tiger Woods? 'You have a hole in one.'",
    "Did you hear about the Buddhist who refused to have an anaesthetic injection when he was going for a filling? Apparently, he wanted to transcend dental medication.",
    "Dentist begging the patient: Could you help me, please? Scream as loud and painfully as you possibly can? Patient: Umm, why? It’s not hurting? Dentist: Because there are tonnes of patients in the waiting room right now and I don’t want to miss the cup final at 3!",
    "Knock, knock. Who’s there? Dishes. Dishes, who? Dishes how I talk since I lost my teeth!",
    "NHS Dentistry.",
  ];

  const tipsAndHints = [
    "Tip: Always start with a healthy patient baseline – Your template should represent a patient of perfect health, fully filled in with no abnormalities.",
    "Hint: Update, don’t add or subtract – When documenting, only provide deviations from the healthy baseline. Do not add sections or delete irrelevant details.",
    "Tip: Fully filled-in templates – Ensure your template is complete with all standard healthy patient details, such as 'No Abnormality Detected', 'Nil' in each section.",
    "Hint: No fill-in-the-blank sections – Avoid using templates that require blanks to be filled or sections to be removed, as DigitalTCO operates on a fixed baseline.",
    "Tip: Focus on deviations – During the exam, only update the baseline with specific problems or observations; the system will adjust the rest automatically.",
    "Hint: Customize your baseline – Tailor your healthy patient baseline to fit your practice, its easy to make changed in 'Customise'.",
    "Tip: Consistency is key – Stick to updating the baseline rather than adding or subtracting, ensuring your notes remain efficient and accurate.",
    "Tip: Watch video 4 in the Knowledge Vault for a deep dive into how templates function.",
    "Hint: Check out video 4 in the Knowledge Vault to get a better understanding of template usage.",
    "Tip: To learn how templates work, watch video 4 in the Knowledge Vault.",
    "Hint: For a full explanation of template functionality, refer to video 4 in the Knowledge Vault.",
    "Tip: Video 4 in the Knowledge Vault explains how to effectively use templates.",
    "Hint: Need clarity on templates? Watch video 4 in the Knowledge Vault for details.",
    "Tip: Video 4 in the Knowledge Vault will guide you through the workings of templates.",
    "Hint: Gain insights into templates by watching video 4 in the Knowledge Vault.",
    "Tip: Head to video 4 in the Knowledge Vault to master the use of templates.",
    "Hint: Understanding templates is easier with video 4 in the Knowledge Vault. Give it a watch.",
    "Tip: If a final note seems off, you can resend the transcript to generate a completely new version.",
    "Hint: In about 1% of cases, the AI might create a strange note. Just resend the transcript for a fresh result.",
    "Tip: If your transcript contains a lot of repetition, your mic might be disconnected or muffled.",
    "Hint: Make sure to charge your Rode 2 mic, especially if it’s around 4pm, as this is when audio issues often happen.",
    "Tip: There’s a 'Check Audio Quality' button in Jazmode and Platypus to help diagnose audio problems.",
    "Hint: If the transcript is weird or contains repeated words, check your microphone connection or replace the battery.",
    "Tip: When a note is not as expected, just resend the transcript for a new version.",
    "Hint: Audio issues? Check the 'Check Audio Quality' button in Jazmode to make sure everything is working correctly.",
    "Tip: If your mic dies or becomes muffled, it can result in strange transcripts. Be sure to check your audio setup.",
    "Hint: To avoid weird notes, always ensure your Rode 2 mic is charged, especially during long afternoon sessions.",
    "Tip: When using a lapel mic, clip it to your pocket rather than your neckline for better sound quality.",
    "Hint: Use the pause button when you're not speaking, especially when taking radiographs. The AI doesn't handle long silences well.",
    "Tip: You can use the Spiel Store for quickly accessing your preset radiograph settings during exams.",
  ];
  

  const randomMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
  const randomTipOrHint = tipsAndHints[Math.floor(Math.random() * tipsAndHints.length)];

  useEffect(() => {
    const randomChoice = Math.random();

    if (randomChoice < 0.15) {
      setShowNPSForm(true);
    } else if (randomChoice < 0.5) {
      setContentToShow('joke');
    } else {
      setContentToShow('tip');
    }
  }, []);

  const handleNpsSubmit = async (score) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('You must be logged in to submit NPS.');
        return;
      }
  
      const uid = user.uid;
  
      // Reference to the user's NPS sub-collection
      const npsRef = collection(firestore, 'nps', uid, 'submissions');
  
      // Add a new document to the 'submissions' collection with a unique ID
      await addDoc(npsRef, {
        uid,
        score,
        timestamp: new Date(),
      });
  
      setNpsSubmitted(true);
      setNpsScore(score);
    } catch (error) {
      console.error('Error submitting NPS:', error);
      alert('Failed to submit NPS. Please try again.');
    }
  };
  

  return (
    <div className="loading-popup-overlay">
      <div className="bg-white p-5 rounded-lg text-center flex flex-col justify-center items-center pt-10 w-2/5">
        <ThreeDotsWave />
        {!npsSubmitted && showNPSForm ? (
          <div className="nps-form">
            <p className="text-xs">
              Cooking up some notes. In the meantime...
            </p>
            <p className="text-xs">
              How likely are you to recommend us to a colleague? (1 = Not at all, 10 = Definitely)
            </p>
            <div className="flex justify-center space-x-2 mt-4 mb-4">
              {[...Array(10)].map((_, index) => (
                <button
                  key={index + 1}
                  className={`w-10 h-10 rounded-full border border-gray-300 text-gray-700 hover:bg-primary-400 hover:text-white transition ${
                    npsScore === index + 1 ? 'bg-primary-400 text-white' : ''
                  }`}
                  onClick={() => handleNpsSubmit(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        ) : contentToShow === 'joke' ? (
          <>
            <p>{randomMessage}</p>
            <p className="text-xs text-gray-400 mt-2 mb-2">Notes will be ready in max 1 minute and saved to Notes List...</p>
          </>
        ) : (
          <>
            <p>{randomTipOrHint}</p>
            <p className="text-xs text-gray-400 mt-2 mb-2">Notes will be ready in max 1 minute and saved to Notes List...</p>
          </>
        )}

        {npsSubmitted && <p className="text-xs text-gray-400">Thanks for your feedback!</p>}
        <div className="loading-bar"></div>
      </div>
    </div>
  );
};

export default LoadingPopup;